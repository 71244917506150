import React, { useState } from "react";
import toast from "react-hot-toast";
import { XIcon } from "lucide-react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { useMediaQuery } from "../../hooks/use-media-query";
import {
  Dialog,
  DialogContent,
  DialogFooter,
} from "../../components/ui/dialog";
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
} from "../../components/ui/drawer";
import LoadingSpinner from "../../components/LoadingSpinner";
import server from "../../utils/serverConfig";

const style = {
  firstHeading: "text-sm text-[#00000099] text-left font-medium",
  inputBox:
    "w-full px-6 py-2.5  bg-white text-[18px]  peer text-gray-900 placeholder-transparent  font-medium rounded-md mt-3 ring-[1px] hover:ring-[2px] ring-gray-200    hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9]  transition ease-in duration-200",
  labelFloat:
    " absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-[16px] peer-placeholder-shown:text-sm peer-placeholder-shown:font-medium peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",
  inputStartDiv: "relative w-full  ",
};

export function TerminateStrategyModal({
  terminateModal,
  setTerminateModal,
  strategyDetails,
  userEmail,
  getStrategyDetails,
  userDetails,
  tableData,

}) {
  const rootUrl = process.env.REACT_APP_URL;
  const advisorTag = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;
  const navigate = useNavigate();
  const isDesktop = useMediaQuery("(min-width: 830px)");

  const [ignoreText, setIgnoreText] = useState("");
  const [ignoreLoading, setIgnoreLoading] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const terminateModalPortfolio = () => {
    setIgnoreLoading(true);
    let data = JSON.stringify({
      email: userEmail,
      action: "unsubscribe",
    });

    let config = {
      method: "put",
      url: `${server.server.baseUrl}api/model-portfolio/subscribe-strategy/${strategyDetails?._id}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then(async (response) => {
        getStrategyDetails();

        setTerminateModal(false);
        let whatsappData = JSON.stringify({
          phone_number: userDetails?.phone_number,
          template_name: "model_pf_unsub",
          template_body_values: [
            `${userDetails?.name}`,
            `${strategyDetails?.model_name}`,
            `${advisorTag}`,
          ],
          template_button_values: [`${rootUrl}`],
          country_code: userDetails?.country_code,
          callback_data: "Standard Callback",
          language_code: "en",
        });

        let whatsappConfig = {
          method: "post",
          url: `${server.ccxtServer.baseUrl}comms/whatsapp/send-template`,
          headers: {
            "Content-Type": "application/json",
          },
          data: whatsappData,
        };

        await axios.request(whatsappConfig);
        toast.success("You have been successfully terminate Model Portfolio.", {
          duration: 3000,
          style: {
            background: "white",
            color: "#16a085",
            maxWidth: "500px",
            fontWeight: "bolder",
            fontSize: "16px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });

        const toRef = setTimeout(() => {
          setIgnoreLoading(false);
          // Use React Router for navigation
          navigate("/model-portfolio");
        }, 2000);

        return () => clearTimeout(toRef);
      })
      .catch((error) => {
        toast.error("Something went wrong. Please try again after sometime.", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
        setIgnoreLoading(false);
      });
  };


  const renderTable = () => (
<table className="w-full text-xs border border-gray-200">
    <thead>
      <tr className="border-b border-gray-200">
        <th className="text-[10px] text-[#000000]/80 font-poppins font-medium text-left px-2 py-1 border-r border-gray-200">
          Stock
        </th>
        <th className="text-[10px] text-center text-[#000000]/80 font-poppins font-medium px-2 py-1 border-r border-gray-200">
          Current Price(₹)
        </th>
        <th className="text-[10px] text-center text-[#000000]/80 font-poppins font-medium px-2 py-1 border-r border-gray-200">
          Avg. Buy Price(₹)
        </th>
        <th className="text-[10px] text-center text-[#000000]/80 font-poppins font-medium px-2 py-1 border-r border-gray-200">
          Returns(%)
        </th>
        <th className="text-[10px] text-center text-[#000000]/80 font-poppins font-medium px-2 py-1 border-r border-gray-200">
          Weights(%)
        </th>
        <th className="text-[10px] text-center text-[#000000]/80 font-poppins font-medium px-2 py-1">
          Shares
        </th>
      </tr>
    </thead>
    <tbody>
      {(tableData && tableData.length > 0 ? tableData : [{}]).map((row, index) => (
        <tr key={index} className={`border-b border-gray-200 ${index % 2 === 0 ? 'bg-gray-50' : ''}`}>
          <td className="text-[10px] text-left px-2 py-1 border-r border-gray-200">{row.symbol || '-'}</td>
          <td className="text-[10px] text-center px-2 py-1 border-r border-gray-200">{row.currentPrice || '-'}</td>
          <td className="text-[10px] text-center px-2 py-1 border-r border-gray-200">{row.avgBuyPrice || '-'}</td>
          <td className="text-[10px] text-center px-2 py-1 border-r border-gray-200">{row.returns ? `${row.returns}%` : '-'}</td>
          <td className="text-[10px] text-center px-2 py-1 border-r border-gray-200">{row.weights ? `${row.weights}%` : '-'}</td>
          <td className="text-[10px] text-center px-2 py-1">{row.shares || '-'}</td>
        </tr>
      ))}
    </tbody>
  </table>
);

  // if (isDesktop) {
    return (
      <Dialog open={terminateModal} onOpenChange={setTerminateModal}>
      <DialogContent className="w-full max-w-[520px] px-4 sm:px-6 md:px-8 animate-modal">
        <div className="flex flex-col items-center py-4">
          <div className="text-lg sm:text-xl md:text-2xl font-medium font-poppins text-center">
            Are you sure you want to terminate this Model Portfolio?
          </div>
          <div className="mt-4 sm:mt-6 w-full max-h-[150px] overflow-x-auto overflow-y-auto">
            {renderTable()}
          </div>
          <div className={`mt-3 w-full ${style.inputStartDiv}`}>
            <input
              id="clientCode"
              placeholder="Reason for Terminate (Optional)"
              type="text"
              value={ignoreText}
              className={`w-full ${style.inputBox}`}
              onChange={(e) => setIgnoreText(e.target.value)}
            />
            <label
              htmlFor="clientCode"
              className={`${style.firstHeading} ${style.labelFloat}`}
            >
              Reason for Terminate (Optional)
            </label>
          </div>
  
          <div className="flex items-start space-x-1 mt-6 w-full">
            <input
              type="checkbox"
              id="terms"
              checked={isConfirmed}
              onChange={(e) => setIsConfirmed(e.target.checked)}
              className="h-4 w-4 mt-1 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
            />
            <label
              htmlFor="terms"
              className="text-xs sm:text-sm font-medium font-poppins leading-tight"
            >
              I understand that this action cannot be undone and I want to terminate this Model Portfolio.
            </label>
          </div>
        </div>
        <DialogFooter className="flex flex-col sm:flex-row gap-2 sm:gap-4 mt-4">
          <button
            onClick={() => setTerminateModal(false)}
            className="w-full bg-white text-black text-sm sm:text-base font-medium font-poppins border-2 border-black/20 rounded-md py-2 sm:py-3 cursor-pointer transition-all duration-150 ease-linear"
          >
            Cancel
          </button>
          <button
            onClick={isConfirmed ? terminateModalPortfolio : undefined}
            className={`w-full text-sm sm:text-base flex items-center justify-center bg-red-500 text-white font-medium font-poppins rounded-md py-2 sm:py-3 cursor-pointer transition-all duration-150 ease-linear ${
              isConfirmed ? 'hover:bg-red-600' : 'opacity-50 cursor-not-allowed'
            }`}
            disabled={!isConfirmed}
          >
            {ignoreLoading ? <LoadingSpinner /> : "Terminate"}
          </button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
    );
  }

  // return (
  //   <Drawer open={terminateModal} onOpenChange={setTerminateModal}>
  //     <DrawerContent className="focus:outline-none max-h-[500px] md:min-h-[300px]">
  //       <div
  //         onClick={() => setTerminateModal(false)}
  //         className="absolute top-4 right-3 flex items-center justify-center "
  //       >
  //         <XIcon className="h-5 w-5 text-gray-900" />
  //       </div>

  //       <div className="p-4 h-full overflow-y-auto">
  //         <div className="flex flex-col  items-center mt-4">
  //           <div className="text-[20px] font-medium font-poppins text-center">
  //             Are you sure you want to terminate this Model Portfolio?
  //           </div>

  //           <div className={`mt-3 ${style.inputStartDiv}`}>
  //             <input
  //               id="clientCode"
  //               placeholder="Reason for Terminate (Optional)"
  //               type="text"
  //               value={ignoreText}
  //               className={`${style.inputBox}`}
  //               onChange={(e) => setIgnoreText(e.target.value)}
  //             />
  //             <label
  //               htmlFor="clientCode"
  //               className={`${style.firstHeading} ${style.labelFloat}`}
  //             >
  //               Reason for Terminate (Optional)
  //             </label>
  //           </div>
  //         </div>
  //       </div>

  //       <DrawerFooter className="pt-2 flex flex-col  w-full">
  //         <div
  //           onClick={terminateModalPortfolio}
  //           className="w-full flex items-center justify-center bg-red-500 text-white text-[18px] font-medium font-poppins rounded-md py-3 cursor-pointer hover:scale-[1.04] transition-all duration-150 ease-linear"
  //         >
  //           {ignoreLoading === true ? <LoadingSpinner /> : "Terminate"}
  //         </div>
  //       </DrawerFooter>
  //     </DrawerContent>
  //   </Drawer>
  // );
// }
