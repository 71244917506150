import React from "react";
import formatCurrency from "../../utils/formatCurrency";

const BespokePortfolioAccountData = ({ style, data }) => {
  const calculateTotals = (data) => {
    const totals = data.reduce(
      (acc, item) => {
        const { avgPrice, ltp, quantity } = item.bespoke_holding;

        const investedValue = quantity * avgPrice;
        const currentValue = quantity * ltp;
        const netReturns = currentValue - investedValue;

        acc.totalInvested += investedValue;
        acc.totalCurrent += currentValue;
        acc.totalNetReturns += netReturns;

        return acc;
      },
      { totalInvested: 0, totalCurrent: 0, totalNetReturns: 0 }
    );

    totals.netReturnsPercentage =
      totals.totalInvested > 0
        ? (totals.totalNetReturns / totals.totalInvested) * 100
        : 0;

    // Format values to 2 decimal places
    return {
      totalInvested: totals.totalInvested.toFixed(2),
      totalCurrent: totals.totalCurrent.toFixed(2),
      totalNetReturns: totals.totalNetReturns.toFixed(2),
      netReturnsPercentage: totals.netReturnsPercentage.toFixed(2),
    };
  };

  const result = calculateTotals(data);

  return (
    <>
      <div className=" relative px-2 lg:px-0 py-4 lg:py-0 mt-8 lg:mt-4 ">
        <div className=" grid grid-cols-2  gap-x-3 gap-y-3 lg:flex lg:gap-x-4 ">
          <div className="flex flex-col space-y-3 shadow-md rounded-md bg-white  w-full px-4 py-2  lg:px-6 lg:py-4">
            <div className={style.analyticsHead}>
              {/* Invested Amount */}
              Total Invested
            </div>
            <div
              className={`${style.analyticsValue} text-xs sm:text-sm md:text-[18px]  lg:text-[26px]`}
            >
              {result?.totalInvested
                ? `₹${formatCurrency(parseInt(result?.totalInvested))}`
                : 0}
            </div>
          </div>
          <div className="flex flex-col space-y-3 shadow-md rounded-md bg-white  w-full px-4 py-2  lg:px-6 lg:py-4">
            <div className={style.analyticsHead}>
              {/* Current Amount */}
              Total Current
            </div>
            <div
              className={`${style.analyticsValue} text-xs sm:text-sm md:text-[18px]  lg:text-[26px]`}
            >
              {result?.totalCurrent
                ? `₹${formatCurrency(parseInt(result?.totalCurrent))}`
                : 0}
            </div>
          </div>

          <div className="flex flex-col space-y-3 shadow-md rounded-md bg-white  w-full px-4 py-2  lg:px-6 lg:py-4">
            <div className={style.analyticsHead}>
              Absolute Returns
              {/* Net returns */}
            </div>
            {result?.totalNetReturns > 0 ? (
              <div className={`flex items-center`}>
                <div className="text-[#000000] text-xs sm:text-sm md:text-[18px]  lg:text-[26px]  font-semibold font-poppins">
                  +₹{formatCurrency(Math.abs(result?.totalNetReturns))}
                </div>
              </div>
            ) : result?.totalNetReturns < 0 ? (
              <div className={`flex items-center`}>
                <div className="text-[#000000] text-xs sm:text-sm md:text-[18px] lg:text-[26px] font-semibold font-poppins">
                  -₹{formatCurrency(Math.abs(result?.totalNetReturns))}
                </div>
              </div>
            ) : (
              <div className="text-xs sm:text-sm md:text-[18px] lg:text-[26px] font-semibold font-poppins">
                0
              </div>
            )}
          </div>
          <div className="flex flex-col space-y-3 shadow-md rounded-md bg-white  w-full px-4 py-2  lg:px-6 lg:py-4">
            <div className={style.analyticsHead}>
              {/* Net Returns */}
              {/* Current Returns */}
              Returns Percentage
            </div>
            {result?.netReturnsPercentage > 0 ? (
              <div className={`flex items-center`}>
                {/* <div className="text-[#000000] text-xs sm:text-sm md:text-[18px]  lg:text-[26px]  font-semibold font-poppins">
                  {formatCurrency(Math.abs(result?.totalNetReturns))}
                </div> */}
                <div className=" text-[#16A085] text-xs sm:text-sm md:text-[18px]  lg:text-[26px]  font-semibold font-poppins">
                  +{Math.abs(result?.netReturnsPercentage)}%
                </div>
              </div>
            ) : result?.netReturnsPercentage < 0 ? (
              <div className={`flex items-center`}>
                {/* <div className="text-[#000000] text-xs sm:text-sm md:text-[18px] lg:text-[26px] font-semibold font-poppins">
                  {formatCurrency(Math.abs(result?.totalNetReturns))}
                </div> */}
                <div className=" text-[#E43D3D] text-xs sm:text-sm md:text-[18px]  lg:text-[26px]  font-semibold font-poppins">
                  -{Math.abs(result?.netReturnsPercentage)}%
                </div>
              </div>
            ) : (
              <div className="text-xs sm:text-sm md:text-[18px] lg:text-[26px] font-semibold font-poppins">
                <div className="text-[#000000] text-xs sm:text-sm md:text-[18px] lg:text-[26px] font-semibold font-poppins">
                  0
                </div>
                <sub className="text-[12px]  text-[#000000] font-semibold font-poppins mt-1 px-[4px] py-[2px]  rounded-md ml-1">
                  0.00%
                </sub>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BespokePortfolioAccountData;
