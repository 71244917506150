import React, { useState, useEffect, useRef } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import axios from "axios";

import {
  Dialog,
  DialogContent,
  DialogFooter,
} from "../../../components/ui/dialog";

import {
  CalendarDays,
  ShoppingBasket,
  ChevronDown,
  ChevronUp,
  Plus,
  Minus,
} from "lucide-react";
import moment from "moment";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { auth } from "../../../firebase";

import server from "../../../utils/serverConfig";

const BasketModal = ({
  isOpen,
  onClose,
  basketName,
  lastUpdated,
  description,
  funds,
  basketId,
  trades,
  onAccept,
  loading,
}) => {
  const [filteredTrades, setFilteredTrades] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [multiplier, setMultiplier] = useState("1");

  useEffect(() => {
    if (isOpen) {
      setLoadingData(true);
      setFilteredTrades(trades);
      setLoadingData(false);
    }
  }, [isOpen, trades]);

  const incrementMultiplier = () => {
    const currentValue = multiplier === "" ? 0 : parseInt(multiplier);
    setMultiplier((currentValue + 1).toString());
  };

  const decrementMultiplier = () => {
    const currentValue = multiplier === "" ? 2 : parseInt(multiplier);
    if (currentValue > 1) {
      setMultiplier((currentValue - 1).toString());
    }
  };

  const handleMultiplierChange = (value) => {
    if (value === "" || parseInt(value) < 1) {
      setMultiplier("");
    } else {
      setMultiplier(value);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[90vw] md:max-w-[72vw] lg:max-w-[52vw] xl:max-w-[38vw] w-full p-0">
        <div className="flex flex-col w-full rounded-lg bg-white">
          <div className="px-4 sm:px-6 py-4 flex items-center space-x-3 shadow-md">
            <div className="bg-[#4A7AAF] p-2 rounded-full">
              <ShoppingBasket size={24} className="text-white" />
            </div>
            <div className="text-[22px] text-black font-bold leading-[40px] font-poppins">
              {basketName}
            </div>
          </div>

          <div className="px-4 pb-4">
            <div className="bg-white rounded-md border border-gray-200 mt-4">
              <div className="h-[300px] overflow-y-auto custom-scroll">
                <table className="w-full">
                  <thead className="bg-[#f5f5f5] sticky top-0 z-20">
                    <tr className="border-b-[1px] border-[#000000]/10">
                      <th className="text-[12px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium text-left px-3 py-3 lg:py-3 lg:px-8">
                        Stocks
                      </th>
                      <th className="text-[12px] min-w-[120px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5">
                        Current Price (₹)
                      </th>
                      <th className="text-[12px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5">
                        Quantity
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loadingData ? (
                      <tr>
                        <td colSpan="3" className="text-center py-4">
                          Loading...
                        </td>
                      </tr>
                    ) : filteredTrades.length > 0 ? (
                      filteredTrades.map((trade, index) => (
                        <tr
                          key={index}
                          className="border-b-[1px] border-[#000000]/10 last:border-none"
                        >
                          <td className="text-[14px] sm:text-sm text-[#000000]/80 font-poppins font-medium text-left py-3 px-3 lg:py-4 lg:px-8 flex flex-col">
                            {trade.searchSymbol}
                            <span
                              className={`mt-1 ${
                                trade.Type === "SELL"
                                  ? "text-[#EA2D3F]"
                                  : "text-[#16A085]"
                              }`}
                            >
                              {trade.Type === "SELL" ? "SELL" : "BUY"}
                            </span>
                          </td>
                          <td className="text-[15px] text-[#000000]/80 font-poppins font-normal text-center py-3 px-5">
                            ₹ {trade.funds || "-"}
                          </td>
                          <td className="text-[15px] text-[#000000]/80 font-poppins font-medium text-center py-3 px-3 lg:py-4 lg:px-5">
                            {trade.Lots * multiplier || "-"}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="text-center py-4">
                          No trades available.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="px-6 py-4 border-t border-[#000000]/10">
            <div className="flex flex-col items-start space-y-2">
              <label
                htmlFor="multiplier"
                className="text-sm text-[#000000]/80 font-poppins"
              >
                Quantity Multiplier:
              </label>
              <div className="flex items-center space-x-2">
                <button
                  onClick={decrementMultiplier}
                  className="w-8 h-8 flex items-center justify-center bg-gray-200 rounded-md hover:bg-gray-300 transition-colors"
                >
                  <Minus size={16} />
                </button>
                <input
                  id="multiplier"
                  type="number"
                  min="1"
                  value={multiplier}
                  onChange={(e) => handleMultiplierChange(e.target.value)}
                  className="w-20 px-2 py-1 border border-gray-300 rounded-md text-sm font-poppins text-center"
                />
                <button
                  onClick={incrementMultiplier}
                  className="w-8 h-8 flex items-center justify-center bg-gray-200 rounded-md hover:bg-gray-300 transition-colors"
                >
                  <Plus size={16} />
                </button>
              </div>
              <p className="text-xs text-gray-500 font-poppins mt-1">
                Note: The multiplier adjusts all stock quantities
                proportionally. A value of 2 doubles all quantities, 3 triples
                them, and so on.
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between px-6 py-4 border-t border-[#000000]/10">
            <div className="flex space-x-4">
              <div>
                <p className="text-sm text-[#000000]/80 font-poppins">
                  Required Fund
                </p>
                <p className="text-lg font-semibold font-poppins">-</p>
              </div>
              <div>
                <p className="text-sm text-[#000000]/80 font-poppins">
                  Available Fund
                </p>
                <p className="text-lg font-semibold font-poppins">
                  {" "}
                  ₹{" "}
                  {funds?.data?.net ? Number(funds.data.net).toFixed(2) : "NaN"}
                </p>
              </div>
            </div>
            <button
              className="w-36 h-10 bg-black text-white text-sm font-semibold rounded-md hover:bg-[#1D3A5F] transition-colors duration-200 font-poppins"
              onClick={onAccept}
            >
              {loading ? <LoadingSpinner /> : "Place Order"}
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default BasketModal;
