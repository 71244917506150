import { Info } from "lucide-react";
import moment from "moment";

const BespokeCard = ({ ele, handlePricingCardClick }) => {
  const onCardClick = () => {
    handlePricingCardClick(ele);
  };

  const isExpired = (moment(ele?.subscription?.end_date).unix() * 1000) < new Date().getTime();

  return (
    <div
      className="flex flex-col cursor-pointer font-poppins w-[95%] rounded-t-none rounded-b-xl bg-white border-[1px] shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] pb-4"
      style={{ borderColor: ele?.subscription ? (!isExpired ? '#2E7D32' : '#BDBDBD') : '#e5e7eb' }}
      onClick={onCardClick}
    >
      {ele?.subscription && (
      <div
          className="p-2 font-medium tracking-wide rounded-t-none rounded-b-full text-center text-sm uppercase w-full"
          style={{ background: !isExpired ? '#2E7D32': '#BDBDBD', color: !isExpired ? 'white': 'black' }}
        >
          { isExpired ? "SUBSCRIPTION EXPIRED" : "SUBSCRIBED" }
          <span className="block font-normal text-xs">
            ({moment(ele.subscription.start_date).format("DD-MMM-YYYY")} to {moment(ele.subscription.end_date).format("DD-MMM-YYYY")})
          </span>
        </div>
      )}
      <div className="flex items-start pl-8 p-4">
        <div className="overflow-hidden rounded-lg">
          <h2 className="text-2xl font-semibold w-full text-[#000000] capitalize">
            {ele?.name}
            <span className="ml-3 text-[16px] font-normal text-[#95989C]">
              {ele?.duration
                ? `(${Math.round(ele.duration / 30)} month${
                    Math.round(ele.duration / 30) > 1 ? "s" : ""
                  })`
                : ""}
            </span>
          </h2>

          {ele?.frequency?.length !== 0 ? (
            <div className="flex items-baseline">
              <h2 className="font-poppins text-[40px] font-bold text-[#333333] leading-none">
                ₹
                {ele?.pricing?.monthly
                  ? ele.pricing.monthly
                  : ele?.pricing?.quarterly
                  ? ele.pricing.quarterly
                  : ele?.pricing?.halfYearly
                  ? ele.pricing.halfYearly
                  : ele?.pricing?.yearly
                  ? ele.pricing.yearly
                  : "N/A"}
              </h2>
              <p className="text-[16px] text-[#95989C] font-normal ml-1">
                {ele?.pricing?.monthly
                  ? "/month"
                  : ele?.pricing?.quarterly
                  ? "/quarter"
                  : ele?.pricing?.halfYearly
                  ? "/half-year"
                  : ele?.pricing?.yearly
                  ? "/year"
                  : ""}
              </p>
            </div>
          ) : (
            <div className="flex items-baseline">
              <h2 className="font-poppins text-[40px] font-bold text-[#333333] leading-none">
                ₹{ele?.amount}
              </h2>
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col grow px-4 pl-8 py-1">
        <div className="font-poppins text-[12.5px] flex flex-row pb-2 items-start space-x-2 text-[#666666]">
          <Info size={20} className="text-white fill-[#9E9E9E] shrink-0" />
          <span className="text-sm font-poppins text-left text-gray-600">
            {ele?.description || "No description available"}
          </span>
        </div>
      </div>

      <div className="px-6 py-2 mt-2">
        <div className="flex space-x-4">
          <button
            onClick={(e) => {
              e.stopPropagation();
              handlePricingCardClick(ele);
            }}
            className={`flex-1 h-10 py-2 px-1 rounded-md mr-2 ${
              ele?.subscription
                ? "bg-blue-500 text-white"
                : "bg-black text-white"
            } border-black border-[2px] text-xs font-medium hover:bg-white hover:text-black transition-all duration-150 ease-linear`}
          >
            {ele?.subscription ? "Renew Now" : "Subscribe Now"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default BespokeCard;
