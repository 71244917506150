import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import handIcon from "../../assests/hand_icon.svg";
import termsIcon from "../../assests/terms_icon.svg";
import rationaleIcon from "../../assests/rationale_icon.png";
import groupIcon from "../../assests/group_icon.svg";
import clientsIcon from "../../assests/clients_icon.svg";
import AdminSettings from "../AdminSettings/AdminSettings.js";
import planIcon from "../../assests/plan-icon.png";

const AdminEmptyState = ({ adminEmail, disclaimerData }) => {
  const whiteLabelText = process.env.REACT_APP_WHITE_LABEL_TEXT;
  const navigate = useNavigate();
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [localDisclaimerData, setLocalDisclaimerData] =
    useState(disclaimerData);
  const [activeSettingsTab, setActiveSettingsTab] = useState(null);

  useEffect(() => {
    setLocalDisclaimerData(disclaimerData);
  }, [disclaimerData]);

  const tasks = [
    {
      name: "Add Disclaimer",
      completed: !!localDisclaimerData?.disclaimer?.trim(),
      icon: handIcon,
      settingsTab: "disclaimer",
    },
    {
      name: "Add Terms & Conditions",
      completed: !!localDisclaimerData?.termsAndConditions?.trim(),
      icon: termsIcon,
      settingsTab: "terms",
    },
    { 
      name: "Add Plan", 
      completed: false, 
      icon: planIcon, 
      action: () => navigate("/admin/admin-plans")
    },

    {
      name: "Add Default Rationale",
      completed: !!localDisclaimerData?.rationale?.trim(),
      icon: rationaleIcon,
      settingsTab: "rationale",
    },
    { name: "Add Clients", completed: false, icon: clientsIcon },
    { name: "Organize Clients in Groups", completed: false, icon: groupIcon },
  ];

  const incompleteTasksCount = tasks.filter((task) => !task.completed).length;

  const handleViewClientList = () => {
    navigate("/admin/client-list");
  };

  const handleOpenSettingsModal = (settingsTab) => {
    setActiveSettingsTab(settingsTab);
    setShowSettingsModal(true);
  };
  const handleCloseSettingsModal = () => {
    setShowSettingsModal(false);
    setActiveSettingsTab(null);
  };

  const handleTaskAction = (task) => {
    if (task.action) {
      task.action();
    } else if (task.settingsTab) {
      handleOpenSettingsModal(task.settingsTab);
    } else if (task.name.includes("Client")) {
      handleViewClientList();
    }
  };

  return (
    <div className="min-h-screen flex flex-col w-full items-center justify-start p-4 bg-gray-50">
      <div className="w-full max-w-4xl">
        <h1 className="text-3xl font-bold text-center mb-6">
          {whiteLabelText}: Automate and elevate client investments!
        </h1>

        <div className="bg-white p-4 rounded-lg shadow-md mb-8">
          <h2 className="text-xl font-semibold mb-2">
            Setup Progress - setup your account by clicking each link below
          </h2>
          <p className="text-gray-600">
            {incompleteTasksCount === 0
              ? "All tasks completed! Your account is fully set up."
              : `You have ${incompleteTasksCount} task${
                  incompleteTasksCount > 1 ? "s" : ""
                } left to complete.`}
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
          {tasks.map((task, index) => (
           <div
           key={index}
           className={`p-6 rounded-lg shadow-md h-60 ${
             task.completed ? "bg-green-100" : "bg-white"
           }`}
         >
              <div className="h-36">
              <h3 className="text-lg font-semibold">{task.name}</h3>
                <img src={task.icon} alt={task.name} className="w-8 h-8" />
              </div>
              <p className="text-sm text-gray-600">
                {task.completed
                  ? "Task completed. Great job!"
                  : "This task needs your attention."}
              </p>
              {!task.completed && (
                <button
                  // onClick={() =>
                  //   task.settingsTab
                  //     ? handleOpenSettingsModal(task.settingsTab)
                  //     : handleViewClientList()
                  // }
                  onClick={() => handleTaskAction(task)}

                  className=" mt-auto  px-4 py-2 bg-black text-white rounded hover:bg-black transition-all duration-200 ease-in-out hover:scale-105"                >
                  {task.name.includes("Client")
                    ? "View Clients"
                    : "Complete Task"}
                </button>
              )}
            </div>
          ))}
        </div>

        {incompleteTasksCount > 0 && (
          <div className="text-center">
            <button
              onClick={() => handleOpenSettingsModal(null)}
              className="px-6 py-3 bg-black text-white font-semibold rounded shadow-lg hover:bg-gray-800 transition-colors"
            >
              Continue Setup
            </button>
          </div>
        )}
      </div>

      {showSettingsModal && (
        <AdminSettings
          adminEmail={adminEmail}
          closeSettingsModal={() => setShowSettingsModal(false)}
          initialTab={activeSettingsTab}
          disclaimerData={disclaimerData}
        />
      )}
    </div>
  );
};

export default AdminEmptyState;
