import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment";
import server from "../../utils/serverConfig";
import { DatePicker } from "../../components/ui/date-picker";
import ShowAdviceDataTable from "./ShowAdviceDataTable";
import { CircleXIcon, RefreshCcwIcon } from "lucide-react";
import { CSVLink } from "react-csv";
import CSVIcon from "../../assests/csv_icon.svg";

const style = {
  selected:
    "text-[14px] lg:text-[18px] font-poppins text-black font-semibold border-b-[3px] border-black cursor-pointer",
  unselected:
    "font-medium font-poppins text-[14px] lg:text-[18px] text-[#000000]/40 cursor-pointer border-b-[3px] border-transparent",
  inputStartDiv: "relative w-full",
};

const RecommendationStatus = ({ adminEmail }) => {
  const [allTrade, setAllTrade] = useState([]);
  const [filteredAllAdviceData, setFilteredAllAdviceData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState("all");
  const limit = 40;

  // Search states
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryStock, setSearchQueryStock] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [filename, setFilename] = useState("");

  // Global counts and pagination
  const [globalStats, setGlobalStats] = useState({
    totalRecords: 0,
    counts: {
      all: 0,
      recommend: 0,
      placed: 0,
      open: 0,
      rejected: 0,
      complete: 0,
      ignored: 0,
    },
  });

  const [tabData, setTabData] = useState({
    currentPage: 1,
    totalPages: 1,
    totalRecords: 0,
  });

  // Fetch global stats
  const fetchGlobalStats = async () => {
    if (!adminEmail) return;

    try {
      const response = await axios.get(
        `${server.server.baseUrl}api/trade-history/trade-history-by-trade-given-by`,
        {
          params: {
            trade_given_by: adminEmail,
            page: 1,
            limit: 1, // Minimal data needed for counts
            status: "all",
          },
        }
      );

      const { totalRecords, tradePlaceStatusCounts } = response.data;
      setGlobalStats({
        totalRecords,
        counts: {
          all: totalRecords,
          ...tradePlaceStatusCounts,
        },
      });
    } catch (error) {
      console.error("Error fetching global stats:", error);
    }
  };

  // Fetch data for current tab
  const fetchTabData = async (page = 1) => {
    if (!adminEmail) return;

    setLoading(true);
    try {
      const response = await axios.get(
        `${server.server.baseUrl}api/trade-history/trade-history-by-trade-given-by`,
        {
          params: {
            trade_given_by: adminEmail,
            page,
            limit,
            status: selectedTab === "all" ? undefined : selectedTab,
          },
        }
      );

      const { trades, totalRecords } = response.data;
      setAllTrade(trades);
      setTabData({
        currentPage: page,
        totalPages: Math.ceil(totalRecords / limit),
        totalRecords,
      });
    } catch (error) {
      console.error("Error fetching tab data:", error);
    } finally {
      setLoading(false);
    }
  };

  // Initial load
  useEffect(() => {
    if (adminEmail) {
      fetchGlobalStats();
    }
  }, [adminEmail]);

  // Tab change
  useEffect(() => {
    fetchTabData(1);
  }, [selectedTab]);

  // Filter data
  useEffect(() => {
    const filteredAdvices = allTrade?.filter((trade) => {
      const matchesEmail = trade.user_email
        ?.toLowerCase()
        .includes(searchQuery.toLowerCase());
      const matchesStockName = trade.Symbol?.toLowerCase().includes(
        searchQueryStock.toLowerCase()
      );
      const matchesStartDate = startDate
        ? moment(trade.date).isSameOrAfter(startDate, "day")
        : true;
      const matchesEndDate = endDate
        ? moment(trade.date).isSameOrBefore(endDate, "day")
        : true;
      const matchesFilename = filename
        ? (trade.trade_given_by_fileName || "")
            .toLowerCase()
            .includes(filename.toLowerCase())
        : true;

      return (
        matchesEmail &&
        matchesStockName &&
        matchesStartDate &&
        matchesEndDate &&
        matchesFilename
      );
    });

    setFilteredAllAdviceData(filteredAdvices || []);
  }, [searchQuery, searchQueryStock, startDate, endDate, filename, allTrade]);

  // CSV export data
  const csvHeaders = [
    { label: "email", key: "email" },
    { label: "symbol", key: "symbol" },
    { label: "type", key: "type" },
    { label: "product_type", key: "product_type" },
    { label: "qty", key: "qty" },
    { label: "rem_qty", key: "rem_qty" },
    { label: "traded_qty", key: "traded_qty" },
    { label: "limit_price", key: "limit_price" },
    { label: "stop_price", key: "stop_price" },
    { label: "traded_price", key: "traded_price" },
    { label: "status", key: "trade_place_status" },
    { label: "ordered_time", key: "ordered_time" },
  ];

  const csvData = filteredAllAdviceData?.map((item) => ({
    email: item.user_email,
    symbol: item.Symbol,
    type: item.Type,
    product_type: item.ProductType,
    qty: item.Quantity,
    rem_qty: item.Qty,
    traded_qty: item.tradedQty,
    limit_price: item.limitPrice,
    stop_price: item.stopPrice,
    traded_price: item.tradedPrice,
    trade_place_status: item.trade_place_status,
    ordered_time: moment(item.date).format("DD MMM YYYY h:mm A"),
  }));

  // Pagination
  const getPageNumbers = () => {
    const pages = [];
    const { currentPage, totalPages } = tabData;
    const totalVisible = 10;

    if (totalPages <= totalVisible) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      const startPage = Math.max(1, currentPage - 2);
      const endPage = Math.min(totalPages, currentPage + 2);

      if (startPage > 1) pages.push(1, "...");
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      if (endPage < totalPages) pages.push("...", totalPages);
    }

    return pages;
  };

  const goToPage = (page) => {
    if (page > 0 && page <= tabData.totalPages) {
      fetchTabData(page);
    }
  };

  const renderTabCount = (count) => {
    if (!count || count <= 0) return null;

    return (
      <span className="ml-2 pt-[1px] px-[4px] flex items-center text-[10px] font-medium text-white capitalize justify-center min-w-[24px] h-5 rounded-full">
        {count.toLocaleString()}
      </span>
    );
  };

  return (
    <div className="min-h-screen w-full bg-[#F9F9F9]">
      {/* Header */}
      <div className="px-4 sm:px-8 flex md:flex-wrap py-3 justify-between items-center">
        <div className="flex items-center text-[18px] md:text-[22px] md:leading-[30px] font-sans font-bold">
          <RefreshCcwIcon
            onClick={() => {
              fetchGlobalStats();
              fetchTabData(1);
            }}
            className="h-4 w-4 md:h-5 md:w-5 text-gray-500 mr-2 cursor-pointer"
          />
          Advice Status
        </div>

        <CSVLink
          data={csvData}
          headers={csvHeaders}
          filename={`advice_status_list_${moment().format(
            "YYYYMMDD_HHmmss"
          )}.csv`}
          className="group relative px-1 flex text-black rounded-[4px] items-center"
        >
          <button className="flex items-center text-[14px] px-2 bg-white border border-[#000000]/20 text-black md:text-[16px] font-medium font-poppins rounded-[4px] md:px-4 py-2 hover:scale-[1.02] transition-all duration-150">
            <img src={CSVIcon} alt="CSV" width={20} className="mr-2" />
            Download Excel
          </button>
        </CSVLink>
      </div>

      {/* Filters */}
      <div className="px-4 grid grid-cols-2 gap-2 sm:gap-0 sm:px-8 relative md:flex md:flex-row md:space-x-6 md:items-end mt-2">
        <div className="w-full text-[12px] md:text-[16px]">
          <DatePicker
            className="px-2 py-2 md:px-4 md:py-3"
            date={startDate}
            setDate={setStartDate}
            placeholder="Start Date"
          />
        </div>
        <div className="w-full">
          <DatePicker
            className="px-2 py-2 md:px-4 md:py-3"
            date={endDate}
            setDate={setEndDate}
            placeholder="End Date"
          />
        </div>

        <div className={style.inputStartDiv}>
          <input
            type="text"
            placeholder='Search By "Email"'
            className="w-full py-2 px-2 bg-[#F9F9F9] text-[12px] md:text-[16px] text-gray-900 placeholder:text-gray-400 font-poppins rounded-md ring-1 ring-gray-200 focus:outline-none focus:ring-[#D9D9D9]"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          {searchQuery?.length > 1 && (
            <CircleXIcon
              onClick={() => setSearchQuery("")}
              className="cursor-pointer absolute top-[14px] right-2 w-4 h-4 text-[#000000]/50"
            />
          )}
        </div>

        <div className={style.inputStartDiv}>
          <input
            type="text"
            placeholder='Search By "Stock Name"'
            className="w-full py-2 px-2 bg-[#F9F9F9] text-[12px] md:text-[16px] text-gray-900 placeholder:text-gray-400 font-poppins rounded-md ring-1 ring-gray-200 focus:outline-none focus:ring-[#D9D9D9]"
            value={searchQueryStock}
            onChange={(e) => setSearchQueryStock(e.target.value)}
          />
          {searchQueryStock?.length > 1 && (
            <CircleXIcon
              onClick={() => setSearchQueryStock("")}
              className="cursor-pointer absolute top-[14px] right-2 w-4 h-4 text-[#000000]/50"
            />
          )}
        </div>
      </div>

      {/* Tabs */}
      <div className="mt-4 flex overflow-x-auto no-scrollbar lg:overscroll-none gap-x-3 lg:gap-x-2 w-full border-b border-[#000000]/10 h-[50px] lg:px-2 shadow-[0px_-4px_4px_0px_rgba(0,0,0,0.06)] md:shadow-none">
        <div
          className={`${
            selectedTab === "all" ? style.selected : style.unselected
          } flex xl:px-2 min-w-[130px] items-center justify-center`}
          onClick={() => setSelectedTab("all")}
        >
          All Advices
          <span
            className={`ml-2 pt-[1px] px-[4px] flex items-center text-[10px] font-medium text-[#ffffff] capitalize col-span-1  justify-center  w-[24px] h-5 rounded-full ${
              selectedTab !== "all" ? " bg-[#000000]/30" : "bg-[#E43D3D]"
            }`}
          >
            {renderTabCount(globalStats.totalRecords)}
          </span>
        </div>

        {[
          { key: "recommend", label: "Recommended" },
          { key: "placed", label: "Placed" },
          { key: "open", label: "Open" },
          { key: "rejected", label: "Rejected" },
          { key: "complete", label: "Completed" },
          { key: "ignored", label: "Ignored" },
        ].map(({ key, label }) => (
          <div
            key={key}
            className={`${
              selectedTab === key ? style.selected : style.unselected
            } flex xl:px-6 min-w-[120px] items-center justify-center`}
            onClick={() => setSelectedTab(key)}
          >
            {label}
            <span
              className={`ml-2 pt-[1px] px-[4px] flex items-center text-[10px] font-medium text-[#ffffff] capitalize col-span-1  justify-center  w-[24px] h-5 rounded-full ${
                selectedTab === key ? "bg-[#E43D3D]" : "bg-[#000000]/30"
              }`}
            >
              {renderTabCount(globalStats.counts[key])}
            </span>
          </div>
        ))}
      </div>

      {/* Table */}
      <ShowAdviceDataTable
        allTrade={allTrade}
        selectedTab={selectedTab}
        filteredAllAdviceData={filteredAllAdviceData}
        loading={loading}
        currentPage={tabData.currentPage}
        goToPage={goToPage}
        getPageNumbers={getPageNumbers}
        totalPages={tabData.totalPages}
        showPagination={tabData.totalPages > 1}
      />

      {/* Pagination */}
      {tabData.totalPages > 1 && (
        <div className="flex justify-center items-center pb-3 space-x-1">
          <button
            onClick={() => goToPage(tabData.currentPage - 1)}
            disabled={tabData.currentPage === 1}
            className="px-3 py-1 text-sm bg-gray-300 rounded disabled:opacity-50"
          >
            Previous
          </button>

          {getPageNumbers().map((page, index) =>
            page === "..." ? (
              <span key={index} className="px-3 py-1 text-gray-600">
                ...
              </span>
            ) : (
              <button
                key={index}
                onClick={() => goToPage(page)}
                className={`px-3 py-1 text-sm rounded ${
                  page === tabData.currentPage
                    ? "bg-blue-500 text-white"
                    : "bg-gray-300"
                }`}
              >
                {page}
              </button>
            )
          )}

          <button
            onClick={() => goToPage(tabData.currentPage + 1)}
            disabled={tabData.currentPage === tabData.totalPages}
            className="px-3 py-1 text-sm bg-gray-300 rounded disabled:opacity-50"
          >
            Next
          </button>
        </div>
      )}
    </div>
  );
};

export default RecommendationStatus;
