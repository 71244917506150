import React, { useState, useEffect } from "react";

import server from "../../utils/serverConfig";
import formatCurrency from "../../utils/formatCurrency";

const advisorTag = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;
const CagrAndPerformace = ({ ele, handleConsentAccept, isBlurred }) => {
  const [showConsentPopup, setShowConsentPopup] = useState(false);
  const [cagrCalculate, setCagrCalculate] = useState();
  const [yearPerformace, setYearPerformance] = useState();

  const getCagrValue = async () => {
    const data = { advisor: advisorTag, modelName: ele?.name };
    try {
      const response = await fetch(
        `${server.ccxtServer.baseUrl}rebalance/performance/cagr`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      const result = await response.json();

      setCagrCalculate(result);
    } catch (error) {}
  };
  const getPerformaceValue = async () => {
    const data = { advisor: advisorTag, modelName: ele?.name };
    try {
      const response = await fetch(
        `${server.ccxtServer.baseUrl}rebalance/performance/2y_returns`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const result = await response.json();
      setYearPerformance(result);
    } catch (error) {}
  };
  useEffect(() => {
    getCagrValue();
    getPerformaceValue();
  }, []);

  const handleBlurClick = () => {
    if (isBlurred) {
      setShowConsentPopup(true);
    }
  };

  const getBlurredValue = (value) => {
    return value || "75.2";
  };

  const onConsentAccept = () => {
    handleConsentAccept();
    setShowConsentPopup(false);
  };

  return (
    <>
      <div className="flex w-full mt-[6px] border-t-[1px] border-b-[1px] border-[#000000]/10">
        <div className="flex flex-col justify-center items-center w-2/4 py-1.5  relative">
          <div className="absolute top-1/2 right-0 transform -translate-y-1/2 h-[80%] border-r border-[#000000]/10"></div>
          <div>
            <div className="text-xs text-left text-[#000000]/70 font-normal font-poppins capitalize">
              CAGR
            </div>
            <p
              className={`text-[13px] lg:text-[13px] text-[#16A085] font-poppins font-bold ${
                isBlurred ? "blur-sm cursor-pointer" : ""
              }`}
              onClick={handleBlurClick}
            >
              {cagrCalculate?.cagr
                ? `+ ${getBlurredValue(cagrCalculate?.cagr)}%`
                : "New Portfolio"}
            </p>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center  w-2/4 py-1.5 relative">
          <div className="absolute top-1/2 right-0 transform -translate-y-1/2 h-[80%] border-r border-[#000000]/10"></div>{" "}
          <div>
            <div className="text-xs  text-left text-[#000000]/70 font-normal font-poppins">
              2Y Returns
            </div>
            <p
              className={`text-[13px] lg:text-[13px] text-[#16A085] font-poppins font-bold ${
                isBlurred ? "blur-sm cursor-pointer" : ""
              }`}
              onClick={handleBlurClick}
            >          
            
                {yearPerformace?.performace
                ? `+ ${yearPerformace?.performace}%`
                : "New Portfolio"}
            </p>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center space-y-1 w-2/4   py-1.5 lg:px-1 rounded-md">
          <div>
            <div className="text-xs   text-left text-[#000000]/70 font-normal font-poppins">
              Min. investment
            </div>
            <p className="text-[13px]   lg:text-[16px] text-[#000000] font-poppins font-medium">
              ₹{formatCurrency(ele?.minInvestment)}
            </p>
          </div>
        </div>
      </div>
      {showConsentPopup && (
        <>
          <div className="fixed inset-0 bg-black bg-opacity-40 z-50"></div>
          <div className="fixed inset-0 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
              <h2 className="text-xl font-bold mb-4">Consent Required</h2>
              <p className="mb-6">
                To view the CAGR information for this New Portfolio, we need
                your consent. Do you agree to proceed?
              </p>
              <div className="flex justify-end space-x-4">
                <button
                  className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300 transition-colors"
                  onClick={() => setShowConsentPopup(false)}
                >
                  Cancel
                </button>
                <button
                  className="px-4 py-2 bg-black text-white rounded hover:bg-gray-800 transition-colors"
                  onClick={onConsentAccept}
                >
                  I Agree
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CagrAndPerformace;
