"use client";

import React, { useEffect, useState, useRef, forwardRef } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Model6 from "./assests/Model_Portfolio/model6.png";
import { Info } from "lucide-react";
import meter from "./assests/icons/meter.svg";
import server from "../utils/serverConfig";
import formatCurrency from "../utils/formatCurrency";

const calculateMonthlyFee = (pricing) => {
  if (pricing.monthly) {
    return parseFloat(pricing.monthly);
  }
  if (pricing.yearly) {
    return parseFloat(pricing.yearly) / 12;
  }
  if (pricing.halfYearly) {
    return parseFloat(pricing.halfYearly) / 6;
  }
  if (pricing.quarterly) {
    return parseFloat(pricing.quarterly) / 3;
  }
  return null; // Return null if no pricing information is available
};

const getMonthlyFee = (durationInDays, totalAmount) => {
  if (!durationInDays || !totalAmount) {
    return 0; // Handle missing data and return a default value (0)
  }

  const months = durationInDays / 30; // Convert days to months
  if (months > 0) {
    const monthlyFee = totalAmount / months; // Calculate monthly fee
    return monthlyFee; // Return the numeric fee, not formatted
  }

  return 0; // Handle invalid duration (e.g., less than a month)
};

const Card = forwardRef(
  (
    { ele, handlePricingCardClick, globalConsent, handleGlobalConsent },
    ref
  ) => {
    const { headerRef, statsRef, infoRef, buttonRef } = ref;

    const [showCAGR, setShowCAGR] = useState(false);
    const [isConsentPopupOpen, setIsConsentPopupOpen] = useState(false);

    const handleCAGRClick = () => {
      if (!globalConsent) {
        setIsConsentPopupOpen(true);
      }
    };

    const handleConsentAccept = () => {
      handleGlobalConsent();
      setIsConsentPopupOpen(false);
    };

    return (
      // Comment: Removed fixed height and added flex column
      <div className="relative font-poppins w-[350px] rounded-xl bg-white border-[1px] border-[#000000]/10 shadow-[0px_3px_4px_0px_rgba(0,0,0,0.06)] py-4 flex flex-col">
        {/* Comment: Added ref to header section */}
        <div ref={headerRef} className="flex items-start gap-x-4 p-4">
          <div className="overflow-hidden rounded-lg">
            <img
              src={ele?.image ? `${server.server.baseUrl}${ele.image}` : Model6}
              alt={ele?.name}
              className="lg:h-[40px] md:w-[70px] md:h-[40px] sm:w-[30px] sm:h-[30px] object-cover"
            />
          </div>
          <div>
            <h2 className="text-lg font-semibold text-[#000000]">
              {ele?.name}
            </h2>
            <p className="mt-1 text-[10px] md:text-[10px] text-[#95989C] font-medium font-poppins text-left">
              {ele?.description.split(" ").slice(0, 10).join(" ")}
            </p>
          </div>
        </div>

        {/* Comment: Added ref to stats section */}
        <div
          ref={statsRef}
          className="flex w-full mt-[4px] border-t-[1px] border-b-[1px] border-[#000000]/10"
        >
          <div className="flex flex-col space-y-1 w-1/3 py-1.5 pl-5 lg:pl-5 border-r-[1px] border-[#000000]/10">
            <div className="text-[12px] text-[#606060]/70 font-normal font-poppins pl-2">
              CAGR
            </div>
            {
              /* <p className="text-[14px] text-[#16A05E] font-poppins font-semibold pl-2">
            New Portfolio
          </p> */
              <p
                className={`text-[14px] text-[#16A05E] font-poppins font-semibold pl-2 ${
                  !globalConsent ? "blur-sm" : ""
                } cursor-pointer transition-all duration-300`}
                onClick={handleCAGRClick}
              >
                New Portfolio
              </p>
            }
          </div>
          <div className="flex flex-col space-y-1 w-1/3 py-1.5 lg:px-2 border-r-[1px] border-[#000000]/10">
            <div className="text-[12px] text-[#606060]/70 font-normal font-poppins pl-2">
              2Y Returns
            </div>
            <p
              className={`text-[14px] text-[#16A05E] font-poppins font-semibold pl-2 ${
                !globalConsent ? "blur-sm" : ""
              } cursor-pointer transition-all duration-300`}
              onClick={handleCAGRClick}
            >
              New Portfolio
            </p>
          </div>
          <div className="flex flex-col space-y-1 w-1/3 py-1.5 lg:px-1 rounded-md">
            <div className="text-[12px] text-[#606060]/70 font-normal font-poppins pl-2">
              Min. Investment
            </div>
            <p className="text-[14px] lg:text-[16px] text-[#000000] font-poppins font-semibold pl-2">
              ₹{formatCurrency(ele.minInvestment)}
            </p>
          </div>

          {/* Consent Popup */}
          {isConsentPopupOpen && (
            <>
              <div className="fixed inset-0 bg-black bg-opacity-40 z-50"></div>
              <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="bg-white p-6 rounded-lg shadow-lg max-w-md w-full">
                  <h2 className="text-xl font-bold mb-4">Consent Required</h2>
                  <p className="mb-6">
                    To view the CAGR information for this New Portfolio, we need
                    your consent. Do you agree to proceed?
                  </p>
                  <div className="flex justify-end space-x-4">
                    <button
                      className="px-4 py-2 bg-gray-200 rounded hover:bg-gray-300 transition-colors"
                      onClick={() => setIsConsentPopupOpen(false)}
                    >
                      Cancel
                    </button>
                    <button
                      className="px-4 py-2 bg-black text-white rounded hover:bg-gray-800 transition-colors"
                      onClick={handleConsentAccept}
                    >
                      I Agree
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        {/* Comment: Added ref to info section */}
        <div
          ref={infoRef}
          className="flex flex-col items-start justify-between px-5 mt-4"
        >
          <div className="w-full group relative flex items-start justify-between text-base text-[#000000]/80 font-poppins font-semibold">
            <div className="flex items-center">
              <Info size={13} />
              <p className="text-[12px] lg:text-[13px] px-1 lg:px-2 py-0 font-medium pl-2">
                Monthly Fees:{" "}
                {ele?.frequency?.length !== 0
                  ? `₹${parseInt(calculateMonthlyFee(ele?.pricing || {}))}`
                  : `₹${parseInt(getMonthlyFee(ele?.duration, ele?.amount))}`}
              </p>
            </div>
            <div className="flex items-center px-3 py-[1px] rounded-md">
              <img src={meter} className="w-[17.57px] h-[12px]" alt="Meter" />
              <span className="text-[12px] lg:text-[13px] px-2 lg:px-2 py-0 font-medium pl-2">
                Moderate Risk
              </span>
            </div>
          </div>
        </div>

        {/* Comment: Added ref to button section and mt-auto to push it to the bottom */}
        <div ref={buttonRef} className="p-4 mt-auto">
          <div className="flex space-x-2 w-full">
            <button className="w-full h-10 py-2 px-3 rounded-md bg-white border-[#000000]/20 border-[2px] text-black text-sm md:text-sm lg:text-sm font-poppins font-medium hover:scale-[1.03] transition-all duration-150 ease-linear">
              Performance
            </button>
            <button
              className="w-full h-10 py-2 px-3 rounded-md bg-black text-white text-sm md:text-sm lg:text-sm font-poppins font-medium hover:scale-[1.03] transition-all duration-150 ease-linear"
              onClick={
                ele?.subscription
                  ? undefined
                  : () => handlePricingCardClick(ele)
              }
            >
              Invest Now
            </button>
          </div>
        </div>
      </div>
    );
  }
);

const PortfolioSection = ({ allPricingPlan = [], handlePricingCardClick }) => {
  const [pricingPlans, setPricingPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const [globalConsent, setGlobalConsent] = useState(false);
  // Comment: Changed rowRefs to cardRefs
  const cardRefs = useRef([]);

  const handleGlobalConsent = () => {
    setGlobalConsent(true);
  };

  useEffect(() => {
    if (allPricingPlan.length > 0) {
      setPricingPlans(allPricingPlan);
      setIsLoading(false);
      setShowSkeleton(false); // Stop showing the skeleton when data is available
    } else {
      const timer = setTimeout(() => {
        setShowSkeleton(false); // Hide skeleton after 3 seconds
      }, 3000);

      return () => clearTimeout(timer); // Cleanup timer on component unmount
    }
  }, [allPricingPlan]);

  useEffect(() => {
    if (!isLoading) {
      adjustCardHeights();
    }
  }, [isLoading, pricingPlans]);

  // Comment: Updated adjustCardHeights function
  const adjustCardHeights = () => {
    const rows = [];
    let currentRow = [];
    cardRefs.current.forEach((card, index) => {
      if (card) {
        currentRow.push(card);
        if ((index + 1) % 3 === 0 || index === cardRefs.current.length - 1) {
          rows.push(currentRow);
          currentRow = [];
        }
      }
    });

    rows.forEach((row) => {
      const sections = ["headerRef", "statsRef", "infoRef", "buttonRef"];
      sections.forEach((section) => {
        const maxHeight = Math.max(
          ...row.map((card) => card[section]?.offsetHeight || 0)
        );
        row.forEach((card) => {
          if (card[section]) {
            card[section].style.height = `${maxHeight}px`;
          }
        });
      });
    });
  };

  const SkeletonCard = () => (
    <div className="w-full sm:w-[calc(50%-12px)] lg:w-[calc(33.333%-16px)] max-w-[350px] relative font-poppins min-h-[200px] rounded-xl bg-white border-[1px] border-[#000000]/10 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] py-4">
      <div className="flex items-start pl-7 pt-2 pb-7 md:items-center">
        <div className="overflow-hidden rounded-lg">
          <Skeleton width={128} height={25} />
          <Skeleton width={100} height={30} />
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div className="w-[80%] border-t-[1px] border-b-[1px] border-[#000000]/10"></div>
      </div>
      <div className="flex flex-col items-center pt-7 p-4 space-y-4 w-90">
        <div className="font-poppins text-[12.5px] min-h-[220px] flex flex-col items-left justify-start space-y-2 text-[#666666]">
          <Skeleton width={200} height={5} />
          <Skeleton width={200} height={5} />
          <Skeleton width={200} height={5} />
          <Skeleton width={200} height={5} />
          <Skeleton width={200} height={5} />
        </div>
      </div>
      <div className="p-4">
        <div className="flex justify-center align">
          <Skeleton width={280} height={44} />
        </div>
      </div>
    </div>
  );

  if (!showSkeleton && allPricingPlan.length === 0) {
    return null; // Return null after 3 seconds if no data
  }
  return (
    <div className="pt-12 pb-32" id="modelPortfolio">
      <h1 className="text-[28px] sm:text-3xl md:text-4xl font-bold text-black text-center mt-4 sm:mt-6 md:mt-8 px-4 leading-tight">
        Model Portfolios for Every Investor
      </h1>

      <div className="container mx-auto px-4 sm:px-20 pt-10 md:pt-14 lg:pt-14">
        <div className="flex flex-wrap justify-center gap-6">
          {isLoading
            ? Array(3)
                .fill(0)
                .map((_, i) => <SkeletonCard key={i} />)
            : pricingPlans.map((ele, i) => (
                // Comment: Added ref to Card component
                <Card
                  key={i}
                  ele={ele}
                  handlePricingCardClick={handlePricingCardClick}
                  globalConsent={globalConsent}
                  handleGlobalConsent={handleGlobalConsent}
                  ref={(el) => {
                    cardRefs.current[i] = el;
                  }}
                />
              ))}
        </div>
      </div>
    </div>
  );
};

export default PortfolioSection;
