import { DownloadIcon, EyeIcon } from "lucide-react";
import moment from "moment";
import React, { useState, useEffect } from "react";
import { CSVLink } from "react-csv";
import { useNavigate } from "react-router-dom";
import SendAdviceModel from "./SendAdviceModal";

const ACCEPTABLE_DATE_FORMATS = [
  "D MMM YYYY, HH:mm:ss",
  "YYYY-MM-DDTHH:mm:ss.SSSZ",
];

const GroupCard = ({
  csvHeaders,
  groupName,
  allClientDataByGroup,
  adminEmail,
  advisorName,
  email_password,
  adminRationale,
  advisorSpecifier,
}) => {
  const navigate = useNavigate();
  const [sendAdviceModal, setSendAdviceModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [filteredData, setFilteredData] = useState(allClientDataByGroup);
  const [searchQuery, setSearchQuery] = useState("");

  const handleViewFile = (groupName) => {
    navigate(`/admin/client-list/${groupName.toLowerCase()}`);
  };

  const openSendAdviceModal = () => {
    // Filter clients based on subscription validity
    const validClients = allClientDataByGroup.filter(
      (client) => client.isSubscriptionValid
    );
    setSendAdviceModal(true);
    setSelectedRows(validClients);
  };

  const closeSendAdviceModal = () => {
    setSendAdviceModal(false);
  };

  useEffect(() => {
    // Filter and sort on the full data set
    const sortedData = [...(allClientDataByGroup || [])].sort(
      (a, b) => new Date(b.creationDate) - new Date(a.creationDate)
    );

    const filtered = sortedData.filter((user) =>
      user?.email?.toLowerCase().includes(searchQuery.toLowerCase())
    );

    setFilteredData(filtered);
  }, [searchQuery, allClientDataByGroup]);

  const isSubscriptionValid = (subscriptions, groupName) => {
    if (!subscriptions || subscriptions?.length === 0) return false;
    const matchingPlanSubs = subscriptions?.filter(
      (sub) => sub?.plan === groupName
    );
    if (matchingPlanSubs?.length === 0) return false;
    const validSubscriptions = matchingPlanSubs?.filter((sub) =>
      sub.expiry
        ? moment(sub.expiry, ACCEPTABLE_DATE_FORMATS, true).isValid()
        : false
    );
    if (validSubscriptions?.length === 0) return false;
    const latestSub = validSubscriptions?.sort(
      (a, b) =>
        moment(b.expiry, ACCEPTABLE_DATE_FORMATS) -
        moment(a.expiry, ACCEPTABLE_DATE_FORMATS)
    )[0];
    const expiryDate = moment(latestSub?.expiry, ACCEPTABLE_DATE_FORMATS);
    const today = moment();
    return expiryDate.isAfter(today);
  };

  return (
    <>
      <div className="flex  items-center justify-between  hover:bg-[#000000]/5 w-full px-6 py-[10px] border-b-[1px] border-[#000000]/10">
        <div className="text-[15px]  font-medium text-[#000000] font-poppins capitalize w-28">
          {groupName?.replace(/_/g, " ")}
        </div>
        <div className="flex items-center justify-end text-[15px]  font-medium text-[#000000] font-poppins w-12 ">
          {filteredData?.filter((client) =>
            isSubscriptionValid(client.subscriptions, groupName)
          )?.length || 0}
        </div>

        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-1">
            <CSVLink
              data={allClientDataByGroup}
              headers={csvHeaders}
              filename={`${groupName
                .toLowerCase()
                .replace(/ /g, "_")}_${moment().format("YYYYMMDD_HHmmss")}.csv`}
              className=""
            >
              <div className="group flex items-center justify-center cursor-pointer w-7 h-7 p-1 rounded-md hover:bg-[#000000] ">
                <DownloadIcon className="h-4 w-4 text-black group-hover:text-white cursor-pointer hover:scale-[1.07] " />
              </div>
            </CSVLink>

            <div
              onClick={() => handleViewFile(groupName)}
              className=" group  flex items-center justify-center cursor-pointer w-7 h-7 p-1 rounded-md hover:bg-[#000000]"
            >
              <EyeIcon className="h-5 w-5  text-black group-hover:text-white   cursor-pointer hover:scale-[1.07]" />
            </div>
          </div>
          <button
            onClick={openSendAdviceModal}
            className="py-1 px-5 bg-white text-black hover:bg-black font-poppins font-medium border-[1px] border-[#1D1D1F]/20 hover:text-white rounded-md flex flex-row justify-center items-center cursor-pointer transition-all duration-300 ease-linear"
          >
            Send
          </button>
        </div>
      </div>

      {sendAdviceModal === true ? (
        <SendAdviceModel
          fileName={groupName}
          closeSendAdviceModal={closeSendAdviceModal}
          data={allClientDataByGroup}
          adminEmail={adminEmail}
          advisorName={advisorName}
          email_password={email_password}
          setSendAdviceModal={setSendAdviceModal}
          setSelectedRows={setSelectedRows}
          adminRationale={adminRationale}
          advisorSpecifier={advisorSpecifier}
        />
      ) : null}
    </>
  );
};

export default GroupCard;
