import React, { useEffect, useState } from "react";
import axios from "axios";
import toast from "react-hot-toast";
import { Loader2 } from "lucide-react";

import server from "../../utils/serverConfig";
import LoadingSpineer from "../../components/LoadingSpinner";
import { Dialog, DialogContent } from "../../components/ui/dialog";
import PlanSubscribeModal from "../PricingSection/PlanSubscribeModal";

const rootUrl = process.env.REACT_APP_URL;
const advisorTag = process.env.REACT_APP_ADVISOR_SPECIFIC_TAG;
const whiteLabelText = process.env.REACT_APP_WHITE_LABEL_TEXT;
const razorPayKey = process.env.REACT_APP_RAZORPAY_LIVE_API_KEY;
const countAdvice = process.env.REACT_APP_COUNT_SEND_ADVICE;
const oneTimePurchasePlanAmount = process.env.REACT_APP_PURCHASE_PROMPT_AMOUNT;

const PurchasePrompt = ({
  setShowPurchasePrompt,
  userDetails,
  getUserDetails,
  showPurchasePrompt,
  handleDismiss,
  setPaymentSuccess,
  selectedCard,
  handleSendAdvice,
  mobileNumber,
  setMobileNumber,
}) => {
  const [name, setName] = useState(userDetails ? userDetails?.name : null);

  const [panNumber, setPanNumber] = useState(
    userDetails ? userDetails?.panNumber : null
  );

  const [birthDate, setBirthDate] = useState(
    userDetails ? userDetails?.DateofBirth : null
  );
  useEffect(() => {
    if (userDetails) {
      setPanNumber(userDetails?.panNumber);
      setName(userDetails?.name);
    }
  }, [userDetails]);

  const [isPostPaymentProcessing, setIsPostPaymentProcessing] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("+91");

  const handleNotNeeded = () => {
    setLoading(true);

    const adviceData = {
      email: userDetails?.email,
      previous_stocks_advice_needed: false,
    };
    let config = {
      method: "post",
      url: `${server.server.baseUrl}api/stocks-list/update-user`,
      headers: {
        "Content-Type": "application/json",
      },
      data: adviceData,
    };

    axios
      .request(config)
      .then((response) => {
        getUserDetails();
        setLoading(false);
        toast.success("Your details has been updated successfully", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error in updating Data !", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
        setLoading(false);
      });
  };

  const [plan, setPlan] = useState();
  const getAllPlans = () => {
    let config = {
      method: "get",
      url: `${server.server.baseUrl}api/admin/plan/${advisorTag}/bespoke/${userDetails?.email}`,
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .request(config)
      .then((response) => {
        const plans = response.data?.data; // Extract the plan data array
        if (Array.isArray(plans)) {
          const oneTimePurchasePlan = plans?.find(
            (plan) => plan.name === "OneTimePurchase"
          );
          if (oneTimePurchasePlan) {
            setPlan(oneTimePurchasePlan);
          } else {
            console.log("No plan with the name 'OneTimePurchase' found.");
          }
        } else {
          console.log("Invalid response data format.");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getAllPlans();
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const logPayment = async (type, data) => {
    try {
      await axios.post(`${server.server.baseUrl}api/log-payment`, {
        type,
        data,
      });
    } catch (error) {
      console.error("Failed to log payment:", error);
    }
  };

  const sendEmailNotification = async (
    email,
    planDetails,
    userName,
    panNumber,
    advisorName,
    tradingPlatform,
    data
  ) => {
    try {
      const latestPayment = data?.subscription?.payment_history.sort(
        (a, b) => new Date(b?.payment_date) - new Date(a?.payment_date)
      )[0];

      const emailData = [
        {
          template_name: "new_plan2",
          template_body_values: [
            userName || email,
            `${
              latestPayment?.payment_type === "extension" &&
              latestPayment?.previous_end_date
                ? latestPayment?.previous_end_date
                : latestPayment?.payment_date
            }`,
            `${latestPayment?.new_end_date}`,
          ],
          trade_given_by: advisorName,
          recipient_email: email,
          plan_name: planDetails.name,
          pan: panNumber,
        },
      ];

      const emailResponse = await axios.post(
        `${server.ccxtServer.baseUrl}/comms/email/send-template-messages/supported-broker`,
        emailData
      );

      if (emailResponse.data.status !== 0) {
        console.error("Email sending failed:", emailResponse.data);
      }

      return emailResponse;
    } catch (error) {
      console.error("Email notification error:", {
        message: error.message,
        response: error.response?.data,
        statusCode: error.response?.status,
        email: email,
      });
      // Don't throw error to prevent disrupting the main flow
    }
  };

  // Example usage in your WhatsApp notification function
  const sendWhatsAppNotification = async (
    phoneNumber,
    countryCode,
    planDetails,
    userName,
    email,
    advisorName,
    data
  ) => {
    try {
      const latestPayment = data.subscription.payment_history.sort(
        (a, b) => new Date(b.payment_date) - new Date(a.payment_date)
      )[0];
      // Format phone number - remove any non-digit characters
      let formattedPhone: *;
      formattedPhone = phoneNumber.toString().replace(/\D/g, "");
      let formattedCountryCode = countryCode; //.toString().replace(/\D/g, '');
      // Then add '+' prefix if it doesn't exist
      formattedCountryCode = formattedCountryCode.startsWith("+")
        ? formattedCountryCode
        : `+${formattedCountryCode}`;

      const getAdvisorCodomain = (advisor) => {
        if (advisor === "AlphaQuark") return "prod";
        if (advisor === "AlphaQuarkTest") return "test";
        return advisor.toLowerCase();
      };

      if (latestPayment?.payment_type === "extension") {
        // console.log("calling", planDetails.isRenewal);
        await axios.post(
          `${server.ccxtServer.baseUrl}comms/whatsapp/send-template`,
          {
            phone_number: formattedPhone,
            template_name: "new_plan2",
            template_body_values: [
              userName || email,
              latestPayment?.payment_type === "extension"
                ? "renewed"
                : "subscribed",
              `${
                latestPayment?.payment_type === "extension" &&
                latestPayment?.previous_end_date
                  ? latestPayment?.previous_end_date
                  : latestPayment?.payment_date
              }`,
              `${latestPayment?.new_end_date}`,
              advisorName,
            ],
            template_button_values: [getAdvisorCodomain(advisorName)],
            template_header_values: [
              latestPayment?.payment_type === "extension"
                ? "Renewed"
                : "Subscribed",
            ],
            country_code: countryCode,
            callback_data: "Standard Callback",
            language_code: "en",
          }
        );
      } else {
        const trackUserResponse = await axios.post(
          `${server.ccxtServer.baseUrl}comms/whatsapp/track-user`,
          {
            phone_number: formattedPhone,
            country_code: formattedCountryCode,
            user_traits: {
              name: userName || email,
              email: email,
              advisor: advisorName,
              advisor_codomain: rootUrl,
              whatsapp_opted_in: true,
            },
            tags: [advisorName, "internal_team"],
          }
        );

        if (trackUserResponse.data.result.result === true) {
          // Then send template message
          await axios.post(
            `${server.ccxtServer.baseUrl}comms/whatsapp/send-template`,
            {
              phone_number: formattedPhone,
              template_name: "new_plan2",
              template_body_values: [
                userName || email,
                latestPayment?.payment_type === "extension"
                  ? "renewed"
                  : "subscribed",
                `${
                  latestPayment?.payment_type === "extension" &&
                  latestPayment?.previous_end_date
                    ? latestPayment?.previous_end_date
                    : latestPayment?.payment_date
                }`,
                `${latestPayment?.new_end_date}`,
                advisorName,
              ],
              template_button_values: [getAdvisorCodomain(advisorName)],
              template_header_values: [
                latestPayment?.payment_type === "extension"
                  ? "Renewed"
                  : "Subscribed",
              ],
              country_code: countryCode,
              callback_data: "Standard Callback",
              language_code: "en",
            }
          );
        }
      }
      // First track the user
    } catch (error) {
      console.error("WhatsApp notification error:", error);
      // Don't throw error to prevent disrupting the main flow
    }
  };

  const sendNotifications = async (notificationData) => {
    const {
      email,
      phoneNumber,
      countryCode,
      planDetails,
      panNumber,
      userName,
      advisorName,
      tradingPlatform,
      data,
    } = notificationData;

    try {
      // Send email first
      await sendEmailNotification(
        email,
        planDetails,
        userName,
        panNumber,
        advisorName,
        tradingPlatform,
        data
      );

      // Then send WhatsApp notification
      await sendWhatsAppNotification(
        phoneNumber,
        countryCode,
        planDetails,
        userName,
        email,
        advisorName,
        data
      );
    } catch (error) {
      console.error("Notification error:", error);
    }
  };

  async function handleSinglePayment(amount) {
    try {
      setIsLoading(true);
      const response = await axios.post(
        `${server.server.baseUrl}api/admin/subscription/one-time-payment/subscription`,
        {
          plan_id: plan?._id,
          user_email: userDetails?.email,
          amount,
          advisor: advisorTag,
        }
      );
      setIsLoading(false);
      setPaymentModal(false);
      const paymentData = response.data.data;

      if (paymentData.razorpay_order_id) {
        // Initialize Razorpay with the order details
        const options = {
          key: razorPayKey,
          order_id: paymentData.razorpay_order_id,
          name: paymentData.plan_id.name,
          description: paymentData?.razorpay_order_id,
          amount: paymentData.amount,
          currency: "INR",
          handler: function (response) {
            setIsPostPaymentProcessing(true);
            completeSinglePayment(response);
          },
          modal: {
            ondismiss: function () {
              alert("Payment was not completed. Please try again.");
            },
          },
          prefill: {
            name: "",
            email: userDetails?.email,
          },
          theme: {
            color: "#F37254",
          },
        };
        // Initialize and open the Razorpay Checkout
        const rzp = new window.Razorpay(options);
        rzp.open();
      } else {
        console.error("Error fetching one-time payment data");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error initiating one-time payment:", error);
      setIsLoading(false);
    }
  }

  async function completeSinglePayment(paymentDetails) {
    try {
      // First, check for existing payment and plan
      const existingPayment = await axios.post(
        `${server.server.baseUrl}api/subscription-check/check-payment-status`,
        {
          razorpay_order_id: paymentDetails.razorpay_order_id,
          plan_id: plan?._id,
          user_email: userDetails?.email,
        }
      );

      if (existingPayment.data.orderExists) {
        throw new Error("This payment has already been processed");
      }

      let expiryDate;
      let isSubscriptionExtension = false;

      // Complete payment with backend
      const response = await axios.post(
        `${server.server.baseUrl}api/admin/subscription/one-time-payment/subscription/complete-one-time-payment`,
        {
          ...paymentDetails,
          user_email: userDetails?.email,
          advisor_email: plan?.advisor_email,
          plan_id: plan?._id,
          amount: plan?.amount,
          end_date:
            expiryDate ||
            new Date(
              new Date().setDate(new Date().getDate() + (plan?.duration || 30))
            ),
          newExpiryDate: expiryDate,
        }
      );

      const data = response.data;
      setIsPostPaymentProcessing(false);
      await logPayment("PAYMENT_SUCCESS", {
        orderId: data.subscription.razorpay_order_id,
        amount: plan?.amount,
        clientName: name,
        email: userDetails?.email,
        plan: plan?.name,
      });

      const adviceData = {
        email: userDetails?.email,
      };
      let config = {
        method: "post",
        url: `${server.server.baseUrl}api/stocks-list/update-user/previous_advice_purchased`,
        headers: {
          "Content-Type": "application/json",
        },
        data: adviceData,
      };

      axios
        .request(config)
        .then((response) => {
          getUserDetails();
        })
        .catch((error) => {
          console.log(error);
        });
      handleSendAdvice();
      setPaymentSuccess(true);

      // Send WhatsApp notification first
      try {
        await sendNotifications({
          email: userDetails?.email,
          phoneNumber: mobileNumber,
          countryCode: countryCode,
          panNumber: panNumber,
          planDetails: {
            isRenewal: isSubscriptionExtension,
            duration: plan?.duration || "30",
            name: plan?.name,
            amount: plan?.amount,
          },
          userName: name,
          advisorName: whiteLabelText,
          tradingPlatform: "supported-broker", // or 'unsupported-broker' based on your needs
          data: data,
        });
      } catch (notificationError) {
        console.error("Notifications failed:", notificationError);
        // Continue execution - notification failure shouldn't stop the process
      }

      // Create new subscription record
      const newSubscription = {
        startDate: new Date(),
        plan: plan?.name || "",
        capital: 0,
        charges: plan?.amount || 0,
        invoice: paymentDetails.razorpay_order_id || "",
        expiry: expiryDate || data.subscription.end_date,
      };

      // Update client data in same way as before
      const clientResponse = await handleClientUpdate(
        isSubscriptionExtension,
        newSubscription,
        plan?.adminId,
        data.subscription
      );
    } catch (error) {
      await logPayment("PAYMENT_FAILURE", {
        error: error.message,
        clientName: name,
        email: userDetails?.email,
        amount: plan?.amount,
      });
      toast.error(`Error processing payment: ${error.message}`, {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
      throw error;
    }
  }

  async function handleClientUpdate(
    isExtension,
    newSubscription,
    adminId,
    subscriptionData
  ) {
    // Generate clientId if not already present
    const clientData = {
      clientName: name || "",
      email: subscriptionData.user_email || "",
      phone: mobileNumber || "",
      groups: [`All Client`, plan?.name],
      location: "",
      telegram: "",
      pan: panNumber || "",
      creationDate: new Date(),
      subscriptions: [newSubscription],
    };

    try {
      const checkClientResponse = await fetch(
        `${server.server.baseUrl}api/add-subscriptions/check-client`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: adminId,
            email: clientData.email,
          }),
        }
      );

      const checkClientResult = await checkClientResponse.json();
      await logPayment("CLIENT_ADDED", {
        clientName: name,
        plan: plan?.name,
        subscriptionDetails: newSubscription,
      });
      if (checkClientResult.clientExists) {
        return fetch(
          `${server.ccxtServer.baseUrl}comms/add-new-client-to-groups`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userId: adminId,
              DateofBirth: birthDate || "",
              advisorName: advisorTag,
              clientData: clientData,
            }),
          }
        );
      } else {
        return fetch(
          `${server.ccxtServer.baseUrl}comms/add-new-client-to-groups`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userId: adminId,
              DateofBirth: birthDate || "",
              advisorName: advisorTag,
              clientData: clientData,
            }),
          }
        );
      }
    } catch (error) {
      console.error("Error updating client data:", error);
      throw error;
    }
  }

  const handleOk = async () => {
    try {
      // First update the user profile
      const profileData = {
        uid: userDetails && userDetails._id,
        email: userDetails?.email,
        phoneNumber: mobileNumber,
        countryCode: countryCode,
        userName: name,
        panNumber: panNumber,
        profileCompletion: 75,
        advisorName: advisorTag,
      };

      await axios.put(
        `${server.server.baseUrl}api/user/update/user-details`,
        profileData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      await handleSinglePayment(plan?.amount);
      getUserDetails();
      setShowPurchasePrompt(false);
    } catch (error) {
      console.error("Error updating profile or processing payment:", error);
      toast.error(error.response?.data?.message || "Failed to process request");
    }
  };

  const handlePricingCardClick = (modelName) => {
    setPaymentModal(true);
  };
  return (
    <>
      <Dialog open={showPurchasePrompt} onOpenChange={handleDismiss}>
        <DialogContent className="sm:max-w-[600px] px-12 pt-12">
          <div className="space-y-8">
            <div className="text-lg text-[#000000] font-normal font-poppins">
              Welcome to {whiteLabelText}. Would you like to check out{" "}
              {countAdvice} previous recommendations as well? Just pay Rs
              {oneTimePurchasePlanAmount} for this one time offering
            </div>
            <div className="text-lg text-[#000000] font-normal font-poppins">
              Please note that these are historical recommendations and current
              price could be outside of advised range
            </div>
          </div>

          <div className="flex justify-between mt-12 ">
            <div
              className="px-12 py-2.5 bg-black text-[16px] text-white font-semibold font-poppins cursor-pointer rounded-lg"
              onClick={handleNotNeeded}
            >
              {loading === true ? <LoadingSpineer /> : "Not Needed"}
            </div>
            <div
              className="px-12 py-2.5 bg-black text-[16px] text-white font-semibold font-poppins cursor-pointer rounded-lg"
              onClick={handlePricingCardClick}
            >
              Purchase
            </div>
          </div>
        </DialogContent>
      </Dialog>

      {paymentModal && (
        <PlanSubscribeModal
          userEmail={userDetails?.email}
          strategyDetails={plan}
          paymentModal={paymentModal}
          setPaymentModal={setPaymentModal}
          handleOk={handleOk}
          loading={isLoading}
          selectedCard={selectedCard}
          setName={setName}
          name={name}
          setMobileNumber={setMobileNumber}
          mobileNumber={mobileNumber}
          setPanNumber={setPanNumber}
          panNumber={panNumber}
          birthDate={birthDate}
          setBirthDate={setBirthDate}
        />
      )}

      {isPostPaymentProcessing && (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 shadow-xl flex flex-col items-center space-y-4">
            <Loader2 className="h-20 w-20 text-blue-500 animate-spin" />

            <p className="text-sm text-gray-500">
              Please wait while we confirm your subscription...
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default PurchasePrompt;
