import React, { useState } from "react";
import { Link } from "react-router-dom";
import { cn } from "../utils/utils";
import Logo from "../assests/Logo.jpg";
import { useDispatch } from "react-redux";
import { logout } from "../store/actions/AuthActions";
import { Drawer, DrawerContent } from "../components/ui/drawer";

import {
  LogOutIcon,
  XIcon,
  CircleDashedIcon,
  HandCoins,
  TablePropertiesIcon,
  TrendingUpIcon,
  UsersRoundIcon,
  HomeIcon,
  GitForkIcon,
  SettingsIcon,
  FileBarChartIcon,
} from "lucide-react";

const Icons = {
  home: <HomeIcon className="mr-5 h-5 w-5" />,
  users: <UsersRoundIcon className="mr-5 h-5 w-5" />,
  advice: <TrendingUpIcon className="mr-5 h-5 w-5" />,
  status: <CircleDashedIcon className="mr-5 w-5 h-5" />,
  universal: <TablePropertiesIcon className="mr-5 w-5 h-5" />,
  strategy: <GitForkIcon className="mr-5 h-5 w-5 -rotate-90" />,
  plans: <HandCoins className="mr-5 h-5 w-5 " />,
  settings: <SettingsIcon className="mr-5 h-5 w-5" />,
  prior: <FileBarChartIcon className="mr-5 h-5 w-5" />,
};

const mobileNavItems = [
  {
    title: "Home",
    href: "/admin",
    path: "admin",
    icon: "home",
  },
  {
    title: "Client List",
    href: "/admin/client-list",
    path: "client-list",
    icon: "users",
  },
  // {
  //   title: "Send Advice",
  //   href: "/send-recommendation",
  //   path: "send-recommendation",
  //   icon: "advice",
  // },
  {
    title: "Advice Status",
    href: "/admin/send-recommendation-status",
    path: "send-recommendation-status",
    icon: "status",
  },
  // {
  //   title: "Universal List",
  //   href: "/universal-list",
  //   path: "universal-list",
  //   icon: "universal",
  // },
  {
    title: "Plans",
    href: "/admin/admin-plans",
    path: "admin-plans",
    icon: "plans",
  },
  {
    title: "Model Portfolio",
    href: "/admin/admin-strategy",
    path: "admin-strategy",
    icon: "strategy",
  },
  {
    title: "Prior Advice",
    href: "/admin/prior-advice",
    path: "prior-advice",
    icon: "prior",
  },
  {
    title: "Settings",
    href: "/admin/admin-settings",
    path: "admin-settings",
    icon: "settings",
  },
];

const AdminMobileNavBar = (
  { isOpen, onClose, adminEmail, adminName, adminImgUrl },
  props
) => {
  const path = window.location.pathname;
  const whiteLabelText = process.env.REACT_APP_WHITE_LABEL_TEXT;
  const dispatch = useDispatch();
  const [showLogoutModel, setShowLogoutModel] = useState(false);
  const onLogout = (e) => {
    e.preventDefault();
    dispatch(logout(props.history));
  };

  if (!mobileNavItems?.length) {
    return null;
  }

  const saved = localStorage.getItem("userDetails");
  const initialValue = JSON.parse(saved);
  const userDetails = initialValue && initialValue.response;

  var intials;
  var fullName = userDetails && userDetails[4];
  intials =
    fullName &&
    fullName
      .split(" ")
      .map((name) => name[0])
      .join("")
      .toUpperCase();

  return (
    <>
      <div
        className={`fixed inset-0 z-50 ${
          isOpen ? "block " : "hidden"
        } transition ease-in duration-300`}
      >
        {/* Background Overlay */}
        <div
          className={`fixed inset-0 bg-black opacity-40  ${
            isOpen ? "opacity-100" : "opacity-0"
          }`}
          onClick={onClose}
        ></div>

        {/* Drawer */}
        <div
          className={`fixed inset-y-0 left-0 w-full bg-black z-50 transform transition ease-in duration-500   ${
            isOpen ? "translate-x-0 animate-slideInNav" : "-translate-x-full "
          }`}
        >
          <aside
            className={`flex flex-col  transform top-0 left-0 w-full bg-black fixed h-full overflow-auto ease-in-out transition-all duration-300 z-30   ${
              isOpen
                ? "translate-x-0 animate-slideInNav"
                : "-translate-x-full animate-slideOutNav"
            }`}
          >
            <div
              onClick={onClose}
              className={`absolute   z-100 top-[26px] right-3`}
            >
              <XIcon className="h-6 w-6  text-white" />
            </div>
            <div className={` md:mb-12`}>
              <div className="flex flex-row space-x-6 items-center px-8 py-4">
                <div className="">
                  <img
                    src={Logo}
                    alt="Logo"
                    width={45}
                    className="rounded-md"
                  />
                </div>
                <div className="text-[24px] font-medium font-poppins text-[#f2f2f2] leading-[40px] ">
                  {whiteLabelText}
                </div>
              </div>
            </div>

            <div className="flex flex-col flex-1 gap-y-1  pt-4">
              {mobileNavItems.map((item, index) => {
                const Icon = Icons[item.icon];

                return (
                  item.href && (
                    <div key={index}>
                      <Link
                        key={index}
                        to={item.disabled ? "/admin" : item.href}
                      >
                        <div
                          className={cn(
                            "group  md:py-5 text-sm md:text-2xl border-l-4   hover:bg-[#ffffff]/20 hover:text-gray-300 text-[18px] text-[#ffffff]/50 font-normal font-poppins leading-[22px] flex items-center px-[30px] py-[10px]",
                            path === item.href
                              ? "bg-[#ffffff]/20 border-l-4border-[#ffffff] text-[#ffffff]"
                              : "border-l-4 border-transparent bg-transparent",
                            item.disabled && "cursor-not-allowed opacity-80"
                          )}
                        >
                          {Icon}
                          <span>{item.title}</span>
                        </div>
                      </Link>
                    </div>
                  )
                );
              })}
              <div
                className={cn(
                  "group cursor-pointer text-sm border-l-4 border-transparent bg-transparent hover:bg-[#ffffff]/20 hover:text-gray-300 text-[18px] text-[#ffffff]/50 font-normal font-poppins leading-[22px] flex items-center px-[30px] py-[14px]"
                )}
                onClick={() => setShowLogoutModel(true)}
              >
                <LogOutIcon className="h-5 w-5 text-[#ffffff]/50 group-hover:text-gray-300  mr-5" />
                <span>Logout</span>
              </div>
            </div>

            {/* <div className="absolute bottom-1 flex  justify-between items-center  h-[70px] w-full border-t-[1px] border-[#ffffff]/20 px-6 ">
            <div className="flex  space-x-3 items-center   text-white text-center py-2   ">
              <div className=" flex items-center justify-center rounded-full ring-[3px]  ring-[#ffffff]/30 w-[50px] h-[50px] p-0.5 ">
                <User2Icon className="w-[25px] h-[25px] text-white " />
              </div>

              <div className="flex font-medium font-poppins text-2xl items-center  justify-center rounded-full border-[2px] border-[#ffffff]/30 w-[55px] h-[55px] p-1">
                admin
              </div>

              <div className="flex flex-col items-start">
                <span className="text-[15px] text-[#F2F2F2] font-poppins font-medium">
                  admin
                </span>
                <span className="text-[13px] text-[#D9D9D9]/60 font-poppins font-normal">
                  admin@gmail.com
                </span>
              </div>
            </div>
          </div> */}

            <div
              className={`absolute bottom-2 w-full flex h-[60px] space-x-4 items-center py-4  text-white text-center border-t-[1px] border-[#ffffff]/20 px-6 `}
            >
              <div className="flex  space-x-3 items-center   text-white text-center   ">
                {adminImgUrl && adminImgUrl !== "" && adminImgUrl !== null ? (
                  <div className=" flex items-center justify-center rounded-full ring-[3px]  ring-[#ffffff]/20 w-[40px] h-[40px]  cursor-pointer">
                    <img
                      src={adminImgUrl}
                      alt="Profile Image"
                      width={50}
                      className="rounded-full"
                    />
                  </div>
                ) : (
                  <div className="flex font-medium font-poppins text-[20px] items-center  justify-center rounded-full border-[2px] border-[#ffffff]/30 w-[45px] h-[45px] p-1 cursor-pointer">
                    {adminEmail.slice(0, 2).toUpperCase()}
                  </div>
                )}
                <div className="flex flex-col items-start">
                  <span className="text-[15px] text-[#F2F2F2] font-poppins font-medium">
                    {adminName}
                  </span>
                  <span className="text-[13px] text-[#D9D9D9]/60 font-poppins font-normal">
                    {adminEmail}
                  </span>
                </div>
              </div>
            </div>
          </aside>
        </div>
      </div>
      <Drawer open={showLogoutModel} onOpenChange={setShowLogoutModel}>
        <DrawerContent className="focus:outline-none max-h-[560px] animate-modal">
          <div className="flex flex-col h-full justify-between p-4">
            <div className="text-center">
              <button
                onClick={() => setShowLogoutModel(false)}
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
              >
                <XIcon className="w-6 h-6" />
              </button>
              <h2 className="text-[18px] font-medium font-poppins mt-2 mb-2">
                Are you sure you want to logout?
              </h2>
            </div>
            <div className="grid grid-cols-2 gap-x-4 gap-y-2 mt-4 mb-4">
              <button
                onClick={() => setShowLogoutModel(false)}
                className="bg-[#ffffff] text-black border-[1px] border-[#000000]/20 text-[17px] font-medium font-poppins rounded-md px-10 py-2 cursor-pointer"
              >
                Cancel
              </button>
              <button
                onClick={onLogout}
                className="bg-[#ff0000] text-white text-[17px] font-medium font-poppins rounded-md px-10 py-2 cursor-pointer hover:scale-[1.04] transition-all duration-150 ease-linear"
              >
                Logout
              </button>
            </div>
          </div>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default AdminMobileNavBar;
