import React from "react";
import DatePicker from "../ui/date-picker";

const InputField = ({
  label,
  id,
  name,
  type = "text",
  value,
  onChange,
  required = false,
  placeholder,
  disabled = false,
}) => (
  <div className="flex flex-col space-y-1">
    <label htmlFor={id} className="text-sm font-medium text-gray-700">
      {label}
      {required && <span className="ml-1 text-red-500">*</span>}
    </label>
    <input
      id={id}
      name={name}
      type={type}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
      className={`px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-2 focus:ring-indigo-500 focus:border-transparent ${
        disabled ? "bg-gray-200 cursor-not-allowed" : ""
      }`}
    />
  </div>
);

export const ClientFormFields = ({
  data,
  onInputChange,
  setExpiryDate,
  expiryDate,
  dateOfBirth,
  setDateOfBirth,
  emailEdit,
}) => {
  return (
    <div className="relative grid grid-cols-1 md:grid-cols-2 gap-6">
      <InputField
        label="Email"
        id="clientEmail"
        name="clientEmail"
        type="email"
        value={data.clientEmail}
        onChange={onInputChange}
        required
        placeholder="Enter Email"
        disabled={emailEdit === true} // Disable if clientEmail exists
      />

      <InputField
        label="Name"
        id="clientName"
        name="clientName"
        value={data.clientName}
        onChange={onInputChange}
        required
        placeholder="Enter Name"
      />

      <InputField
        label="Phone"
        id="phone"
        name="phone"
        type="tel"
        value={data.phone}
        onChange={onInputChange}
        required
        placeholder="Enter Phone"
      />
      <div className="flex flex-col space-y-1">
        <label className="text-sm font-medium text-gray-700">
          Date of Birth
        </label>
        <DatePicker
          date={dateOfBirth}
          setDate={setDateOfBirth}
          placeholder="Choose Date of Birth`"
          className="w-full"
        />
      </div>

      <InputField
        label="Location"
        id="location"
        name="location"
        value={data.location}
        onChange={onInputChange}
        placeholder="Enter Location"
      />

      <InputField
        label="Telegram"
        id="telegram"
        name="telegram"
        value={data.telegram}
        onChange={onInputChange}
        placeholder="Enter Telegram ID"
      />

      <InputField
        label="KYC"
        id="pan"
        name="pan"
        value={data.pan}
        onChange={onInputChange}
        placeholder="Enter KYC"
      />

      <div className="flex flex-col space-y-1">
        <label className="text-sm font-medium text-gray-700">
          Plan Expiry Date
          <span className="ml-1 text-red-500">*</span>
        </label>
        <DatePicker
          date={expiryDate}
          setDate={setExpiryDate}
          placeholder="Choose Plan Expiry Date"
          className="w-full"
          expirySet={true}
        />
      </div>
    </div>
  );
};
