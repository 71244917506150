import axios from "axios";
import server from "../utils/serverConfig";

/** Creates a plan group entity in the backend. */
export function createPlanGroup(name, advisorId, advisorEmail, plans) {
  return axios
    .post(`${server.server.baseUrl}api/admin/plan-groups`, {
      name,
      advisorId,
      advisorEmail,
      plans,
    })
    .then((response) => response.data.data.planGroup);
}

/** Updates a plan group entity in the backend. */
export function updatePlanGroup(groupId, advisorId, advisorEmail, plans) {
  return axios.put(`${server.server.baseUrl}api/admin/plan-groups/${groupId}`, {
    advisorId,
    advisorEmail,
    plans,
  });
}

/** Deletes a plan group entity in the backend. */
export function deletePlanGroup(groupId) {
  return axios.delete(
    `${server.server.baseUrl}api/admin/plan-groups/${groupId}`
  );
}

/** Fetches a specific plan group given a groupId. */
export function getPlanGroup(groupId) {
  return axios.get(`${server.server.baseUrl}api/admin/plan-groups/${groupId}`);
}

/** Fetches all the plan groups corresponding to the provided advisor email. */
export function getAllPlanGroupsForAdvisor(advisorEmail) {
  return axios.get(
    `${server.server.baseUrl}api/admin/plan-groups/search-by-advisor/${advisorEmail}`
  );
}
