import React, { useState } from "react";
import {
  ChevronDown,
  X,
  Trash2,
  AlertTriangle,
  SquarePlus,
} from "lucide-react";
import { Dialog, DialogContent } from "../components/ui/dialog";

const Derivatives = () => {
  const [activeTab, setActiveTab] = useState("equity");
  const [isOpen, setIsOpen] = useState(false);
  const [isReviewOpen, setIsReviewOpen] = useState(false);
  const [strategyName, setStrategyName] = useState("Iron Condor");

  const openDialog = () => setIsOpen(true);
  const closeDialog = () => setIsOpen(false);
  const openReviewModal = () => setIsReviewOpen(true);
  const closeReviewModal = () => setIsReviewOpen(false);
  // Update the state to include includeDerivatives
  const [includeDerivatives, setIncludeDerivatives] = useState(false);
  const [showBasketEntry, setShowBasketEntry] = useState(false);

  const strategies = [
    { name: "Bull Call", icon: "📈" },
    { name: "Bull Put", icon: "📉" },
    { name: "Bull Call Spread", icon: "📊" },
    { name: "Bull Put Spread", icon: "📋" },
    { name: "Call Ratio Back", icon: "📈" },
    { name: "Long Calendar", icon: "📅" },
    { name: "Bull Combo", icon: "📊" },
    { name: "Bull Butterfly", icon: "🦋" },
  ];

  const trades = [
    {
      type: "B",
      symbol: "NIFTY24OCT18500CE",
      expiry: "17 Oct",
      strike: "24000",
      cepe: "CE",
      lots: "1",
      price: "130.05",
    },
    {
      type: "S",
      symbol: "NIFTY24OCT18500CE",
      expiry: "17 Oct",
      strike: "24000",
      cepe: "CE",
      lots: "1",
      price: "130.05",
    },
  ];

  const reviewTrades = [
    {
      strategyName: "Butterfly Iron",
      rationale: "The selected stocks have been carefully ...",
      trades: [
        {
          type: "B",
          symbol: "NIFTY24OCT18500CE",
          expiry: "17 Oct",
          strike: "24000",
          cepe: "CE",
          lots: "1",
          price: "130.05",
        },
        {
          type: "S",
          symbol: "NIFTY24OCT18500CE",
          expiry: "17 Oct",
          strike: "24000",
          cepe: "CE",
          lots: "1",
          price: "130.05",
        },
      ],
    },
    {
      strategyName: "Calendar Spread",
      rationale: "The selected stocks have been carefully ...",
      trades: [
        {
          type: "B",
          symbol: "NIFTY24OCT18500CE",
          expiry: "17 Oct",
          strike: "24000",
          cepe: "CE",
          lots: "1",
          price: "130.05",
        },
        {
          type: "S",
          symbol: "NIFTY24OCT18500CE",
          expiry: "17 Oct",
          strike: "24000",
          cepe: "CE",
          lots: "1",
          price: "130.05",
        },
      ],
    },
  ];


  const equityTrades = [
    {
      symbol: "",
      mktLim: "Market",
      type: "Delivery",
      qty: "10",
      ltp: "130.05",
      price: "130.05",
      range: { low: "Low", high: "High" },
      rationale: "The selected stocks have been carefully ...."
    },
    {
      symbol: "",
      mktLim: "Market",
      type: "Delivery",
      qty: "10",
      ltp: "130.05",
      price: "130.05",
      range: { low: "Low", high: "High" },
      rationale: "The selected stocks have been carefully ...."
    },
    {
      symbol: "",
      mktLim: "Market",
      type: "Delivery",
      qty: "10",
      ltp: "130.05",
      price: "130.05",
      range: { low: "Low", high: "High" },
      rationale: "The selected stocks have been carefully ...."
    },
    {
      symbol: "",
      mktLim: "Market",
      type: "Delivery",
      qty: "10",
      ltp: "130.05",
      price: "130.05",
      range: { low: "Low", high: "High" },
      rationale: "The selected stocks have been carefully ...."
    },
    {
      symbol: "",
      mktLim: "Market",
      type: "Delivery",
      qty: "10",
      ltp: "130.05",
      price: "130.05",
      range: { low: "Low", high: "High" },
      rationale: "The selected stocks have been carefully ...."
    },
    {
      symbol: "",
      mktLim: "Market",
      type: "Delivery",
      qty: "10",
      ltp: "130.05",
      price: "130.05",
      range: { low: "Low", high: "High" },
      rationale: "The selected stocks have been carefully ...."
    },
    {
      symbol: "",
      mktLim: "Market",
      type: "Delivery",
      qty: "10",
      ltp: "130.05",
      price: "130.05",
      range: { low: "Low", high: "High" },
      rationale: "The selected stocks have been carefully ...."
    },
    {
      symbol: "",
      mktLim: "Market",
      type: "Delivery",
      qty: "10",
      ltp: "130.05",
      price: "130.05",
      range: { low: "Low", high: "High" },
      rationale: "The selected stocks have been carefully ...."
    }
  ];


  const handleAddBasketEntry = () => {
    setShowBasketEntry(true);
  };



  return (
    <div className="p-4">
      <button
        onClick={openDialog}
        className="mb-4 bg-black text-white px-4 py-2 rounded-lg hover:bg-gray-800 mr-4"
      >
        Open Derivatives Dialog
      </button>
      <button
        onClick={openReviewModal}
        className="mb-4 bg-black text-white px-4 py-2 rounded-lg hover:bg-gray-800"
      >
        Open Review Modal
      </button>

      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent className="p-0 border-0 bg-transparent shadow-none max-w-none" showCloseButton={false}>
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white w-full max-w-[1350px] h-[600px] md:h-[600px] flex flex-col relative rounded-lg">
              <button
                onClick={closeDialog}
                className="absolute top-2 right-2 p-2 hover:bg-gray-100 rounded-full z-10 "
              >
                <X className="h-5 w-5" />
              </button>
              {/* Header */}
              <div className="h-[80px] shadow-[0px_4px_4px_0px_#0000000A] p-4">
                <h1 className="text-[18px] font-bold">
                  Send Advice to Clearmind Client
                </h1>
                <div className="mt-2 space-x-4">
                  <button
                    className={`text-sm ${activeTab === "equity"
                        ? "text-blue-600 border-b-2 border-blue-600"
                        : "text-gray-600"
                      }`}
                    onClick={() => setActiveTab("equity")}
                  >
                    Equity
                  </button>
                  <button
                    className={`text-sm ${activeTab === "derivatives"
                        ? "text-blue-600 border-b-2 border-blue-600"
                        : "text-gray-600"
                      }`}
                    onClick={() => setActiveTab("derivatives")}
                  >
                    Derivatives
                  </button>
                </div>
              </div>

              {/* Main content */}
              <div className="w-full max-w-[1287px] flex-grow overflow-y-auto mx-auto mt-2 flex flex-col md:flex-row p-4">
                {/* Added conditional rendering for equity and derivatives content */}
                {activeTab === "equity" ? (
                  // Equity content
                  <div className="w-full overflow-x-auto">
                    <div className="min-w-[1000px]">
                      {/* Added Include Derivatives toggle */}
                      <div className="flex justify-end mb-4">
                        <div className="flex items-center">
                          <span className="mr-2">Include Derivatives</span>
                          <button
                            onClick={() => setIncludeDerivatives(!includeDerivatives)}
                            className={`w-12 h-6 flex items-center rounded-full p-1 ${includeDerivatives ? 'bg-green-500' : 'bg-gray-300'
                              }`}
                          >
                            <div
                              className={`bg-white w-4 h-4 rounded-full shadow-md transform transition-transform duration-300 ${includeDerivatives ? 'translate-x-6' : ''
                                }`}
                            ></div>
                          </button>
                        </div>
                      </div>
                      {/* Equity trades table */}
                      <table className="w-full border-collapse">
                        <thead>
                          <tr className="bg-gray-100">
                            <th className="p-2 text-left"><SquarePlus /></th>
                            <th className="p-2 text-left">B/S</th>
                            <th className="p-2 text-left">Symbol</th>
                            <th className="p-2 text-left w-32">Mkt/Lim</th>
                            <th className="p-2 text-left w-32">Type</th>
                            <th className="p-2 text-left">Qty</th>
                            <th className="p-2 text-left">LTP</th>
                            <th className="p-2 text-left">Price</th>
                            <th className="p-2 text-left">Range</th>
                            <th className="p-2 text-left">Rationale</th>
                          </tr>
                        </thead>
                        <tbody>
                          {equityTrades.map((trade, index) => (
                            <tr key={index} className="border-b">
                              <td className="p-2"><Trash2 size={18} /></td>
                              <td className="p-2">
                                <div className="w-8 h-8 rounded-full bg-green-100 text-green-600 flex items-center justify-center">
                                  B
                                </div>
                              </td>
                              <td className="p-2"><input type="text" className="border p-1 w-full" placeholder="Search" /></td>
                              <td className="p-2">
                                <select className="border p-1 w-full text-sm font-poppins">
                                  <option>{trade.mktLim}</option>
                                </select>
                              </td>
                              <td className="p-2">
                                <select className="border p-1 w-full font-poppins">
                                  <option>{trade.type}</option>
                                </select>
                              </td>
                              <td className="p-2">
                                <div className="flex items-center">
                                  <button className="border px-2">-</button>
                                  <input type="text" value={trade.qty} className="border w-12 text-center mx-1" />
                                  <button className="border px-2">+</button>
                                </div>
                              </td>
                              <td className="p-2">{trade.ltp}</td>
                              <td className="p-2"><input type="text" value={trade.price} className="border p-1 w-full" /></td>
                              <td className="p-2">
                                <input type="text" value={`${trade.range.low} - ${trade.range.high}`} className="border p-1 w-full" />
                              </td>
                              <td className="p-2"><input type="text" value={trade.rationale} className="border p-1 w-full" /></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  // Derivatives content (unchanged)
                  <>
                    {/* Left section */}
                    <div className="w-full md:w-[30%] pr-0 md:pr-4 mb-4 md:mb-0">
                      <p className="text-gray-500 font-poppins font-normal text-[14px] mb-2 opacity-75">
                        Please click on the below strategy to view trades.
                      </p>
                      <div className="flex flex-wrap md:flex-nowrap justify-between items-center mb-4">
                        <div className="flex gap-2 mb-2 md:mb-0">
                          <button className="px-3 py-1 bg-gray-100 rounded-lg text-xs">
                            BULL
                          </button>
                          <button className="px-3 py-1 bg-gray-100 rounded-lg text-xs">
                            BEAR
                          </button>
                          <button className="px-3 py-1 bg-gray-100 rounded-lg text-xs">
                            NEUTRAL
                          </button>
                        </div>
                        <div className="flex items-center gap-1">
                          <span className="text-xs">Expiry</span>
                          <ChevronDown size={16} />
                        </div>
                      </div>

                      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
                        {strategies.map((strategy, index) => (
                          <div
                            key={index}
                            className="bg-gray-100 w-full h-[94px] rounded-lg"
                          ></div>
                        ))}
                      </div>
{/* 
                      <button className="w-full py-3 bg-white border-2 border-black text-black mt-4 text-base font-semibold">
                        Import trades from Sensibull
                      </button> */}
                    </div>

                    {/* Right section */}
                    <div className="w-full md:w-[70%] pl-0 md:pl-4">
                      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4 gap-2">
                        <div className="flex items-center gap-2 w-full sm:w-auto">
                          <span className="text-sm whitespace-nowrap">Strategy Name</span>
                          <input
                            type="text"
                            value={strategyName}
                            onChange={(e) => setStrategyName(e.target.value)}
                            className="px-3 py-1 flex-grow sm:w-64 bg-gray-100 rounded-lg text-sm"
                          />
                        </div>
                        <span className="text-xs text-blue-600 cursor-pointer hover:underline whitespace-nowrap">
                          Clear All Trades
                        </span>
                      </div>

                      {/* Trades table */}
                      <div className="bg-white rounded-lg border border-gray-200 overflow-x-auto">
                        <div className="min-w-[800px]">
                          <table className="w-full text-sm">
                            <thead className="bg-gray-50">
                              <tr>
                                <th className="px-4 py-2 text-left">
                                  <SquarePlus />
                                </th>
                                <th className="px-4 py-2 text-left">B/S</th>
                                <th className="px-4 py-2 text-left">Symbol</th>
                                <th className="px-4 py-2 text-left">Expiry</th>
                                <th className="px-4 py-2 text-left">Strike</th>
                                <th className="px-4 py-2 text-left">CE/PE</th>
                                <th className="px-4 py-2 text-left">Lots</th>
                                <th className="px-4 py-2 text-left">Price</th>
                                <th className="px-4 py-2"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {trades.map((trade, index) => (
                                <tr
                                  key={index}
                                  className="border-t border-gray-200 font-poppins"
                                >
                                  <td className="px-4 py-2">
                                    <button className="text-black hover:text-red-500">
                                      <Trash2 size={22} />
                                    </button>
                                  </td>
                                  <td className={`px-4 py-2`}>
                                    <div
                                      className={`w-8 h-8 flex items-center font-semibold justify-center ${trade.type === "B"
                                          ? "text-green-600 bg-[#E8F6F3]"
                                          : "text-red-600 bg-[#FCECEC]"
                                        } `}
                                    >
                                      {trade.type}
                                    </div>
                                  </td>
                                  <td className="px-4 py-2">{trade.symbol}</td>
                                  <td className="px-4 py-2">{trade.expiry}</td>
                                  <td className="px-4 py-2">{trade.strike}</td>
                                  <td className="px-4 py-2">{trade.cepe}</td>
                                  <td className="px-4 py-2">{trade.lots}</td>
                                  <td className="px-4 py-2">{trade.price}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="mt-4">
                        <div className="flex flex-col md:flex-row items-start md:items-center justify-between bg-gray-50 p-4">
                          <div className="flex items-center gap-4 mb-2 md:mb-0">
                            <span className="text-gray-500">Multiplier</span>
                            <div className="flex items-center border border-gray-300 rounded">
                              <input
                                type="text"
                                className="w-8 text-center text-gray-700 outline-none px-2"
                                defaultValue="1"
                              />
                              <div className="border-l border-gray-300 pl-2">
                                <ChevronDown className="h-4 w-4 text-gray-500" />
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-col md:flex-row items-start md:items-center gap-2 md:gap-6">
                            <div>
                              <span className="text-gray-500">Price</span>
                              <span className="ml-2">Pay 55.65</span>
                            </div>
                            <div>
                              <span className="text-gray-500">Premium</span>
                              <span className="ml-2">Pay 1,391</span>
                            </div>
                            <button className="px-4 py-1 bg-black text-white text-sm mt-2 md:mt-0">
                              Save
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* Calendar Spreads */}
                      {/* <div className="mt-4 border rounded-lg overflow-hidden">
                        <div className="h-[200px] overflow-y-auto">
                          <div className="p-4 space-y-4">
                            {[...Array(10)].map((_, index) => (
                              <div key={index} className="bg-gray-50 p-4 rounded-lg">
                                <div className="flex justify-between items-center">
                                  <span className="font-medium">Calendar Spread {index + 1}</span>
                                  <span className="text-blue-600 text-sm cursor-pointer hover:underline">
                                    View Trades
                                  </span>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div> */}


                    </div>
                  </>
                )}
              </div>

              {/* basket entry */}
              {showBasketEntry && (
                <div className="mt-4 p-4 border border-gray-200 rounded-lg">
                  <h3 className="text-lg font-semibold mb-2">Basket Entry</h3>
                  <textarea
                    className="w-full h-32 p-2 border border-gray-300 rounded-lg"
                    placeholder="Enter your basket data here..."
                  ></textarea>
                  <div className="mt-2 flex justify-end">
                    <button
                      onClick={() => setShowBasketEntry(false)}
                      className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        // Handle basket entry submission here
                        setShowBasketEntry(false);
                      }}
                      className="ml-2 px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-600 transition-colors"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              )}



              {/* Footer */}
              <div className="h-[70px] shadow-[0px_-4px_4px_0px_#0000000A] flex items-center justify-end px-4 sticky bottom-0 bg-white">
                <button className="px-8 py-3 bg-black text-white rounded-sm text-sm font-medium">
                  Send Advice
                </button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>


      {/* Review Modal */}
      <Dialog open={isReviewOpen} onOpenChange={setIsReviewOpen}>
        <DialogContent className="max-w-[1200px] p-0 w-full h-full md:h-auto">
          <div className="relative bg-white rounded-lg overflow-y-auto max-h-screen">
            <div className="flex items-center justify-between p-4 border-b">
              <h2 className="text-xl font-semibold">Review Modal</h2>
            </div>

            <div className="border-b overflow-x-auto">
              <div className="flex gap-4 md:gap-8 px-4 md:px-6 py-3">
                <button className="text-gray-500 hover:text-gray-700 whitespace-nowrap">
                  All
                </button>
                <button className="text-gray-500 hover:text-gray-700 whitespace-nowrap">
                  Equity(8)
                </button>
                <button className="text-gray-900 border-b-2 border-gray-900 whitespace-nowrap">
                  Derivatives(3)
                </button>
              </div>
            </div>

            <div className="p-4 md:p-6 space-y-8">
              {reviewTrades.map((strategy, index) => (
                <div key={index} className="space-y-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                      <span className="text-gray-500">Strategy Name:</span>
                      <span className="ml-2 font-medium">
                        {strategy.strategyName}
                      </span>
                    </div>
                    <div>
                      <span className="text-gray-500">Rationale</span>
                      <span className="ml-2">{strategy.rationale}</span>
                    </div>
                  </div>

                  <div className="bg-white rounded-lg overflow-x-auto">
                    <table className="w-full text-sm">
                      <thead className="bg-gray-50">
                        <tr>
                          <th className="w-10"></th>
                          <th className="text-left p-3 font-poppins">B/S</th>
                          <th className="text-left p-3 font-poppins">Symbol</th>
                          <th className="text-left p-3 font-poppins">Expiry</th>
                          <th className="text-left p-3 font-poppins">Strike</th>
                          <th className="text-left p-3 font-poppins">CE/PE</th>
                          <th className="text-left p-3 font-poppins">Lots</th>
                          <th className="text-left p-3 font-poppins">Price</th>
                          <th className="w-16"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {strategy.trades.map((trade, tradeIndex) => (
                          <tr key={tradeIndex} className="border-t">
                            <td className="p-3">
                              <button className="text-gray-400 hover:text-gray-600">
                                <Trash2 className="h-4 w-4" />
                              </button>
                            </td>
                            <td
                              className={`p-3 ${trade.type === "B"
                                  ? "text-green-500"
                                  : "text-red-500"
                                }`}
                            >
                              {trade.type}
                            </td>
                            <td className="p-3 font-poppins">{trade.symbol}</td>
                            <td className="p-3 font-poppins">
                              <div className="flex items-center ">
                                {trade.expiry}
                                <ChevronDown className="h-4 w-4 ml-1" />
                              </div>
                            </td>
                            <td className="p-3 font-poppins">
                              <div className="flex items-center gap-2">
                                <button className="text-gray-400 font-poppins">-</button>
                                {trade.strike}
                                <button className="text-gray-400">+</button>
                              </div>
                            </td>
                            <td className="p-3 font-poppins">{trade.cepe}</td>
                            <td className="p-3">
                              <div className="flex items-center font-poppins">
                                {trade.lots}
                                <ChevronDown className="h-4 w-4 ml-1" />
                              </div>
                            </td>
                            <td className="p-3 font-poppins">{trade.price}</td>
                            <td className="p-3">
                              <div className="flex gap-2">
                                <AlertTriangle className="h-4 w-4 text-yellow-500" />
                                <AlertTriangle className="h-4 w-4 text-red-500" />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>

                    <div className="bg-gray-50 p-4 flex flex-col md:flex-row items-start md:items-center justify-between">
                      <div className="flex items-center gap-4 mb-2 md:mb-0">
                        <span className="text-gray-500">Multiplier</span>
                        <div className="flex items-center">
                          <span>1</span>
                          <ChevronDown className="h-4 w-4 ml-1" />
                        </div>
                      </div>
                      <div className="flex flex-col md:flex-row items-start md:items-center gap-2 md:gap-6">
                        <div>
                          <span className="text-gray-500">Price</span>
                          <span className="ml-2">Pay 55.65</span>
                        </div>
                        <div>
                          <span className="text-gray-500">Premium</span>
                          <span className="ml-2">Pay 1,391</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="border-t p-4 flex flex-col md:flex-row items-start md:items-center justify-between sticky bottom-0 bg-white">
              <div className="mb-2 md:mb-0">
                <span className="text-gray-500">Confirm Total Amount :</span>
                <span className="ml-2 text-lg font-medium">₹13,234</span>
              </div>
              <button className="px-6 py-2 bg-gray-900 text-white rounded-lg hover:bg-gray-800 w-full md:w-auto">
                Place Order
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Derivatives;
