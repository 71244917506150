import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { Info } from "lucide-react";

import Alpha100 from "../../assests/alpha-100.png";
import Meter from "../../assests/Icon/meter.svg";
import server from "../../utils/serverConfig";
import formatCurrency from "../../utils/formatCurrency";
import PlanGroupModal from "./PlanGroupModal";

const PlanCard = ({ plan, bespokePlans, planGroups }) => {
  const navigate = useNavigate();

  const handleViewStrategy = () => {
    navigate(`/admin/admin-plan/${plan._id}`);
  };

  return (
    <div className="relative font-poppins w-full rounded-t-none rounded-b-xl bg-white border-[1px] border-[#000000]/10 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] pb-2">
      <div className="flex">
        <div
          className="p-2 font-medium tracking-wide rounded-t-none rounded-b-full text-center text-xs uppercase w-full"
          style={{ background: "#dadce0" }}
        >
          {plan?.type}
        </div>
      </div>
      <div
        className="flex items-start gap-x-3 p-4 cursor-pointer"
        onClick={handleViewStrategy}
      >
        <div className="flex flex-shrink-0 overflow-hidden rounded-[4px]">
          <img
            src={
              plan?.image ? `${server.server.baseUrl}${plan.image}` : Alpha100
            }
            alt={plan?.name}
            className="object-cover w-[49.18px] h-[48px]"
          />
        </div>
        <div className="flex flex-col grow">
          <h2 className="text-lg font-semibold text-[#000000]">{plan?.name}</h2>
          <p className="mt-1 text-[10px] md:text-[13px] text-[#95989C] font-poppins text-left font-normal">
            {plan?.description.split(" ").slice(0, 10).join(" ")}
          </p>
        </div>
      </div>

      <div className="flex w-full border-t-[1px] border-b-[1px] border-[#000000]/10">
        <div className="flex flex-col justify-center items-center w-2/4 py-1.5  relative">
          <div className="absolute top-1/2 right-0 transform -translate-y-1/2 h-[80%] border-r border-[#000000]/10"></div>
          <div>
            <div className="text-xs text-left text-[#000000]/70 font-normal font-poppins capitalize">
              CAGR
            </div>
            <p className="text-[13px]  lg:text-[16px] text-[#16A085] font-poppins font-bold text-center">
              -
            </p>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center  w-2/4 py-1.5 relative">
          <div className="absolute top-1/2 right-0 transform -translate-y-1/2 h-[80%] border-r border-[#000000]/10"></div>{" "}
          <div>
            <div className="text-xs  text-left text-[#000000]/70 font-normal font-poppins">
              2Y Returns
            </div>
            <p className="text-[13px]   lg:text-[16px] text-[#16A085] font-poppins font-bold text-center">
              -
            </p>
          </div>
        </div>

        <div className="flex flex-col justify-center items-center space-y-1 w-2/4   py-1.5 lg:px-1 rounded-md">
          <div>
            <div className="text-xs   text-left text-[#000000]/70 font-normal font-poppins">
              Min. investment
            </div>
            <p className="text-[13px]   lg:text-[16px] text-[#000000] font-poppins font-medium">
              ₹{formatCurrency(plan.minInvestment)}
            </p>
          </div>
        </div>
      </div>

      <div className="flex w-full px-4 mt-4">
        <div className="flex flex-col text-base text-[#000000]/80 font-sans w-full">
          <div className="flex items-center">
            <Info size={16} />
            <span className="text-[13px] px-2 font-medium">
              Fees: ₹ {plan?.amount}
            </span>
          </div>
          <div className="flex items-center">
            <Info size={15} />
            <span className="text-[13px] px-2 font-medium">
              Duration: {plan?.duration} days
            </span>
          </div>
          <div className="flex items-center">
            <img src={Meter} width="14px" alt="Meter" />
            <span className="text-[13px] px-2 py-0 font-medium">
              Subject to market risk
            </span>
          </div>
        </div>
        <div className="flex flex-col min-w-[120px]">
          <Button
            className="px-3 py-1.5 mb-2 rounded-lg text-sm font-medium border-[1px] border-[#000000]/10"
            style={{ background: "#EEEEEE" }}
            onClick={handleViewStrategy}
          >
            View Details
          </Button>

          {plan?.type === "bespoke" && (
            <PlanGroupModal
              plan={plan}
              bespokePlans={bespokePlans}
              planGroups={planGroups}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
