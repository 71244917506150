import {
  ChevronDownIcon,
  ChevronUpIcon,
  XIcon,
  AlertTriangle,
  X,
} from "lucide-react";
import React, { useEffect, useState } from "react";
import CheckedIcon from "../../assests/checked.svg";
import FailureIcon from "../../assests/failure.svg";
import PartialSuccessIcon from "../../assests/partial_success.svg";
import Ddpi from "../../assests/DDPI.svg";
import { Link } from "react-router-dom";

import { useMediaQuery } from "../../hooks/use-media-query";
import { Dialog, DialogContent } from "../../components/ui/dialog";
import { Drawer, DrawerContent } from "../../components/ui/drawer";
import moment from "moment";

const RecommendationSuccessModal = ({
  setOpenSucessModal,
  orderPlacementResponse,
  setStockDetails,
  openSuccessModal,
  setSelectedRecommendation,
  userDetails,
  tradeType,
  setShowAfterPlaceOrderDdpiModal,
  failedSellAttempts,
  rejectedSellCount,
}) => {
  // Function to get the formatted date

  const [orderResponse, setOrderResponse] = useState(orderPlacementResponse);

  useEffect(() => {
    setOrderResponse(orderPlacementResponse);
  }, []);

  useEffect(() => {
    console.log("Current tradeType in RecommendationSuccessModal:", tradeType);
  }, [tradeType]);

  const [showStocksDetails, setShowStocksDetails] = useState(false);

  const getFormattedDate = () => {
    const date = new Date();
    return moment(date).format("Do MMM YYYY");
  };

  const toggleStocksDetails = () => {
    setShowStocksDetails(!showStocksDetails);
  };

  const successCount = orderResponse?.filter(
    (item) =>
      item?.orderStatus === "Complete" ||
      item?.orderStatus === "complete" ||
      item?.orderStatus === "COMPLETE" ||
      item?.orderStatus === "Placed" ||
      item?.orderStatus === "PLACED" ||
      item?.orderStatus === "Executed" ||
      item?.orderStatus === "Ordered" ||
      item?.orderStatus === "open" ||
      item?.orderStatus === "OPEN" ||
      item?.orderStatus === "Traded" ||
      item?.orderStatus === "TRADED" ||
      item?.orderStatus === "Transit" ||
      item?.orderStatus === "TRANSIT"
  ).length;

  const failureCount = orderResponse?.filter(
    (item) =>
      item?.orderStatus === "Rejected" ||
      item?.orderStatus === "rejected" ||
      item?.orderStatus === "Rejected" ||
      item?.orderStatus === "cancelled" ||
      item?.orderStatus === "CANCELLED" ||
      item?.orderStatus === "Cancelled"
  ).length;

  const totalCount = orderResponse?.length;

  const successPercentage = (successCount / totalCount) * 100;
  const failurePercentage = (failureCount / totalCount) * 100;
  const partialFailurePercentage = 100 - successPercentage;

  const isMixed =
    orderResponse?.some((item) => item.transactionType === "BUY") &&
    orderResponse?.some((item) => item.transactionType === "SELL");

  const allSell = orderResponse?.every(
    (item) => item.transactionType === "SELL"
  );

  const allBuy = orderResponse?.every((item) => item.transactionType === "BUY");

  const currentBroker = userDetails?.user_broker;
  // If currentBroker is undefined, return null immediately
  if (!currentBroker) {
    console.log("Current broker is undefined. Returning null.");
    return null;
  }

  // Directly fetch the rejected count from localStorage

  const handleClose = () => {
    setOpenSucessModal(false);
    // Get the current broker from userDetails
    const currentBroker = userDetails?.user_broker;
    // If currentBroker is undefined, return null immediately
    if (!currentBroker) {
      console.log("Current broker is undefined. Returning null.");
      return null;
    }

    if (
      userDetails?.user_broker === "Angel One" &&
      userDetails?.ddpi_enabled === true
    ) {
      return null;
    } else if (
      (allSell === true &&
        failureCount === 0 &&
        userDetails?.ddpi_enabled === false &&
        userDetails?.is_authorized_for_sell === true &&
        allBuy !== true) ||
      (isMixed === true &&
        failureCount === 0 &&
        userDetails?.ddpi_enabled === false &&
        userDetails?.is_authorized_for_sell === true &&
        allBuy !== true)
    ) {
      //  && rejectedSellCount > 1
      console.log(
        "Setting showAfterPlaceOrderDdpiModal to true for sell or mixed trades"
      );
      setShowAfterPlaceOrderDdpiModal(true);
    } else {
      console.log(
        "Setting showAfterPlaceOrderDdpiModal to false for buy-only trades & for failed order"
      );
      return null;
    }
  };

  //  console.log("Trade Type in success Modal",tradeType);
  // if (isDesktop) {
  return (
    <>
      <Dialog open={openSuccessModal} onOpenChange={setOpenSucessModal}>
        <DialogContent
          className=" max-w-[780px] w-full p-0 max-h-[90vh] animate-modal"
          showCloseButton={false}
        >
          <div className="flex flex-col w-full pt-4  rounded-md">
            <button
              onClick={handleClose}
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              aria-label="Close"
            >
              <X className="w-6 h-6" />
            </button>
            {/* Complete Success  */}
            {successCount === totalCount && orderResponse?.length !== 0 && (
              <div className="flex space-x-2 lg:mt-[16px]  lg:space-x-4 px-4 lg:px-12 lg:py-3">
                <img
                  src={CheckedIcon}
                  alt="Check Icon"
                  className="w-[45px] lg:w-[50px] rounded-full mr-2 -mt-4"
                />

                <div className="md:mt-0 flex flex-col space-y-[8px]">
                  <h3 className="text-[#000000] text-[13px] lg:text-[22px] font-semibold font-poppins leading-[18px] lg:leading-[24px]">
                    All Orders Placed Successfully
                  </h3>
                  <p className=" text-[#000000] text-[11px] lg:text-[16px] font-light font-poppins leading-[18px] lg:leading-[24px]">
                    Please review the
                    {window.location.pathname === "/order-details" ? (
                      <span
                        className="  text-blue-500 px-[4px] cursor-pointer"
                        onClick={() => {
                          setSelectedRecommendation("orders-placed");
                          // setOpenSucessModal(false);
                          handleClose();
                        }}
                      >
                        Order details
                      </span>
                    ) : (
                      <Link
                        className="  text-blue-500 px-[4px]"
                        to="/order-details"
                      >
                        Order details
                      </Link>
                    )}
                    below.
                  </p>
                </div>
              </div>
            )}

            {/* Complete Failure  */}

            {failureCount === totalCount && (
              <div className="mt-2 flex  space-x-2 lg:mt-[16px]  lg:space-x-4 px-4 lg:px-12">
                <img
                  src={FailureIcon}
                  alt="Cross Icon "
                  className="w-[60px] lg:w-[90px] rounded-full mr-2 -mt-4"
                />

                <div className="md:mt-2 md:max-w-[500px] flex flex-col space-y-[8px]">
                  <h3 className="text-[#000000] text-[13px] lg:text-[22px] font-semibold font-poppins leading-[18px] lg:leading-[24px]">
                    No Orders Placed
                  </h3>
                  <p className=" text-[#000000] text-[11px] lg:text-[16px] font-light font-poppins leading-[18px] lg:leading-[24px]">
                    Please review the{" "}
                    {window.location.pathname === "/order-details" ? (
                      <span
                        className="  text-blue-500 px-[4px] cursor-pointer"
                        onClick={() => {
                          setSelectedRecommendation("orders-placed");
                          // setOpenSucessModal(false);
                          handleClose();
                        }}
                      >
                        Order details
                      </span>
                    ) : (
                      <Link
                        className="  text-blue-500 px-[4px]"
                        to="/order-details"
                      >
                        Order details
                      </Link>
                    )}{" "}
                    below and contact your advisor for next steps.{" "}
                  </p>
                </div>
              </div>
            )}

            {/* Partial Success  */}

            {successCount >= 1 && successCount !== totalCount && (
              <div className="flex space-x-2 lg:mt-[16px]  lg:space-x-4 px-4 lg:px-12 lg:py-3">
                <img
                  src={PartialSuccessIcon}
                  alt="Cross Icon"
                  className="w-[45px] lg:w-[50px] rounded-full mr-2 -mt-4"
                />

                <div className="md:mt-2 flex flex-col space-y-[8px]">
                  <h3 className="text-[#000000] text-[13px] lg:text-[22px] font-semibold font-poppins leading-[18px] lg:leading-[24px]">
                    Some Orders Placed Successfully
                  </h3>
                  <p className=" text-[#000000] text-[11px] lg:text-[16px] font-light font-poppins leading-[18px] lg:leading-[24px]">
                    Please review the{" "}
                    {window.location.pathname === "/order-details" ? (
                      <span
                        className="  text-blue-500 px-[4px] cursor-pointer"
                        onClick={() => {
                          setSelectedRecommendation("orders-placed");
                          // setOpenSucessModal(false);
                          handleClose();
                        }}
                      >
                        Order details
                      </span>
                    ) : (
                      <Link
                        className="  text-blue-500 px-[4px]"
                        to="/order-details"
                      >
                        Order details
                      </Link>
                    )}{" "}
                    below for more information.{" "}
                  </p>
                </div>
              </div>
            )}

            <div className="mt-2 grid grid-cols-3 lg:mt-[16px] lg:h-[80px] border-t-[1px] border-r-[#000000]/10 bg-[#F5F5F5]  justify-between rounded-md">
              <div className="flex flex-col px-2 justify-center rounded-bl-md border-r-[1px] border-r-[#000000]/10 lg:px-6 py-2 ">
                <h4 className="text-[10px] lg:text-[14px] text-[#000000]/70 font-normal font-poppins ">
                  Placed On
                </h4>
                <p className="text-[12px] lg:text-[18px] text-[#000000]/70 font-medium  font-poppins  capitalize">
                  {getFormattedDate()}
                </p>
              </div>
              <div className="flex flex-col px-2 justify-center border-r-[1px] border-r-[#000000]/10 lg:px-6 py-2 ">
                <h4 className="text-[10px] lg:text-[14px] text-[#000000]/70 font-normal font-poppins ">
                  Status
                </h4>
                <p className="text-[12px] lg:text-[18px] text-[#000000]/70 font-medium  font-poppins  capitalize">
                  Placed
                </p>
              </div>
              <div className="flex flex-col px-2 space-y-[2px] lg:space-y-[8px] justify-center lg:px-6 py-2 ">
                <h4 className="text-[10px] lg:text-[14px] text-[#000000]/70 font-normal font-poppins ">
                  {successCount} of {totalCount} Executed
                </h4>
                <div className="flex items-center space-x-2">
                  <div className="flex w-full bg-[#D9D9D9] h-2 lg:h-3 rounded-[3px] ">
                    {successCount === totalCount && (
                      <div
                        className="bg-[#16A085] h-2 lg:h-3 rounded-l-[3px]"
                        style={{ width: `${successPercentage}%` }}
                      ></div>
                    )}

                    {failureCount === totalCount && (
                      <div
                        className="bg-[#FF0000] h-2 lg:h-3 rounded-r-[3px]"
                        style={{ width: `${failurePercentage}%` }}
                      ></div>
                    )}
                    {successCount >= 1 && successCount !== totalCount && (
                      <>
                        <div
                          className="bg-[#16A085] h-2 lg:h-3 rounded-l-[3px]"
                          style={{ width: `${successPercentage}%` }}
                        ></div>
                        <div
                          className="bg-[#FF0000] h-2 lg:h-3 rounded-r-[3px]"
                          style={{ width: `${partialFailurePercentage}%` }}
                        ></div>
                      </>
                    )}
                  </div>
                  {showStocksDetails === true ? (
                    <ChevronUpIcon
                      onClick={toggleStocksDetails}
                      className="text-[#000000]/80 h-5 w-5 lg:h-6 lg:w-6 cursor-pointer"
                    />
                  ) : (
                    <ChevronDownIcon
                      onClick={toggleStocksDetails}
                      className="text-[#000000]/80 h-5 w-5 lg:h-6 lg:w-6 cursor-pointer"
                    />
                  )}
                </div>
              </div>
            </div>

            {showStocksDetails && (
              <div className=" w-full border-t-[1px] border-[#000000]/10 max-h-[250px] lg:h-[380px] mb-2 lg:mb-4 overflow-auto custom-scroll">
                <table className={`w-full `}>
                  <thead className="bg-[#ffffff] z-20 sticky top-0">
                    <tr className="border-b-[1px]   border-[#000000]/10">
                      <th className="text-[12px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium text-left px-3 py-3 lg:py-3 lg:px-5">
                        Stocks
                      </th>

                      <th className="text-[12px] min-w-[90px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                        Order
                      </th>
                      <th className="text-[12px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                        Quantity
                      </th>
                      <th className="text-[12px] min-w-[90px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                        Order Type
                      </th>
                      <th className="text-[12px] min-w-[90px] lg:text-[13px] text-[#000000]/80 font-poppins font-medium px-2 py-3 lg:py-3 lg:px-5 ">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderResponse?.map((ele, i) => {
                      return (
                        <tr
                          key={i}
                          className={
                            ele?.orderStatus === "Placed" ||
                            ele?.orderStatus === "complete" ||
                            ele?.orderStatus === "COMPLETE" ||
                            ele?.orderStatus === "Executed" ||
                            ele?.orderStatus === "open" ||
                            ele?.orderStatus === "OPEN" ||
                            ele?.orderStatus === "Ordered" ||
                            ele?.orderStatus === "Traded" ||
                            ele?.orderStatus === "TRADED" ||
                            ele?.orderStatus === "TRANSIT" ||
                            ele?.orderStatus === "Transit" ||
                            ele?.orderStatus === "Pending" ||
                            ele?.orderStatus === "REQUESTED" ||
                            ele?.orderStatus === "Requested"
                              ? `border-b-[1px]   border-[#000000]/10 last-of-type:border-none`
                              : `border-b-[1px]   border-b-[#000000]/10  bg-[#FF0000]/5 last-of-type:border-none `
                          }
                        >
                          <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium text-left py-3 px-5 ">
                            {ele?.symbol}
                          </td>

                          <td
                            className={`text-[15px]  text-[#000000]/80 capitalize font-poppins font-medium text-center py-3 px-5
                          ${
                            ele?.transactionType?.toLowerCase() === "buy"
                              ? "text-[#16A085]"
                              : ele?.transactionType?.toLowerCase() === "sell"
                              ? "text-[#E43D3D] "
                              : "text-[#000000]/80"
                          }
                          `}
                          >
                            {ele?.transactionType?.toLowerCase()}
                          </td>

                          <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium text-center py-3 px-5 ">
                            {ele?.quantity}
                          </td>
                          <td className="text-[15px]  text-[#000000]/80 capitalize font-poppins font-medium text-center py-3 px-5 ">
                            {ele?.orderType}
                          </td>
                          <td className="text-[15px]  text-[#000000]/80 font-poppins font-medium text-center py-3 px-5 ">
                            {!ele?.message_aq ||
                            ele?.message_aq.toLowerCase() === "no message" ||
                            ele?.message_aq === "--" ? (
                              <span className="capitalize">
                                {ele?.orderStatus}
                              </span>
                            ) : (
                              <span className="capitalize">
                                {ele?.message_aq.toLowerCase()}
                              </span>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RecommendationSuccessModal;
