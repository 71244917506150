import React, { useState, useEffect } from "react";
import StrategyCard from "./StrategyCard";
import { Toaster } from "react-hot-toast";
import axios from "axios";
import { GitForkIcon } from "lucide-react";
import server from "../../utils/serverConfig";
import { motion, AnimatePresence } from "framer-motion";

const style = {
  selected:
    "flex items-center text-[18px]  font-sans text-black font-bold lg:leading-[42px] cursor-pointer",
  unselected:
    "flex items-center text-[18px]  font-sans font-medium text-[#000000]/40 lg:leading-[42px] cursor-pointer",
};

const StrategyList = ({ advisorName }) => {
  const [allModalPortfolioList, setAllModalPortdfolioList] = useState([]);

  const getAllModalPortfolioList = () => {
    if (advisorName) {
      axios
        .get(
          `${server.server.baseUrl}api/model-portfolio/portfolios/${advisorName}`
        )
        .then((res) => {
          setAllModalPortdfolioList(res.data);
        })
        .catch((err) => console.log(err));
    }
  };
  useEffect(() => {
    getAllModalPortfolioList();
  }, [advisorName]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="min-h-screen w-full items-center justify-center bg-[#F9F9F9] ">
      <Toaster position="top-center" reverseOrder={true} />
      <div className="py-4 px-4 flex flex-row  justify-between border-b border-[#000000]/20 h-auto lg:h-[80px] font-poppins">
        <div className="flex flex-row space-x-6 lg:space-x-8 py-2 ">
          <div
            className={`
              ${style.selected} flex items-center justify-center text-[18px] md:text-[22px] lg:px-10`}
          >
            Model Portfolios Rebalancing
          </div>
        </div>
      </div>
      <div className="flex-grow overflow-auto pb-4  md:px-4 lg:px-[50px]">
        {allModalPortfolioList.length !== 0 ? (
          <AnimatePresence mode="wait">
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
              className="grid gap-4 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 pl-1  lg:pt-6 pb-10"
            >
              {allModalPortfolioList.length !== 0 &&
                allModalPortfolioList.map((ele, i) => {
                  return (
                    <StrategyCard
                      fileName={ele.model_name}
                      key={i}
                      overView={ele.overView}
                      ele={ele}
                    />
                  );
                })}
            </motion.div>
          </AnimatePresence>
        ) : (
          <div className="flex flex-row justify-center min-h-screen">
            {isLoading ? (
              <div className="py-24 text-3xl font-semibold font-sans flex items-center justify-center  h-[calc(100vh-60px)]">
                <svg
                  className="h-10 w-10 text-[#000000] animate-spin"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center space-y-6 w-full lg:h-[calc(100vh-80px)] relative  bg-[#f9f9f9]">
                <div className="flex flex-row items-center justify-center w-[120px] h-[120px] lg:w-[150px] lg:h-[150px] rounded-full bg-gray-200/70 ">
                  <GitForkIcon size={42} className="w-[60px] lg:w-[80px]" />
                </div>
                <div className="flex flex-col space-y-3 lg:space-y-5 items-center px-6 lg:w-[800px]">
                  <div className="text-balck text-center text-[22px] lg:text-[28px] leading-[26px] mt-4 font-sans font-semibold">
                    View and manage your existing model portfolio here. Contact your admin for more details.
                  </div>
                  <div className="text-center text-[14px] lg:text-[18px] text-[#000000]/60 lg:leading-[30px] font-normal font-poppins px-[10px] lg:px-[60px]">
                    Create a personalized model portfolio based strategy from
                    scratch to meet your goals. Create model portfolio under the
                    Plan tab.
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default StrategyList;
