import React from 'react'

const Insights = () => {
  const tableData = [
    {
      source: "Support",
      pendingLastMonth: 0,
      received: 0,
      resolved: 0,
      totalPending: 0,
      pendingThreeMonths: 0,
      avgResolutionTime: 0
    },
    {
      source: "SEBI Scored",
      pendingLastMonth: 0,
      received: 0,
      resolved: 0,
      totalPending: 0,
      pendingThreeMonths: 0,
      avgResolutionTime: 0
    },
    {
      source: "Other Sources",
      pendingLastMonth: 0,
      received: 0,
      resolved: 0,
      totalPending: 0,
      pendingThreeMonths: 0,
      avgResolutionTime: 0
    },
    {
      source: "Grand Total",
      pendingLastMonth: 0,
      received: 0,
      resolved: 0,
      totalPending: 0,
      pendingThreeMonths: 0,
      avgResolutionTime: 0
    }
  ]

  return (
    <div className=" bg-[#EEF9F9] py-16">
      <div className="max-w-7xl mx-auto px-4">
        {/* Header */}
        <div className="text-center mb-12">
          <h3 className="text-gray-500  mb-2 font-semibold font-montserrat">COMPLAINT BOARD</h3>
          <h2 className="text-4xl font-bold text-gray-900">Latest Complaint Insights</h2>
        </div>

        {/* Table */}
        <div className="bg-white rounded-lg overflow-hidden shadow-sm">
          <div className="overflow-x-auto">
            <table className="w-full">
              <thead>
                <tr className="bg-[#4E5054] text-white">
                  <th className="px-6 py-4 text-left font-poppins text-xs font-normal">Received From</th>
                  <th className="px-6 py-4 text-center font-poppins text-xs font-normal">Pending at the end of last month</th>
                  <th className="px-6 py-4 text-center font-poppins text-xs font-normal">Received</th>
                  <th className="px-6 py-4 text-center font-poppins text-xs font-normal">Resolved</th>
                  <th className="px-6 py-4 text-center font-poppins text-xs font-normal">Total Pending</th>
                  <th className="px-6 py-4 text-center font-poppins text-xs font-normal">Pending Complaints &gt; 3 Months</th>
                  <th className="px-6 py-4 text-center font-poppins text-xs font-normal">Average Resolution Time</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((row, index) => (
                  <tr 
                    key={row.source}
                    className={`
                      border-b
                      ${index === tableData.length - 1 ? 'font-medium' : ''}
                    `}
                  >
                    <td className="px-6 py-4 text-left font-poppins text-sm font-medium">{row.source}</td>
                    <td className="px-6 py-4 text-center font-poppins text-sm font-medium">{row.pendingLastMonth}</td>
                    <td className="px-6 py-4 text-center font-poppins text-sm font-medium">{row.received}</td>
                    <td className="px-6 py-4 text-center font-poppins text-sm font-medium">{row.resolved}</td>
                    <td className="px-6 py-4 text-center font-poppins text-sm font-medium">{row.totalPending}</td>
                    <td className="px-6 py-4 text-center font-poppins text-sm font-medium">{row.pendingThreeMonths}</td>
                    <td className="px-6 py-4 text-center font-poppins text-sm font-medium">{row.avgResolutionTime}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Last Updated */}
        <div className="text-right mt-4 text-xs font-medium font-poppins text-[#4E5054] ">
          Last Updated on 17th Dec 2024
        </div>
      </div>
    </div>
  )
}

export default Insights

