import React, { useState, useEffect } from "react";
import moment from "moment";
import { Toaster } from "react-hot-toast";
import axios from "axios";
import { useAuthState } from "react-firebase-hooks/auth";
import {
  ChevronRightIcon,
  ChevronUp,
  ChevronDown,
  ArrowDownWideNarrow,
  ChevronLeft,
} from "lucide-react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Alpha100 from "../../assests/alpha-100.png";
import PigIcon from "../../assests/pig_icon.svg";
import { auth } from "../../firebase";
import server from "../../utils/serverConfig";

import formatCurrency from "../../utils/formatCurrency";
import RebalanceTimeLineModal from "../../AdminDashboard/StrategyDetailsPageSection/RebalanceTimeLineModal";
import useWebSocketCurrentPrice from "../../FunctionCall/useWebSocketCurrentPrice";
import { TerminateStrategyModal } from "./TerminateStrategyModal";
import { ModifyInvestmentModal } from "./ModifyInvestmentModal";
import LoadingSpinner from "../../components/LoadingSpinner";

const style = {
  analyticsHead:
    " text-[12px] lg:text-sm text-[#000000]/80 leading-[22px] font-poppins",
  analyticsValue:
    "text-black text-[18px] lg:text-[22px] font-semibold font-poppins",
  selected:
    "flex items-center text-[16px] md:text-[18px]  font-sans text-black font-bold lg:leading-[42px] border-b-[3px] border-black cursor-pointer",
  unselected:
    "flex items-center text-[16px] md:text-[18px]  font-sans font-medium text-[#000000]/40 lg:leading-[42px] cursor-pointer",
  firstHeading: "text-sm text-[#00000099] text-left font-medium",
  inputBox:
    "w-full px-6 py-2.5  bg-white text-[18px]  peer text-gray-900 placeholder-transparent  font-medium rounded-md mt-3 ring-[1px] hover:ring-[2px] ring-gray-200    hover:ring-[#D9D9D9] focus:outline-none focus:ring-2 focus:ring-[#D9D9D9]  transition ease-in duration-200  ",

  labelFloat:
    " absolute px-1.5 top-0.5 left-3.5 text-[#808080] bg-white text-[16px] peer-placeholder-shown:text-sm peer-placeholder-shown:font-medium peer-placeholder-shown:top-6 peer-placeholder-shown:text-[#808080] transition-all peer-focus:top-0.5 peer-focus:text-[#00000099] peer-focus:text-xs",

  selectDiv:
    "flex items-center px-2 py-2 hover:first-of-type:rounded-t-lg hover:last-of-type:rounded-b-lg first-of-type:rounded-t-lg last-of-type:rounded-b-lg md-3 text-gray-900  hover:bg-[#D9D9D9] hover:text-gray-100  transition ease-in duration-200 cursor-pointer",
  inputStartDiv: "relative w-full  ",
};

const StrategyDetailsWithPortfolioData = ({
  // totalInvested = 1000000,
  // totalCurrent = 2000000,
  // totalNetReturns = 100,
  // netReturnsPercentage = 60,
}) => {
  const { fileName } = useParams();
  const [user] = useAuthState(auth);
  const userEmail = user && user.email;

  const [userDetails, setUserDetails] = useState();

  const getUserDetails = () => {
    axios
      .get(`${server.server.baseUrl}api/user/getUser/${userEmail}`)
      .then((res) => {
        // console.log("User Details API Response:", res.data);
        setUserDetails(res.data.User);
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getUserDetails();
  }, [userEmail, server.server.baseUrl]);

  const [strategyDetails, setStrategyDetails] = useState(null);
  const [latestRebalance, setLatestRebalance] = useState(null);
  const getStrategyDetails = () => {
    if (fileName) {
      axios
        .get(
          `${server.server.baseUrl
          }api/model-portfolio/portfolios/strategy/${fileName?.replaceAll(
            /_/g,
            " "
          )}`
        )
        .then((res) => {
          const portfolioData = res.data[0].originalData;
          setStrategyDetails(portfolioData);
          if (
            portfolioData &&
            portfolioData.model &&
            portfolioData.model.rebalanceHistory.length > 0
          ) {
            const latest = portfolioData.model.rebalanceHistory.sort(
              (a, b) => new Date(b.rebalanceDate) - new Date(a.rebalanceDate)
            )[0];
            setLatestRebalance(latest);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    getStrategyDetails();
  }, [fileName]);

  const subscribed =
    strategyDetails?.subscribed_by?.filter((email) => email === userEmail)
      .length > 0;

  const [showRebalanceTimelineModal, setShowRebalanceTimelineModal] =
    useState(false);
  const closeRebalanceTimelineModal = () => {
    setShowRebalanceTimelineModal(false);
  };

  const [terminateModal, setTerminateModal] = useState(false);

  const [sortOrder, setSortOrder] = useState("asc");
  const [expandedItems, setExpandedItems] = useState({});
  const toggleSort = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const toggleExpansion = (index) => {
    setExpandedItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const [modifyInvestmentModal, setModifyInvestmentModal] = useState(false);
  const [subscriptionAmount, setSubscrptionAmount] = useState();
  const getSubscriptionData = () => {
    let config = {
      method: "get",
      url: `${server.server.baseUrl
        }api/model-portfolio-db-update/subscription-raw-amount?email=${encodeURIComponent(
          userEmail
        )}&modelName=${encodeURIComponent(strategyDetails?.model_name)}`,
    };

    axios
      .request(config)
      .then((response) => {
        setSubscrptionAmount(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (strategyDetails !== null) {
      getSubscriptionData();
    }
  }, [strategyDetails]);

  const sortedRebalances = subscriptionAmount?.subscription_amount_raw?.sort(
    (a, b) => new Date(b.dateTime) - new Date(a.dateTime)
  );

  const net_portfolio_updated = subscriptionAmount?.user_net_pf_model?.sort(
    (a, b) => new Date(b.execDate) - new Date(a.execDate)
  )[0];

  const { getLTPForSymbol } = useWebSocketCurrentPrice(
    net_portfolio_updated?.order_results
  );

  const totalUpdatedQty = net_portfolio_updated?.order_results?.reduce(
    (total, ele) => total + (ele?.quantity || 0),
    0
  );

  const [portfolioLoading, setPortfolioLoading] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setPortfolioLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleGoBack = () => {
    navigate(-1); // This navigates to the previous path
  };

  const totalInvested = net_portfolio_updated?.order_results?.reduce((total, stock) => {
    return total + (parseFloat(stock.averagePrice) * stock.quantity);
  }, 0) || 0;
  const totalCurrent = net_portfolio_updated?.order_results?.reduce((total, stock) => {
    return total + (parseFloat(getLTPForSymbol(stock?.symbol)) * stock.quantity);

  }, 0) || 0;

  const totalNetReturns = net_portfolio_updated?.order_results?.reduce((total, stock) => {
    return total + ((parseFloat(getLTPForSymbol(stock?.symbol)) - parseFloat(stock.averagePrice)) * stock.quantity);
  }, 0) || 0;


  const netReturnsPercentage = net_portfolio_updated?.order_results?.reduce((total, stock) => {
    return total + ((parseFloat(getLTPForSymbol(stock?.symbol)) - parseFloat(stock.averagePrice) * stock.quantity) / parseFloat(stock.averagePrice) * stock.quantity);
  }, 0) || 0;

  const tableData = net_portfolio_updated?.order_results?.map(stock => ({
    symbol: stock.symbol,
    currentPrice: parseFloat(getLTPForSymbol(stock?.symbol)).toFixed(2),
    avgBuyPrice: parseFloat(stock?.averagePrice).toFixed(2),
    returns: parseFloat(((getLTPForSymbol(stock?.symbol) - stock?.averagePrice) / stock?.averagePrice) * 100).toFixed(2),
    weights: parseFloat((stock?.quantity / totalUpdatedQty) * 100).toFixed(2),
    shares: stock?.quantity
  })) || [];


  return (
    <div className="flex flex-col pb-2 max-h-[calc(100vh-60px)]  lg:min-h-screen bg-white">
      <Toaster position="top-center" reverseOrder={true} />
      <div className="flex-grow overflow-y-auto">
        <div className="w-full flex flex-col p-4 lg:pt-[20px] lg:px-[60px]">
          <div className="sm:hidden">
            <ChevronLeft
              className="w-5 h-5 mb-3 text-black font cursor-pointer"
              onClick={handleGoBack}
            />
          </div>
          <div className="hidden sm:block">
            {/* <Breadcrumb breadcrumbsList={coinBreadcrumbsList} /> */}
            <div>
              <ChevronLeft
                className="w-6 h-6  text-black font cursor-pointer"
                onClick={handleGoBack}
              />
            </div>
          </div>

          <div className="flex flex-col lg:flex-row justify-between items-start lg:items-center mb-2 md:mb-2 lg:mb-2 ">
            <div className="flex items-center space-x-4 mb-4 lg:mb-0">
              {strategyDetails?.image ? (
                <div className="flex items-center justify-center">
                  <img
                    src={`${server.server.baseUrl}${strategyDetails.image}`}
                    alt={strategyDetails?.modal_name}
                    className="h-[50px] w-[50px] rounded-[4px]"
                  />
                </div>
              ) : (
                <div className="flex items-center justify-center">
                  <img
                    src={Alpha100}
                    alt="Alpha 100 Strategy"
                    className="h-[50px] w-[50px] rounded-[4px]"
                  />
                </div>
              )}
              <div className="flex flex-col ">
                <div className="flex items-center space-x-4">
                  <h3 className="text-[18px] font-sans font-bold capitalize text-[#000000]">
                    {fileName?.replaceAll(/_/g, " ")}
                  </h3>
                </div>
                <p className="text-[14px] font-medium font-poppins text-[#000000]/60">
                  Invested since 07th Sept, 2024
                </p>
              </div>
            </div>
            <div className="hidden lg:block ">
              <div className="flex flex-col space-y-6 py-6">
                {subscribed === true && (
                  <button
                    className="w-full lg:w-auto text-base font-medium bg-black text-white rounded-md py-2 px-4"
                    onClick={() => setModifyInvestmentModal(true)}
                  >
                    Modify Investment
                  </button>
                )}
              </div>
            </div>
          </div>

          <div className="bg-white py-4 mb-4 shadow-md rounded-[10px] border-[1px] border-[#000000]/5 ">
            <div className=" grid grid-cols-2 gap-4 lg:grid-cols-4  lg:py-0  divide-x-[1px] divide-[#000000]/10">
              <div className="flex flex-col lg:text-left space-y-0  md:space-y-2 lg:space-y-2 justify-center items-center   w-full px-4   lg:pl-[60px] ">
                <div className="items-start  md:space-y-2 ">
                  <div className={style.analyticsHead}>Total Invested</div>
                  <div
                    className={`${style.analyticsValue}  text-xs sm:text-sm md:text-[18px]  lg:text-[22px]`}
                  >
                    {totalInvested
                      ? `₹${formatCurrency(parseInt(totalInvested.toFixed(2)))}`
                      : "-"}
                  </div>
                </div>
              </div>
              <div className="flex flex-col  space-y-0  md:space-y-2 lg:space-y-2 justify-center items-center  w-full px-4  lg:pl-[60px] ">
                <div className="items-start  md:space-y-2 ">
                  <div className={style.analyticsHead}>Total Current</div>
                  <div
                    className={`${style.analyticsValue} text-xs sm:text-sm md:text-[18px]  lg:text-[22px]`}
                  >
                    {totalCurrent
                      ? `₹${formatCurrency(parseInt(totalCurrent.toFixed(2)))}`
                      : "-"}
                  </div>
                </div>
              </div>

              <div className="flex flex-col  space-y-0  md:space-y-2 lg:space-y-2  justify-center items-center   w-full px-4   lg:pl-[60px]">
                <div>
                  <div className="items-start  md:space-y-2 ">
                    <div className="ml-4 md:ml-0 lg:ml-0 md:space-y-2">
                      <div className={style.analyticsHead}>
                        {/* Absolute Returns */}
                        Current Returns
                      </div>
                      {totalNetReturns > 0 ? (
                        <div className={`flex items-center`}>
                          <div className="text-[#000000] text-xs sm:text-sm md:text-[18px]  lg:text-[22px]  font-semibold font-poppins">
                            {formatCurrency(Math.abs(totalNetReturns.toFixed(2)))}%
                          </div>
                          <sub className="text-[10px]  text-[#16A085] font-semibold font-poppins mt-1 px-[4px] py-[2px] rounded-md ml-1">
                            +{Math.abs(netReturnsPercentage.toFixed(2))}%
                          </sub>
                        </div>
                      ) : totalNetReturns < 0 ? (
                        <div className={`flex items-center`}>
                          <div className="text-[#000000] text-xs sm:text-sm md:text-[18px] lg:text-[22px] font-semibold font-poppins">
                            {formatCurrency(Math.abs(totalNetReturns.toFixed(2)))}%
                          </div>
                          <sub className="text-[12px]  text-[#E43D3D] font-semibold font-poppins mt-1 px-[4px] py-[2px]  rounded-md ml-1">
                            -{Math.abs(netReturnsPercentage.toFixed(2))}%
                          </sub>
                        </div>
                      ) : (
                        <div className="text-xs sm:text-sm md:text-[18px] lg:text-[26px] font-semibold font-poppins">
                          {totalInvested
                            ? formatCurrency(totalNetReturns)
                            : "-"}
                          %
                          <sub className="text-sm font-semibold pt-4 ml-2">
                            {totalInvested && netReturnsPercentage ? (
                              <span
                                className={
                                  totalInvested.totalnetReturnsPercentage > 0
                                    ? "text-[#16A085]"
                                    : "text-[#E43D3D]"
                                }
                              >
                                {netReturnsPercentage}%
                              </span>
                            ) : (
                              <span className="text-[#000000]/80">0.00%</span>
                            )}
                          </sub>
                        </div>
                      )

                      }
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col  space-y-0  md:space-y-2 lg:space-y-2  justify-center items-center  w-full px-4   lg:pl-[60px] ">
                <div className="items-start  md:space-y-2 ">
                  <div className={style.analyticsHead}>

                    {/* Net Returns */}
                    Cagr


                  </div>
                  {totalNetReturns > 0 ? (
                    <div className={`flex items-center`}>
                      <div className="text-[#000000] text-xs sm:text-sm md:text-[18px]  lg:text-[22px]  font-semibold font-poppins">
                        {/* {formatCurrency(Math.abs(totalNetReturns))}% */}
                        Tbc
                      </div>
                      <sub className="text-[10px]  text-[#16A085] font-semibold font-poppins mt-1 px-[4px] py-[2px] rounded-md ml-1">
                        {/* +{Math.abs(netReturnsPercentage)}% */}
                        {/* - % */}

                      </sub>
                    </div>
                  ) : totalNetReturns < 0 ? (
                    <div className={`flex items-center`}>
                      <div className="text-[#000000] text-xs sm:text-sm md:text-[18px] lg:text-[22px] font-bold font-poppins">
                        {/* {formatCurrency(Math.abs(totalNetReturns))}% */}
                        TBC

                      </div>
                      <sub className="text-[12px]  text-[#E43D3D] font-semibold font-poppins mt-1 px-[4px] py-[2px]  rounded-md ml-1">
                        {/* +{Math.abs(netReturnsPercentage)}% */}
                        {/* - % */}
                      </sub>
                    </div>
                  ) : (
                    <div className="text-xs sm:text-sm md:text-[18px] lg:text-[22px] font-semibold font-poppins">
                      {totalInvested ? formatCurrency(totalNetReturns) : "-"}%
                      <sub className="text-sm font-semibold pt-4 ml-2">
                        {totalInvested && netReturnsPercentage ? (
                          <span
                            className={
                              totalInvested.totalnetReturnsPercentage > 0
                                ? "text-[#16A085]"
                                : "text-[#E43D3D]"
                            }
                          >
                            {netReturnsPercentage}%
                          </span>
                        ) : (
                          <span className="text-[#000000]/80">0.00%</span>
                        )}
                      </sub>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 lg:grid-cols-12 h-[calc(100vh-260px)] lg:mt-[20px] ">
            <div className="flex flex-col col-span-12 lg:pr-[20px]  md:col-span-8 xl:col-span-9">
              {/* <div className="flex text-[14px] font-normal font-poppins text-[#000000]/60">
              Check Latest Rebalance Updates issued by the Manager.
              <span
                className="ml-1 text-blue-500 cursor-pointer"
                onClick={() => setShowRebalanceTimelineModal(true)}
              >
                View Rebalance History
              </span>
            </div> */}

              <p className="text-sm font-normal font-poppins text-gray-600 py-4 md:py-0 md:mb-2">
                Check Latest Rebalance Updates issued by the Manager.
                <span
                  className="ml-1 text-blue-500 cursor-pointer"
                  onClick={() => setShowRebalanceTimelineModal(true)}
                >
                  View Rebalance History
                </span>
              </p>

              <div className="mt-1 md:mt-4 lg:mt-4 grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-0 lg:w-[656px] bg-[#f5f5f5] rounded-[4px] py-4 px-2 sm:px-4 md:px-0">
                <div className="flex flex-col items-center  md:items-start space-y-1 w-full md:text-left md:pl-6">
                  <div>
                    <h4 className="text-[12px]  sm:text-[14px] text-[#000000]/60 font-normal font-poppins text-left">
                      Last Rebalance
                    </h4>
                    <p className="text-[14px]  sm:text-[16px] text-[#000000] font-medium font-poppins">
                      {moment(strategyDetails?.last_updated).format(
                        "MMM DD, YYYY"
                      )}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-center md:items-start space-y-1 w-full md:text-left md:pl-6">
                  <div>
                    <h4 className="text-[12px] sm:text-[14px] text-[#000000]/60 font-normal font-poppins">
                      Next Rebalance
                    </h4>
                    <p className="text-[14px] sm:text-[16px] text-[#000000] font-medium font-poppins">
                      {moment(strategyDetails?.nextRebalanceDate).format(
                        "MMM DD, YYYY"
                      )}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-center md:items-start space-y-1 w-full md:text-left md:pl-6">
                  <div className="ml-10 md:ml-0 lg:ml-0">
                    <h4 className="text-[12px] sm:text-[14px] text-[#000000]/60 font-normal font-poppins whitespace-nowrap">
                      Rebalance Frequency
                    </h4>
                    <p className="text-[14px] sm:text-[16px] text-[#000000] font-medium font-poppins">
                      {strategyDetails?.frequency}
                    </p>
                  </div>
                </div>
              </div>

              <div className="hidden sm:block  mt-6 bg-white w-full overflow-x-auto">
                <div className="min-w-[640px] lg:w-full">
                  <table className="w-full">
                    <thead className="bg-[#f5f5f5] text-sm text-left sticky top-0">
                      <tr className="border-b-[1px] border-[#000000]/10">
                        <th className="text-[12px] min-w-[140px] lg:text-[12px] text-[#000000]/80 font-poppins font-medium text-left px-3 py-2 lg:py-3 lg:px-5">
                          Stock
                        </th>
                        <th className="text-[12px] lg:text-[12px] text-center text-[#000000]/80 font-poppins font-medium px-3 py-2 lg:py-3 lg:px-5">
                          Current Price(₹)
                        </th>
                        <th className="text-[12px] lg:text-[12px] text-center text-[#000000]/80 font-poppins font-medium px-3 py-2 lg:py-3 lg:px-5">
                          Avg. Buy Price(₹)
                        </th>
                        <th className="text-[12px] min-w-[100px] lg:text-[12px] text-center text-[#000000]/80 font-poppins font-medium px-3 py-2 lg:py-3 lg:px-5">
                          Returns(%)
                        </th>
                        <th className="text-[12px] lg:text-[12px] text-center text-[#000000]/80 font-poppins font-medium px-3 py-2 lg:py-3 lg:px-5">
                          Weights(%)
                        </th>
                        <th className="text-[12px] lg:text-[12px] text-center text-[#000000]/80 font-poppins font-medium px-3 py-2 lg:py-3 lg:px-5">
                          Shares
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {net_portfolio_updated &&
                        net_portfolio_updated?.order_results?.map(
                          (stock, index) => {
                            const value =
                              getLTPForSymbol(stock?.symbol) -
                              stock?.averagePrice;
                            const pnlPercent =
                              (value / stock?.averagePrice) * 100;

                            return (
                              <tr
                                key={index}
                                className="border-b-[1px] z-10 border-[#000000]/10"
                              >
                                <td className="text-[14px] text-[#000000] font-poppins font-normal text-left px-3 py-2 lg:py-3 lg:px-5">
                                  {stock.symbol}
                                </td>
                                <td className="text-[14px] text-[#000000] font-poppins font-normal text-right px-3 py-2 lg:py-3 lg:px-5">
                                  {parseFloat(
                                    getLTPForSymbol(stock?.symbol)
                                  ).toFixed(2)}
                                </td>
                                <td className="text-[14px] text-[#000000] font-poppins font-normal text-right px-3 py-2 lg:py-3 lg:px-5">
                                  {parseFloat(stock?.averagePrice).toFixed(2)}
                                </td>
                                {/* <td className="text-[14px] text-[#16A085]   font-poppins font-normal text-right px-3 py-2 lg:py-3 lg:px-5">
                                  {parseFloat(pnlPercent).toFixed(2)}%
                                </td> */}
                                <td
                                  className={`text-[14px] font-poppins font-normal text-right px-3 py-2 lg:py-3 lg:px-5 ${pnlPercent < 0 ? 'text-red-500' : 'text-green-500'
                                    }`}
                                >
                                  {parseFloat(pnlPercent).toFixed(2)}%
                                </td>
                                <td className="text-[14px] text-[#000000] font-poppins font-normal text-right px-3 py-2 lg:py-3 lg:px-5">
                                  {parseFloat(
                                    (stock?.quantity / totalUpdatedQty) * 100
                                  ).toFixed(2)}
                                </td>
                                <td className="capitalize text-[14px] text-[#000000] font-poppins font-normal text-right px-3 py-2 lg:py-3 lg:px-5">
                                  {stock?.quantity}
                                </td>
                              </tr>
                            );
                          }
                        )}
                    </tbody>
                  </table>
                  {net_portfolio_updated === undefined && (
                    <>
                      {portfolioLoading ? (
                        <div className="flex flex-row justify-center py-12 text-xl font-semibold">
                          <LoadingSpinner />
                        </div>
                      ) : (
                        <div className="flex flex-row justify-center py-12 text-xl font-semibold">
                          <div>No Data Available</div>
                        </div>
                      )}
                    </>
                  )}
                </div>{" "}
              </div>
            </div>

            {/* Mobile view */}

            <div className="md:hidden mt-6  w-full">
              <div className="flex  bg-[#F1F0F0] justify-between items-center px-4 py-3">
                <div className="text-[12px]  sm:text-[14px] text-[#000000]/60 font-normal font-poppins text-left">
                  Stock
                </div>
                <div className="flex items-center">
                  <span className="text-[12px]  sm:text-[14px] text-[#000000]/60 font-normal font-poppins text-left">
                    Returns(%)
                  </span>
                  <button onClick={toggleSort} className="focus:outline-none">
                    <ArrowDownWideNarrow className="w-4 h-4  text-[#000000]/60  transform scale-x-[-1]" />
                  </button>
                </div>
              </div>
              {net_portfolio_updated === undefined ? (
                <>
                  {portfolioLoading ? (
                    <div className="flex flex-row justify-center py-6 text-xl font-semibold">
                      <LoadingSpinner />
                    </div>
                  ) : (
                    <div className="flex flex-row justify-center py-6 text-xl font-semibold">
                      <div>No Data Available</div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {net_portfolio_updated &&
                    net_portfolio_updated?.order_results?.map((ele, index) => {
                      const value =
                        getLTPForSymbol(ele?.symbol) - ele?.averagePrice;
                      const pnlPercent = (value / ele?.averagePrice) * 100;

                      return (
                        <div
                          key={index}
                          className="border-b border-[#000000]/10"
                        >
                          <div
                            className="flex justify-between items-center px-4 py-3 cursor-pointer"
                            onClick={() => toggleExpansion(index)}
                          >
                            <div className="text-[14px] text-[#000000]">
                              {ele.symbol}
                            </div>
                            <div className="flex items-center">
                              <span className="text-[14px] text-[#16A085] mr-2">
                                {parseFloat(pnlPercent).toFixed(2)}%
                              </span>
                              {expandedItems[index] ? (
                                <ChevronUp className="w-4 h-4 text-[#000000]/60" />
                              ) : (
                                <ChevronDown className="w-4 h-4 text-[#000000]/60" />
                              )}
                            </div>
                          </div>
                          {expandedItems[index] && (
                            <div className="px-4 py-2">
                              <div className="grid grid-cols-2 gap-y-2">
                                <div className="flex items-center justify-between">
                                  <div className="text-[12px] text-[#000000]/60">
                                    {" "}
                                    Weights(%):{" "}
                                    <span className="text-black">
                                      {(
                                        (ele?.updated_qty / totalUpdatedQty) *
                                        100
                                      ).toFixed(2)}
                                    </span>
                                  </div>
                                </div>

                                <div className="flex items-center justify-end ">
                                  <div className="text-[12px] text-[#000000]/60">
                                    Current Price(₹):{" "}
                                    <span className="text-black">
                                      {parseFloat(
                                        getLTPForSymbol(ele?.symbol)
                                      ).toFixed(2)}
                                    </span>
                                  </div>
                                </div>

                                <div className="flex items-center justify-between">
                                  <div className="text-[12px] text-[#000000]/60">
                                    Shares:{" "}
                                    <span className="text-black">
                                      {ele?.updated_qty}
                                    </span>
                                  </div>
                                </div>

                                <div className="flex items-center justify-end">
                                  <div className="text-[12px] text-[#000000]/60">
                                    Avg. Buy Price(₹):{" "}
                                    <span className="text-black">
                                      {ele?.averagePrice}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    })}
                </>
              )}
            </div>

            <div className="flex flex-col items-center lg:px-[10px] col-span-12 lg:col-span-3 mt-6 lg:mt-0">
              <div className="flex flex-col py-3 md:py-6 items-center justify-center shadow-md border-[1px] border-[#000000]/10 rounded-[4px] w-full max-w-full lg:max-w-[340px]">
                <div className="flex flex-col md:flex-row md:items-center w-full">
                  <div className="flex flex-row md:flex-col items-center md:space-y-6 w-full">
                    <div className="order-2 md:order-none w-[25%] md:w-full flex justify-center items-center mt-4 md:mt-0 lg:mt-0">
                      <img
                        src={PigIcon}
                        alt="Money Icon"
                        className="h-[100px] w-[100px] md:h-[120px] md:w-[120px] rounded-[4px]"
                      />
                    </div>
                    <div className="order-1 md:order-none w-[75%] md:w-full flex flex-col justify-center space-y-4 md:space-y-6">
                      {/* Mobile and Desktop text */}
                      <div className="flex items-center justify-start text-[13px]  md:justify-center text-left md:text-center md:text-[14px] lg:md:text-[14px] font-medium font-poppins text-[#000000]/70 px-4">
                        Unlock stability with regular investments in this
                        portfolio, to handle market volatility.
                      </div>

                      <div className="flex items-center justify-start md:justify-center px-4 md:px-0">
                        <button className="flex items-center justify-center text-sm md:text-sm font-medium font-poppins px-3 md:px-4 py-1 md:py-2 rounded-[4px] border border-[#1D1D1F] whitespace-nowrap">
                          <span>SIP (Coming Soon)</span>
                          <ChevronRightIcon className="text-[#000000]/60 h-3 w-3 md:h-4 md:w-4 ml-1 flex-shrink-0" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div
                className="w-full max-w-[300px] lg:max-w-none mt-6 py-3 flex items-center justify-center text-[14px] font-medium font-poppins text-[#E6626F] border-[1px] border-[#E6626F] rounded-[4px] cursor-pointer"
                onClick={() => setTerminateModal(true)}
              >
                Terminate {fileName?.replaceAll(/_/g, " ")}
              </div>
            </div>
          </div>
        </div>

        {showRebalanceTimelineModal && (
          <RebalanceTimeLineModal
            closeRebalanceTimelineModal={closeRebalanceTimelineModal}
            strategyDetails={strategyDetails}
          />
        )}
        {terminateModal === true ? (
          <TerminateStrategyModal
            setTerminateModal={setTerminateModal}
            terminateModal={terminateModal}
            userEmail={userEmail}
            strategyDetails={strategyDetails}
            userDetails={userDetails}
            getStrategyDetails={getStrategyDetails}
            tableData={tableData}
            totalInvested={totalInvested}
            totalCurrent={totalCurrent}
          />
        ) : null}

        {modifyInvestmentModal === true ? (
          <ModifyInvestmentModal
            modifyInvestmentModal={modifyInvestmentModal}
            setModifyInvestmentModal={setModifyInvestmentModal}
            userEmail={userEmail}
            strategyDetails={strategyDetails}
            getStrategyDetails={getStrategyDetails}
            amount={
              sortedRebalances.length > 0 ? sortedRebalances[0]["amount"] : 0
            }
            latestRebalance={latestRebalance}
            userBroker={userDetails && userDetails?.user_broker}
          />
        ) : null}
      </div>
      <div className="lg:hidden flex items-center w-full h-[80px] shadow-[0px_-4px_4px_0px_#0000000f] px-4">
        <button
          onClick={() => setModifyInvestmentModal(true)}
          className="mt-3 w-full text-[16px] font-poppins font-medium bg-black text-white rounded-md py-4 px-6"
        >
          Modify Investment
        </button>
      </div>
    </div>
  );
};

export default StrategyDetailsWithPortfolioData;
