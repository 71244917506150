import React, { useState, useEffect, useCallback, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { XIcon, Plus, AlertCircle, AlertTriangleIcon } from "lucide-react";
import toast from "react-hot-toast";
import axios from "axios";
import moment from "moment";
import server from "../../utils/serverConfig";
import { debounce } from "lodash";
import LoadingSpinner from "../../components/LoadingSpinner";
import useWebSocketCurrentPrice from "../../FunctionCall/useWebSocketCurrentPrice";
import EmojiPicker, { EmojiClickData } from "emoji-picker-react";
import { Smile } from "lucide-react";
import CommentModal from "./CommentModal";

const ACCEPTABLE_DATE_FORMATS = [
  "D MMM YYYY, HH:mm:ss",
  "YYYY-MM-DDTHH:mm:ss.SSSZ",
];

const SendAdviceModel = ({
  fileName,
  closeSendAdviceModal,
  data,
  adminEmail,
  advisorName,
  email_password,
  setSendAdviceModal,
  setSelectedRows,
  adminRationale,
  advisorSpecifier,
}) => {
  const showQuantityColumn = advisorSpecifier === "RIA";
  const [filteredClients, setFilteredClients] = useState([]);

  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("equity");
  const [showMainSection, setShowMainSection] = useState(true);
  const [activeDerivativesTab, setActiveDerivativesTab] = useState("entry");

  const [adviceEntries, setAdviceEntries] = useState([
    {
      id: Date.now(),
      symbol: "",
      exchange: "",
      orderType: "MARKET",
      productType: "DELIVERY",
      quantity: advisorSpecifier === "RIA" ? 1 : "",
      segment: "EQUITY",
      price: 0,
      type: "",
      adviceLower: 0,
      adviceHigher: 0,
      inputValue: "",
      symbols: [],
      comments: "",
      comment2: "",

      advisorType: advisorSpecifier,
      price_when_send_advice: 0,
      rationale: adminRationale
        ? adminRationale
        : "This recommendation is based on a comprehensive analysis of the company's growth potential and value metrics. This recommendation also accounts for potential future risks, ensuring a balanced approach to maximizing returns while mitigating uncertainties. Please contact your advisor for any queries.",
    },
  ]);

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleAddAdviceEntry = () => {
    const newEntry = {
      id: Date.now(),
      symbol: "",
      exchange: "",
      orderType: "MARKET",
      productType: "DELIVERY",
      quantity: advisorSpecifier === "RIA" ? 1 : "",
      segment: "EQUITY",
      price: 0,
      type: "",
      adviceLower: 0,
      adviceHigher: 0,
      inputValue: "",
      symbols: [],
      comments: "",
      comment2: "",

      advisorType: advisorSpecifier,
      price_when_send_advice: 0,
      rationale:
        adminRationale ||
        "This recommendation is based on comprehensive analysis...",
    };

    setAdviceEntries([...adviceEntries, newEntry]);
  };

  const handleRemoveAdviceEntry = (index) => {
    const updatedEntries = adviceEntries.filter((_, i) => i !== index);
    setAdviceEntries(updatedEntries);
  };

  const numericFields = ["price", "anotherNumericField"]; // Add all numeric field names here

  const handleAdviceChange = (index, field, value) => {
    let formattedValue = value;
    // Format value if the field is numeric
    if (numericFields?.includes(field)) {
      // Allow only valid numbers with up to two decimal places
      if (value) {
        const [integerPart, decimalPart] = value.split(".");
        if (decimalPart && decimalPart.length > 2) {
          formattedValue = `${integerPart}.${decimalPart.slice(0, 2)}`;
        } else {
          formattedValue = value;
        }
      }
    }

    const updatedEntries = adviceEntries.map((entry, i) =>
      i === index ? { ...entry, [field]: formattedValue } : entry
    );
    setAdviceEntries(updatedEntries);
  };

  // Derivative add entry
  const [adviceDerivativesEntries, setAdviceDerivativesEntries] = useState([
    {
      id: Date.now(),
      symbol: "",
      foType: "",
      expiry: "",
      strike: "",
      optionType: "",
      lots: "",
      price: "",
      rationale: "",
      comments: "",
      extendedComment: "",
      strikes: [],
      optionTypes: [],
      symbols: [],
    },
  ]);

  const [adviceBasketEntries, setAdviceBasketEntries] = useState([
    {
      id: Date.now(),
      symbol: "",
      foType: "",
      expiry: "",
      strike: "",
      OrderType: "",
      lots: "",
      price: "",
      commonRationale: "",
      commonComment: "",
      commonExtendedComment: "",
      symbols: [],
      strikes: [],
      optionTypes: [],
    },
  ]);

  // handle adding a new derivatives entry
  const handleAddDerivativesEntry = () => {
    const newEntry = {
      id: Date.now(),
      symbol: "",
      foType: "",
      expiry: "",
      strike: "",
      optionType: "",
      orderType: "",
      productType: "",
      lots: " ",
      price: " ",
      rationale: "",
      comments: "",
      comments2: "",
    };
    setAdviceDerivativesEntries([...adviceDerivativesEntries, newEntry]);
  };

  //  handle changes in derivatives entries
  const handleDerivativeChange = (index, field, value) => {
    setAdviceDerivativesEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === index ? { ...entry, [field]: value } : entry
      )
    );

    // If the field is 'foType', reset the optionType and strike
    if (field === "foType") {
      setAdviceDerivativesEntries((prevEntries) =>
        prevEntries.map((entry, i) =>
          i === index
            ? { ...entry, optionType: "", strike: "", lots: "" }
            : entry
        )
      );
    }

    // If changing lotMultiplier, update the total quantity
    if (field === "lotMultiplier") {
      setAdviceDerivativesEntries((prevEntries) =>
        prevEntries.map((entry, i) =>
          i === index ? { ...entry, lotMultiplier: value } : entry
        )
      );
    }
  };
  const addDerivativeEntry = () => {
    setAdviceDerivativesEntries([
      ...adviceDerivativesEntries,
      {
        symbol: "",
        searchSymbol: "",
        strike: "",
        optionType: "",
        lots: "",
        exchange: "",
        strikes: [],
        symbols: [],
        optionTypes: [],
        lotMultiplier: 1,
      },
    ]);
  };

  const isValid = adviceEntries.every((entry) => {
    const isPriceRequired =
      entry.orderType === "LIMIT" || entry.orderType === "STOP";
    return (
      entry.symbol &&
      entry.exchange &&
      entry.orderType &&
      entry.productType &&
      entry.segment &&
      entry.type &&
      entry.rationale && // Add rationale validation
      (!isPriceRequired || entry.price)
    );
  });

  const handleSymbolSelect = (index, symbol, exchange) => {
    const updatedEntries = adviceEntries.map((entry, i) =>
      i === index
        ? {
            ...entry,
            symbol: symbol,
            symbols: [],
            inputValue: symbol,
            exchange: exchange,
          }
        : entry
    );
    setAdviceEntries(updatedEntries);
    setIsOpen(false);
  };

  const fetchSymbols = async (index, inputValue) => {
    const data = JSON.stringify({
      symbol: inputValue,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${server.ccxtServer.baseUrl}angelone/get-symbol-name-exchange`,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    try {
      const response = await axios.request(config);
      setAdviceEntries((prevEntries) =>
        prevEntries.map((entry, i) =>
          i === index ? { ...entry, symbols: response.data.match } : entry
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  const debouncedFetchSymbols = useCallback(
    debounce((index, value) => {
      fetchSymbols(index, value);
    }, 300),
    []
  );

  const handleInputChange = (index, value) => {
    setAdviceEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === index ? { ...entry, inputValue: value, symbol: value } : entry
      )
    );

    if (value.length >= 3) {
      debouncedFetchSymbols(index, value);
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  const { getLTPForSymbol } = useWebSocketCurrentPrice(adviceEntries);

  const { getLTPForSymbol: getLTPForSymbol2 } = useWebSocketCurrentPrice(
    adviceDerivativesEntries
  );

  const handleRationaleChange = (index, rationaleMsg) => {
    setAdviceEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === index ? { ...entry, rationale: rationaleMsg } : entry
      )
    );
  };

  const handleCommentsChange = (index, commentsMsg) => {
    if (commentsMsg.length <= 256) {
      setAdviceEntries((prevEntries) =>
        prevEntries.map((entry, i) =>
          i === index ? { ...entry, comments: commentsMsg } : entry
        )
      );
    } else {
      // Optionally, you could handle this case (e.g., showing a message or preventing further input)

      toast.error("Comment exceeds the maximum length of 256 characters", {
        duration: 3000,
        style: {
          background: "white",
          color: "#1e293b",
          maxWidth: "500px",
          fontWeight: 600,
          fontSize: "13px",
          padding: "10px 20px",
        },
        iconTheme: {
          primary: "#e43d3d",
          secondary: "#FFFAEE",
        },
      });
    }
  };

  const handleComments2Change = (index, commentsMsg) => {
    if (commentsMsg.length <= 256) {
      setAdviceEntries((prevEntries) =>
        prevEntries.map((entry, i) =>
          i === index ? { ...entry, comment2: commentsMsg } : entry
        )
      );
    } else {
      toast.error(
        "Extended comment exceeds the maximum length of 256 characters",
        {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        }
      );
    }
  };

  const todaydate = new Date();

  const isSubscriptionValid = (subscriptions, groupName) => {
    if (!subscriptions || subscriptions?.length === 0) return false;
    const matchingPlanSubs = subscriptions?.filter(
      (sub) => sub?.plan === groupName
    );
    if (matchingPlanSubs?.length === 0) return false;
    const validSubscriptions = matchingPlanSubs?.filter((sub) =>
      sub?.expiry
        ? moment(sub?.expiry, ACCEPTABLE_DATE_FORMATS, true).isValid()
        : false
    );
    if (validSubscriptions?.length === 0) return false;
    const latestSub = validSubscriptions?.sort(
      (a, b) =>
        moment(b?.expiry, ACCEPTABLE_DATE_FORMATS) -
        moment(a?.expiry, ACCEPTABLE_DATE_FORMATS)
    )[0];
    const expiryDate = moment(latestSub?.expiry, ACCEPTABLE_DATE_FORMATS);
    const today = moment();
    return expiryDate.isAfter(today);
  };

  useEffect(() => {
    const validClients = data.filter((client) =>
      isSubscriptionValid(client.subscriptions, fileName)
    );
    setFilteredClients(validClients);
  }, []);

  const handleSendAdvice = () => {
    setLoading(true);

    const adviceData = filteredClients.flatMap((user) =>
      adviceEntries.map((entry) => {
        // Format country code - ensure it starts with '+'
        let formattedCountryCode = String(user?.country_code || "+91").trim();
        formattedCountryCode =
          formattedCountryCode.charAt(0) === "+"
            ? formattedCountryCode
            : "+" + formattedCountryCode;

        return {
          email: user.email,
          userName: user?.clientName,
          phoneNumber: user?.phone,
          country_code: formattedCountryCode, // Use formatted country code
          telegramId: user?.telegram,
          trade_given_by: adminEmail,
          email_password: email_password,
          trade_given_by_fileName: fileName,
          advisor_name: advisorName,
          Symbol: entry.symbol,
          Exchange: entry.exchange,
          Type: entry.type,
          OrderType: entry.orderType,
          ProductType: entry.productType,
          Segment: entry.segment,
          Price: entry.orderType === "MARKET" ? 0 : entry.price,
          date: moment(todaydate).format(),
          Quantity: entry.quantity,
          Advised_Range_Lower: entry.adviceLower,
          Advised_Range_Higher: entry.adviceHigher,
          rationale: entry?.rationale,
          comments: entry?.comments,
          comment2: entry?.comment2,
          advisorType: advisorSpecifier,
          price_when_send_advice: getLTPForSymbol(entry?.symbol),
          group: fileName,
        };
      })
    );
    let config = {
      method: "post",
      url: `${server.ccxtServer.baseUrl}comms/send-reco`,
      headers: {
        "Content-Type": "application/json",
      },
      data: adviceData,
    };

    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        // processClientsAndSendNotifications();
        toast.success("Advice has been sent successfully", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#16a085",
            secondary: "#FFFAEE",
          },
        });
        setTimeout(() => {
          setAdviceEntries([
            {
              symbol: "",
              exchange: "NSE",
              orderType: "MARKET",
              productType: "DELIVERY",
              quantity: 1,
              segment: "EQUITY",
              price: 0,
              type: "",
              adviceLower: 0,
              adviceHigher: 0,
              inputValue: "",
              symbols: [],
            },
          ]);
          setSendAdviceModal(false);
          setSelectedRows([]);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        toast.error("Error in sending Advice !", {
          duration: 3000,
          style: {
            background: "white",
            color: "#1e293b",
            maxWidth: "500px",
            fontWeight: 600,
            fontSize: "13px",
            padding: "10px 20px",
          },
          iconTheme: {
            primary: "#e43d3d",
            secondary: "#FFFAEE",
          },
        });
        setLoading(false);
      });
  };

  const sendNotifications = async (fcmTokens, notificationData) => {
    console.log("Notification Data:", notificationData[0]); // Log notificationData
    for (let token of fcmTokens) {
      try {
        const response = await fetch(
          `${server.server.baseUrl}api/notifications/send`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              fcmToken: token,
              title: notificationData[0].Symbol, // Customizing the title
              body: "stock Advice", // Customizing the body
              notificationType: notificationData[0].notificationType, // Including notification type
              Symbol: notificationData[0].Symbol, // Including symbol
              Type: notificationData[0].Type, // Including types
              Price: notificationData[0].Price, // Including price
            }),
          }
        );

        const result = await response.json();
        console.log(`Notification sent to ${token}:`, result);
      } catch (error) {
        console.error(`Error sending notification to ${token}:`, error);
      }
    }
  };

  // Main function to process client data and send notifications

  const fetchUserDetailsByEmail = async (email) => {
    try {
      const response = await axios.get(
        `${server.server.baseUrl}api/user/getUser/${email}`
      );
      console.log("response:", response.data);
      return response.data; // Assuming the user data is returned in the response
    } catch (error) {
      console.error(
        `Error fetching user details for ${email}:`,
        error.response.data || error.message
      );
      return null; // Return null or an appropriate value if the user is not found
    }
  };

  const processClientsAndSendNotifications = async (notificationData) => {
    let fcmTokens = [];
    // Iterate through each user in the data array
    for (const user of data) {
      const { email } = user; // Destructure to get the email
      console.log("user:", email);
      if (!email) {
        console.warn("User does not have an email:", user);
        continue; // Skip this user if there is no email
      }

      try {
        const userDetails = await fetchUserDetailsByEmail(email); // Fetch user details by email
        console.log("userDetails:", userDetails);
        if (userDetails && userDetails.User.fcm_token) {
          console.log("FCM token:", userDetails.User.fcm_token);
          fcmTokens.push(userDetails.User.fcm_token); // Collect valid FCM tokens
        } else {
          console.warn(`No valid FCM token found for user: ${email}`);
        }
      } catch (error) {
        console.error(`Error fetching user details for ${email}:, error`);
      }
    }

    // Check if we have any tokens to send notifications
    if (fcmTokens.length > 0) {
      await sendNotifications(fcmTokens, notificationData); // Pass fcmTokens and notificationData to sendNotifications
    } else {
      console.log("No valid FCM tokens found for sending notifications.");
    }
  };
  const formatToTwoDecimals = (value) => {
    // Remove any non-digit and non-decimal point characters
    let formatted = value.replace(/[^\d.]/g, "");

    // Ensure only one decimal point
    const parts = formatted.split(".");
    if (parts.length > 2) {
      formatted = parts[0] + "." + parts.slice(1).join("");
    }

    // Limit to two decimal places
    if (parts.length > 1) {
      formatted = parts[0] + "." + parts[1].slice(0, 2);
    }

    return formatted;
  };
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [showEmojiPicker2, setShowEmojiPicker2] = useState(false);
  const [activeCommentIndex, setActiveCommentIndex] = useState(null);

  const [activeEmojiPicker, setActiveEmojiPicker] = useState(null);
  const emojiPickerRef = useRef(null);

  const toggleEmojiPicker = (index, type) => {
    if (
      activeEmojiPicker &&
      (activeEmojiPicker.index !== index || activeEmojiPicker.type !== type)
    ) {
      setActiveEmojiPicker(null);
    } else {
      setActiveEmojiPicker(activeEmojiPicker ? null : { index, type });
    }
  };

  // const handleEmojiClick = (emojiObject, index, type) => {
  //   const updatedEntries = [...adviceEntries]
  //   if (type === 'comment') {
  //     updatedEntries[index].comments += emojiObject.emoji
  //   } else if (type === 'comment2') {
  //     updatedEntries[index].comment2 += emojiObject.emoji
  //   }
  //   setAdviceEntries(updatedEntries)
  // }
  const handleEmojiClick = (emojiObject, index, type) => {
    const updatedEntries = [...adviceEntries];
    if (type === "comment") {
      updatedEntries[index].comments += emojiObject.emoji;
    } else if (type === "comment2") {
      updatedEntries[index].comment2 += emojiObject.emoji;
    }
    setAdviceEntries(updatedEntries);
    // Close the emoji picker after selecting an emoji
    setActiveEmojiPicker(null);
  };

  const handleEmojiPickerDoubleClick = () => {
    setActiveEmojiPicker(null);
  };

  const handleClickOutsideEmoji = (event) => {
    if (
      emojiPickerRef.current &&
      !emojiPickerRef.current.contains(event.target) &&
      !event.target.closest("button")
    ) {
      setActiveEmojiPicker(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideEmoji);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideEmoji);
    };
  }, []);

  useEffect(() => {
    const handleDoubleClick = () => {
      setActiveEmojiPicker(null);
    };

    document.addEventListener("dblclick", handleDoubleClick);
    return () => {
      document.removeEventListener("dblclick", handleDoubleClick);
    };
  }, []);

  // useEffect(() => {
  //   document.addEventListener("mousedown", handleClickOutsideEmoji)
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutsideEmoji)
  //   }
  // }, [])

  const [activeCommentModal, setActiveCommentModal] = useState(null);
  const [modalPosition, setModalPosition] = useState({ top: 0, left: 0 });

  const fetchDerivativesSymbols = async (index, inputValue, type) => {
    if (inputValue.length < 3) return;

    try {
      const response = await axios.post(
        `${server.ccxtServer.baseUrl}comms/fno/search`,
        {
          symbol: inputValue,
          type: type || "",
        }
      );
      const symbols = response.data.match || [];

      const strikes = [...new Set(symbols.map((sym) => sym.strike))]
        .filter(Boolean)
        .sort((a, b) => a - b);
      const optionTypes = [
        ...new Set(symbols.map((sym) => sym.optionType)),
      ].filter(Boolean);

      setAdviceDerivativesEntries((prevEntries) =>
        prevEntries.map((entry, i) =>
          i === index
            ? {
                ...entry,
                symbols: symbols,
                strikes: strikes,
                optionTypes: optionTypes,
              }
            : entry
        )
      );
    } catch (error) {
      console.error("Error fetching derivatives symbols:", error);
    }
  };

  const debouncedFetchDerivativesSymbols = useCallback(
    debounce((index, value, type) => {
      fetchDerivativesSymbols(index, value, type);
    }, 300),
    []
  );

  const handleDerivativesInputChange = (index, value) => {
    setAdviceDerivativesEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === index
          ? { ...entry, searchSymbol: value, symbol: value, showDropdown: true }
          : entry
      )
    );

    if (value.length >= 3) {
      const currentType = adviceDerivativesEntries[index].foType;
      debouncedFetchDerivativesSymbols(index, value, currentType);
    }
  };

  const handleDerivativesSymbolSelect = (
    index,
    symbol,
    lotsize,
    strike,
    exchange,
    optionType
  ) => {
    setAdviceDerivativesEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === index
          ? {
              ...entry,
              symbol: symbol.symbol,
              searchSymbol: symbol.searchSymbol,
              strike: strike,
              optionType: optionType, // Set the optionType
              lots: symbol.lotsize,
              exchange: exchange,
              strikes: prevEntries[i].strikes || [], // Preserve the strikes array
              symbols: [],
              showDropdown: false,
            }
          : entry
      )
    );
    fetchDerivativeSymbols(index, symbol.searchSymbol, "OPTIONS");
  };

  const isDerivativesValid = (entries) => {
    if (activeDerivativesTab === "basketEntry") {
      return entries.every(
        (entry) =>
          entry.foType &&
          entry.symbol &&
          entry.orderType &&
          entry.lots &&
          entry.priority &&
          entries[0].commonRationale // Check if common rationale is filled
      );
    } else {
      return entries.every((entry) => {
        return (
          entry.foType &&
          entry.symbol &&
          entry.orderType &&
          entry.rationale &&
          entry.comments &&
          (entry.foType === "Options" ? entry.strike : true) &&
          entry.lots
          // &&
          // entry.price
        );
      });
    }
  };

  const handleSendDerivativesAdvice = () => {
    setLoading(true);

    let adviceData;

    if (activeDerivativesTab === "basketEntry") {
      // Sort basket entries by priority
      const sortedEntries = [...adviceBasketEntries].sort(
        (a, b) => a.priority - b.priority
      );

      adviceData = filteredClients.flatMap((user) =>
        sortedEntries.map((entry) => {
          const matchedSymbol =
            entry.fullSymbolObject &&
            entry.fullSymbolObject.symbol === entry.symbol &&
            entry.fullSymbolObject.strike === entry.strike &&
            entry.fullSymbolObject.optionType === entry.optionType
              ? entry.fullSymbolObject
              : null;

          return {
            email: user.email,
            userName: user?.clientName,
            phoneNumber: user?.phone,
            country_code:
              String(user?.country_code || "+91")
                .trim()
                .charAt(0) === "+"
                ? String(user?.country_code || "+91").trim()
                : "+" + String(user?.country_code || "+91").trim(),
            telegramId: user?.telegram,
            trade_given_by: adminEmail,
            email_password: email_password,
            trade_given_by_fileName: fileName,
            advisor_name: advisorName,
            Symbol: entry.symbols.find(
              (s) =>
                s.searchSymbol === entry.searchSymbol &&
                s.optionType === entry.optionType
            )?.symbol,
            Strike: entry.strike,
            Type: entry.orderType,
            OrderType: entry.order || "MARKET",
            OptionType: entry.optionType,
            // OrderType: entry.order,
            ProductType: entry.productType || "CARRYFORWARD",
            searchSymbol: entry.searchSymbol,
            Segment: entry.foType,
            Exchange: entry.exchange,
            Lots: entry.lots,
            Price: getLTPForSymbol2(entry?.symbol),
            price_when_send_advice: getLTPForSymbol2(entry?.symbol),
            date: moment(new Date()).format(),
            rationale: entry.commonRationale,
            comments: entry.commonComment,
            comment2: entry.commonExtendedComment,
            advisorType: advisorSpecifier,
            group: fileName,
            Basket: true,
            priority: entry.priority,
          };
        })
      );
    } else {
      // Updated logic for non-basket entries
      adviceData = filteredClients.flatMap((user) =>
        adviceDerivativesEntries.map((entry) => {
          // Find the correct symbol based on the combination
          const matchedSymbol =
            entry.fullSymbolObject &&
            entry.fullSymbolObject.symbol === entry.symbol &&
            entry.fullSymbolObject.strike === entry.strike &&
            entry.fullSymbolObject.optionType === entry.optionType
              ? entry.fullSymbolObject
              : null;

          return {
            email: user.email,
            userName: user?.clientName,
            phoneNumber: user?.phone,
            country_code:
              String(user?.country_code || "+91")
                .trim()
                .charAt(0) === "+"
                ? String(user?.country_code || "+91").trim()
                : "+" + String(user?.country_code || "+91").trim(),
            telegramId: user?.telegram,
            trade_given_by: adminEmail,
            email_password: email_password,
            trade_given_by_fileName: fileName,
            advisor_name: advisorName,
            Symbol: entry.symbols.find(
              (s) =>
                s.searchSymbol === entry.searchSymbol &&
                s.optionType === entry.optionType
            )?.symbol,
            Strike: entry.strike,
            Type: entry.orderType,
            Segment: entry.foType,
            Exchange: entry.exchange,
            OptionType: entry.optionType,
            OrderType: entry.order || "MARKET",
            ProductType: entry.productType || "CARRYFORWARD",
            searchSymbol: entry.searchSymbol,
            Lots: entry.lots,
            Price: entry.price,
            price_when_send_advice: getLTPForSymbol2(
              matchedSymbol ? matchedSymbol.symbol : entry.symbol
            ),
            date: moment(new Date()).format(),
            rationale: entry.rationale,
            comments: entry.comments,
            comment2: entry.extendedComment,
            advisorType: advisorSpecifier,
            group: fileName,
            Basket: false,
          };
        })
      );
    }

    let config = {
      method: "post",
      url: `${server.ccxtServer.baseUrl}comms/send-reco`, // Assuming server is defined elsewhere
      headers: {
        "Content-Type": "application/json",
      },
      data: adviceData,
    };

    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        toast.success(
          activeDerivativesTab === "basketEntry"
            ? "Basket advice has been sent successfully"
            : "Derivatives advice has been sent successfully",
          {
            duration: 3000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#16a085",
              secondary: "#FFFAEE",
            },
          }
        );
        setTimeout(() => {
          if (activeDerivativesTab === "basketEntry") {
            setAdviceBasketEntries([
              {
                id: Date.now(),
                symbol: "",
                foType: "",
                expiry: "",
                strike: "",
                optionType: "",
                lots: 1,
                price: 0,
                commonRationale: "",
                commonComment: "",
                commonExtendedComment: "",
                priority: 1,
              },
            ]);
          } else {
            setAdviceDerivativesEntries([
              {
                id: Date.now(),
                symbol: "",
                foType: "",
                expiry: "",
                strike: "",
                optionType: "",
                lots: 1,
                price: 0,
                rationale: "",
                comments: "",
                comments2: "",
              },
            ]);
          }
          setSendAdviceModal(false);
          setSelectedRows([]);
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          activeDerivativesTab === "basketEntry"
            ? "Error in sending Basket Advice!"
            : "Error in sending Derivatives Advice!",
          {
            duration: 3000,
            style: {
              background: "white",
              color: "#1e293b",
              maxWidth: "500px",
              fontWeight: 600,
              fontSize: "13px",
              padding: "10px 20px",
            },
            iconTheme: {
              primary: "#e43d3d",
              secondary: "#FFFAEE",
            },
          }
        );
        setLoading(false);
      });
  };

  const handleAddBasketEntry = () => {
    const newEntry = {
      id: Date.now(),
      symbol: "",
      foType: "",
      expiry: "",
      strike: "",
      optionType: "",
      lots: " ",
      price: " ",
      commonRationale: adviceBasketEntries[0].commonRationale,
      commonComment: adviceBasketEntries[0].commonComment,
      commonExtendedComment: adviceBasketEntries[0].commonExtendedComment,
      optionTypes: [],
      priority: adviceBasketEntries.length + 1,
    };
    setAdviceBasketEntries([...adviceBasketEntries, newEntry]);
  };

  const handleBasketSymbolSelect = (index, symbol) => {
    setAdviceBasketEntries((prevEntries) =>
      prevEntries.map((entry, i) =>
        i === index
          ? {
              ...entry,
              symbol: symbol.symbol,
              searchSymbol: symbol.searchSymbol,
              selectedSymbol: symbol.symbol.split(" ")[0],

              strike: symbol.strike,
              optionType: symbol.optionType,
              lots: symbol.lotsize,
              exchange: symbol.exchange,
              symbols: [],
              strikes: [symbol.strike],
              optionTypes: [symbol.optionType],
              fullSymbolObject: symbol,
              showBasketDropdown: false,
            }
          : entry
      )
    );
    fetchBasketSymbols(index, symbol.searchSymbol, "OPTIONS");
  };
  const debouncedFetchBasketSymbols = useCallback(
    debounce((index, value, type) => {
      fetchBasketSymbols(index, value, type);
    }, 300),
    []
  );
  //  Added new function to fetch symbols for basket entry
  const fetchBasketSymbols = async (index, inputValue, type) => {
    if (inputValue.length < 3) return;

    try {
      const response = await axios.post(
        `${server.ccxtServer.baseUrl}comms/fno/search`,
        {
          symbol: inputValue,
          type: type || "",
        }
      );

      const symbols = response.data.match || [];
      console.log("symbols", symbols);
      setAdviceBasketEntries((prevEntries) =>
        prevEntries.map((entry, i) =>
          i === index
            ? {
                ...entry,
                symbols: symbols,
                strikes:
                  type === "OPTIONS"
                    ? [...new Set(symbols.map((sym) => sym.strike))]
                        .filter(Boolean)
                        .sort((a, b) => a - b)
                    : [],
                optionTypes:
                  type === "OPTIONS"
                    ? [...new Set(symbols.map((sym) => sym.optionType))].filter(
                        Boolean
                      )
                    : [],
              }
            : entry
        )
      );
    } catch (error) {
      console.error("Error fetching basket symbols:", error);
    }
  };

  const fetchDerivativeSymbols = async (index, inputValue, type) => {
    if (inputValue.length < 3) return;

    try {
      const response = await axios.post(
        `${server.ccxtServer.baseUrl}comms/fno/search`,
        {
          symbol: inputValue,
          type: type || "",
        }
      );

      const symbols = response.data.match || [];
      console.log("symbols", symbols);

      setAdviceDerivativesEntries((prevEntries) =>
        prevEntries.map((entry, i) =>
          i === index
            ? {
                ...entry,
                symbols: symbols,
              }
            : entry
        )
      );
    } catch (error) {
      console.error("Error fetching symbols:", error);
    }
  };

  // Updated handleBasketChange function to handle symbol input
  const handleBasketChange = (index, field, value) => {
    setAdviceBasketEntries((prevEntries) =>
      prevEntries.map((entry, i) => {
        if (i === index) {
          const updatedEntry = {
            ...entry,
            [field]: value,
            showBasketDropdown: true,
          };

          // Reset related fields when changing segment type
          if (field === "foType") {
            updatedEntry.strike = "";
            updatedEntry.optionType = "";
            updatedEntry.symbol = "";
            updatedEntry.searchSymbol = "";
            updatedEntry.symbols = [];
            updatedEntry.strikes = [];
            updatedEntry.optionTypes = [];
            updatedEntry.order = "";
            updatedEntry.productType = "";
            updatedEntry.orderType = "";
          }
          // Handle priority changes
          if (field === "priority") {
            const newPriority = parseInt(value, 10);
            if (!isNaN(newPriority) && newPriority > 0) {
              updatedEntry.priority = newPriority;
            }
          }
          return updatedEntry;
        }
        return entry;
      })
    );
    // Fetch symbols if necessary
    if (field === "foType" || (field === "searchSymbol" && value.length >= 3)) {
      const currentType =
        field === "foType" ? value : adviceBasketEntries[index].foType;
      const searchValue = field === "searchSymbol" ? value : "";
      debouncedFetchBasketSymbols(index, searchValue, currentType);
    }
  };

  const handleCommonFieldChange = (field, value) => {
    setAdviceBasketEntries((prevEntries) =>
      prevEntries.map((entry) => ({
        ...entry,
        [field]: value,
      }))
    );
  };

  const handleRemoveDerivativesEntry = (index) => {
    const updatedEntries = adviceDerivativesEntries.filter(
      (_, i) => i !== index
    );
    setAdviceDerivativesEntries(updatedEntries);
  };

  const handleRemoveBasketEntry = (index) => {
    const updatedEntries = adviceBasketEntries.filter((_, i) => i !== index);
    setAdviceBasketEntries(updatedEntries);
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 z-50 flex items-center justify-center bg-black/75 backdrop-blur-sm"
        onClick={(e) => e.target === e.currentTarget && closeSendAdviceModal()}
      >
        <motion.div
          initial={{ scale: 0.95 }}
          animate={{ scale: 1 }}
          exit={{ scale: 0.95 }}
          className="relative w-full max-w-[87rem] max-h-[90vh] bg-white rounded-xl shadow-xl overflow-hidden"
          onClick={(e) => e.stopPropagation()}
        >
          {/* Header */}
          <div className="sticky top-0 z-10 bg-white/80 backdrop-blur-md border-b border-gray-200">
            <div className="px-6 py-4 flex items-center justify-between">
              <div>
                <h2 className="text-xl font-semibold text-gray-900">
                  Send Advice to {fileName?.replaceAll(/_/g, " ")}
                </h2>
                <p className="mt-1 text-sm text-gray-500">
                  {/* Configure and send advice to {data.length} selected client
                  {data.length !== 1 ? "s" : ""} */}
                  Configure and send advice to {filteredClients.length} valid
                  client
                  {filteredClients.length !== 1 ? "s" : ""} with active
                  subscriptions
                </p>
              </div>
              <button
                onClick={closeSendAdviceModal}
                className="p-2 rounded-full hover:bg-gray-100 transition-colors"
              >
                <XIcon className="w-5 h-5 text-gray-500" />
              </button>
            </div>
          </div>

          {/* <div className="inline-block font-poppins text-base text-gray-700 px-3 py-1 border border-gray-300 rounded-md shadow-sm mt-4 mb-2 ml-8">
            <span>Basket Entry</span>
          </div> */}

          {/* Table Section */}
          <div
            className="overflow-auto custom-scroll px-6 py-2"
            style={{ maxHeight: "calc(92vh - 180px)" }}
          >
            <button
              onClick={() => setActiveTab("equity")}
              className={`px-4 py-2 text-poppins text-base font-medium ${
                activeTab === "equity"
                  ? "text-black border-b-2 border-black"
                  : "text-gray-500 hover:text-black"
              }`}
            >
              Equity
            </button>
            <button
              onClick={() => setActiveTab("derivatives")}
              className={`px-4 py-2 text-poppins text-base font-medium ${
                activeTab === "derivatives"
                  ? "text-black border-b-2 border-black"
                  : "text-gray-500 hover:text-black"
              }`}
            >
              Derivatives
            </button>
            <div className="space-y-1 border-gray-200 " />

            {/* Main Section Code */}

            {activeTab === "equity" && (
              <div className="mt-4 p-4 border border-gray-200 rounded-lg">
                <div className="mb-4 flex flex-row items-center space-x-4 border-gray-200 ">
                  <button
                    onClick={handleAddAdviceEntry}
                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-black rounded-lg hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 transition-colors"
                  >
                    <Plus className="w-4 h-4 mr-2" />
                    Add Entry
                  </button>

                  <span className="text-sm text-gray-500">
                    {adviceEntries.length} entr
                    {adviceEntries.length === 1 ? "y" : "ies"}
                  </span>
                </div>

                <div className="overflow-x-auto custom-scroll rounded-lg border border-gray-200 shadow-sm">
                  <table className="min-w-full divide-y divide-gray-200">
                    <colgroup>
                      <col className="w-[250px]" />{" "}
                      {/* Symbol - increased width */}
                      <col className="w-[150px]" /> {/* Order Type */}
                      <col className="w-[150px]" /> {/* Product Type */}
                      <col className="w-[120px]" /> {/* Segment */}
                      <col className="w-[180px]" />{" "}
                      {/* Type - increased width */}
                      {showQuantityColumn && <col className="w-[100px]" />}{" "}
                      {/* Quantity */}
                      <col className="w-[100px]" /> {/* LTP */}
                      <col className="w-[150px]" /> {/* Price */}
                      <col className="w-[200px]" /> {/* Advised Range */}
                      <col className="w-[200px]" /> {/* Comments */}
                      <col className="w-[300px]" /> {/* Rationale */}
                      <col className="w-[80px]" /> {/* Actions */}
                    </colgroup>

                    <thead className="bg-gray-50">
                      <tr className="h-16">
                        {" "}
                        {/* Increased header height */}
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Symbol <span className="text-red-500">*</span>
                        </th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Order Type <span className="text-red-500">*</span>
                        </th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Product Type <span className="text-red-500">*</span>
                        </th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Segment <span className="text-red-500">*</span>
                        </th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Type <span className="text-red-500">*</span>
                        </th>
                        {showQuantityColumn && (
                          <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Quantity
                          </th>
                        )}
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          LTP
                        </th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Price <span className="text-red-500">*</span>
                        </th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Advised Range
                        </th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Rationale <span className="text-red-500">*</span>
                        </th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Comments
                        </th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Extended Comment
                        </th>
                        <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Actions
                        </th>
                      </tr>
                    </thead>

                    <tbody className="bg-white divide-y divide-gray-200">
                      {adviceEntries.map((entry, index) => (
                        <tr
                          key={entry.id}
                          className="hover:bg-gray-50 transition-colors min-h-[120px]"
                        >
                          {" "}
                          {/* Increased row height */}
                          {/* Symbol */}
                          <td className="px-4 py-4">
                            {" "}
                            {/* Increased cell padding */}
                            <div className="relative">
                              <input
                                type="text"
                                value={entry.inputValue}
                                onChange={(e) =>
                                  handleInputChange(index, e.target.value)
                                }
                                className="w-full min-w-[220px] px-3 py-2.5 border border-gray-300 rounded-md"
                                placeholder="Enter symbol"
                              />
                              {entry.symbols.length > 0 && isOpen && (
                                <div className="absolute z-10 w-full mt-1 bg-white rounded-md shadow-lg">
                                  {entry.symbols.map((symbol, idx) => (
                                    <div
                                      key={idx}
                                      onClick={() =>
                                        handleSymbolSelect(
                                          index,
                                          symbol.symbol,
                                          symbol.segment
                                        )
                                      }
                                      className="px-4 py-2.5 hover:bg-gray-100 cursor-pointer"
                                    >
                                      {symbol.symbol} - {symbol.segment}
                                    </div>
                                  ))}
                                </div>
                              )}
                            </div>
                          </td>
                          {/* Order Type */}
                          <td className="px-4 py-4">
                            <select
                              value={entry.orderType}
                              onChange={(e) =>
                                handleAdviceChange(
                                  index,
                                  "orderType",
                                  e.target.value
                                )
                              }
                              className="w-full min-w-[140px] font-poppins text-base px-3 py-2.5 border border-gray-300 rounded-md"
                            >
                              {["MARKET", "LIMIT", "STOP"].map((type) => (
                                <option key={type} value={type}>
                                  {type}
                                </option>
                              ))}
                            </select>
                          </td>
                          {/* Product Type */}
                          <td className="px-4 py-4">
                            <select
                              value={entry.productType}
                              onChange={(e) =>
                                handleAdviceChange(
                                  index,
                                  "productType",
                                  e.target.value
                                )
                              }
                              className="w-full min-w-[140px] font-poppins text-base px-3 py-2.5 border border-gray-300 rounded-md"
                            >
                              {["DELIVERY", "INTRADAY"].map((type) => (
                                <option key={type} value={type}>
                                  {type}
                                </option>
                              ))}
                            </select>
                          </td>
                          {/* Segment */}
                          <td className="px-4 py-4">
                            <select
                              value={entry.segment}
                              onChange={(e) =>
                                handleAdviceChange(
                                  index,
                                  "segment",
                                  e.target.value
                                )
                              }
                              className="w-full min-w-[120px] font-poppins text-base px-3 py-2.5 border border-gray-300 rounded-md"
                            >
                              <option value="EQUITY">EQUITY</option>
                            </select>
                          </td>
                          {/* Type */}
                          <td className="px-4 py-4">
                            <select
                              value={entry.type}
                              onChange={(e) =>
                                handleAdviceChange(
                                  index,
                                  "type",
                                  e.target.value
                                )
                              }
                              className="w-full min-w-[150px] font-poppins text-base px-3 py-2.5 border border-gray-300 rounded-md"
                            >
                              <option value="">Select Type</option>
                              {["BUY", "SELL"].map((type) => (
                                <option key={type} value={type}>
                                  {type}
                                </option>
                              ))}
                            </select>
                          </td>
                          {/* Quantity */}
                          {showQuantityColumn && (
                            <td className="px-4 py-4">
                              <input
                                type="number"
                                value={entry.quantity}
                                onChange={(e) =>
                                  handleAdviceChange(
                                    index,
                                    "quantity",
                                    e.target.value
                                  )
                                }
                                className="w-full  font-poppins text-basepx-3 py-2.5 border border-gray-300 rounded-md"
                                min="1"
                              />
                            </td>
                          )}
                          {/* LTP */}
                          <td className="px-4 py-4">
                            <div className="text-right">
                              {entry.symbol && getLTPForSymbol(entry.symbol)}
                            </div>
                          </td>
                          {/* Price */}
                          <td className="px-4 py-4 min-w-[150px]">
                            {" "}
                            {/* Added minimum width to td */}
                            {entry.orderType === "MARKET" ? (
                              <div className="text-right w-full max-w-[30px]">
                                {entry.symbol && getLTPForSymbol(entry.symbol)}
                              </div>
                            ) : (
                              <input
                                type="number"
                                value={entry.price || ""}
                                onChange={(e) =>
                                  handleAdviceChange(
                                    index,
                                    "price",
                                    e.target.value
                                  )
                                }
                                className="w-full min-w-[10px] px-3 py-2.5 border border-gray-300 rounded-md"
                                step="0.01"
                              />
                            )}
                          </td>
                          {/* Advised Range */}
                          <td className="px-4 py-4">
                            <div className="flex space-x-2">
                              <input
                                type="number"
                                value={entry.adviceLower || ""}
                                onChange={(e) =>
                                  handleAdviceChange(
                                    index,
                                    "adviceLower",
                                    e.target.value
                                  )
                                }
                                className="w-24 px-3 py-2.5 border border-gray-300 rounded-md"
                                placeholder="Low"
                              />
                              <span className="self-center">-</span>
                              <input
                                type="number"
                                value={entry.adviceHigher || ""}
                                onChange={(e) =>
                                  handleAdviceChange(
                                    index,
                                    "adviceHigher",
                                    e.target.value
                                  )
                                }
                                className="w-24 px-3 py-2.5 border border-gray-300 rounded-md"
                                placeholder="High"
                              />
                            </div>
                          </td>
                          {/* Rationale */}
                          <td className="px-4 py-4">
                            <textarea
                              value={entry.rationale}
                              onChange={(e) =>
                                handleRationaleChange(index, e.target.value)
                              }
                              className="w-full min-w-[280px] font-poppins px-3 py-2.5 border border-gray-300 rounded-md"
                              rows="3"
                              placeholder="Enter rationale for this trade..."
                            />
                          </td>
                          {/* Comments */}
                          <td className="relative px-4 py-4">
                            <div className="relative w-full min-w-[250px]">
                              <textarea
                                value={entry.comments}
                                readOnly
                                placeholder="Click to edit comment"
                                onClick={(e) => {
                                  const rect = e.target.getBoundingClientRect();
                                  setModalPosition({
                                    top: rect.top + window.scrollY,
                                    left: rect.left + window.scrollX,
                                  });
                                  setActiveCommentModal({
                                    index,
                                    type: "comments",
                                  });
                                }}
                                className="w-full px-3 py-2.5 border border-gray-300 rounded-md pr-10"
                                rows={3}
                              />

                              <CommentModal
                                isOpen={
                                  activeCommentModal?.index === index &&
                                  activeCommentModal?.type === "comments"
                                }
                                onClose={() => setActiveCommentModal(null)}
                                initialValue={entry.comments}
                                onSave={(value) =>
                                  handleCommentsChange(index, value)
                                }
                                position={modalPosition}
                                maxLength={256}
                              />
                            </div>
                          </td>
                          {/* Comments2 */}
                          <td className="px-4 py-4">
                            <div className="relative w-full min-w-[250px]">
                              <textarea
                                value={entry.comment2}
                                readOnly
                                placeholder="Click to edit extended comment"
                                onClick={(e) => {
                                  const rect = e.target.getBoundingClientRect();
                                  setModalPosition({
                                    top: rect.top + window.scrollY,
                                    left: rect.left + window.scrollX,
                                  });
                                  setActiveCommentModal({
                                    index,
                                    type: "comment2", // Changed this to differentiate from comments
                                  });
                                }}
                                className="w-full min-w-[200px] px-3 py-2.5 border border-gray-300 rounded-md"
                                rows="3"
                              />

                              <CommentModal
                                isOpen={
                                  activeCommentModal?.index === index &&
                                  activeCommentModal?.type === "comment2" // Match with the type above
                                }
                                onClose={() => setActiveCommentModal(null)}
                                initialValue={entry.comment2}
                                onSave={(value) =>
                                  handleComments2Change(index, value)
                                }
                                position={modalPosition}
                                maxLength={256}
                              />
                            </div>
                          </td>
                          {/* Actions */}
                          <td className="px-4 py-4">
                            {adviceEntries.length > 1 && (
                              <button
                                onClick={() => handleRemoveAdviceEntry(index)}
                                className="text-red-500 hover:text-red-700"
                              >
                                <XIcon className="h-5 w-5" />
                              </button>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {activeEmojiPicker && (
              <div
                ref={emojiPickerRef}
                className="absolute z-20"
                style={{
                  right:
                    activeEmojiPicker.type === "comment" ? "120px" : "395px",
                  bottom: "20px",
                }}
              >
                <EmojiPicker
                  onEmojiClick={(emojiObject) =>
                    handleEmojiClick(
                      emojiObject,
                      activeEmojiPicker.index,
                      activeEmojiPicker.type
                    )
                  }
                  width={280}
                  height={350}
                />
              </div>
            )}

            {/* Derivatives Entry Code */}
            {activeTab === "derivatives" && (
              <div className="mt-4 p-4 border border-gray-200 rounded-lg">
                <div className="flex space-x-4 mb-4">
                  <button
                    onClick={() => setActiveDerivativesTab("entry")}
                    className={`px-4 py-2 text-poppins text-base font-medium ${
                      activeDerivativesTab === "entry"
                        ? "text-black border-b-2 border-black"
                        : "text-gray-500 hover:text-black"
                    }`}
                  >
                    Add Entry
                  </button>
                  <button
                    onClick={() => setActiveDerivativesTab("basketEntry")}
                    className={`px-4 py-2 text-poppins text-base font-medium ${
                      activeDerivativesTab === "basketEntry"
                        ? "text-black border-b-2 border-black"
                        : "text-gray-500 hover:text-black"
                    }`}
                  >
                    Basket Entry
                  </button>
                </div>

                {activeDerivativesTab === "entry" && (
                  <div className="mt-4 p-4 border border-gray-200 rounded-lg">
                    <div className="mb-4 flex flex-row items-center space-x-4 border-gray-200 ">
                      <button
                        onClick={handleAddDerivativesEntry}
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-black rounded-lg hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 transition-colors"
                      >
                        <Plus className="w-4 h-4 mr-2" />
                        Add
                      </button>
                      <span className="text-sm text-gray-500">
                        {adviceDerivativesEntries.length} entr
                        {adviceDerivativesEntries.length === 1 ? "y" : "ies"}
                      </span>
                    </div>
                    <div className="overflow-x-auto custom-scroll rounded-lg border border-gray-200 shadow-sm">
                      <table className="min-w-full divide-y divide-gray-200">
                        <colgroup>
                          <col className="w-[200px]" /> {/* Segment */}
                          <col className="w-[250px]" /> {/* Symbol */}
                          <col className="w-[180px]" /> {/* Type */}
                          <col className="w-[200px]" /> {/* Strike */}
                          <col className="w-[250px]" /> {/* Order Type */}
                          <col className="w-[250px]" /> {/* Product Type */}
                          <col className="w-[250px]" /> {/* Option type */}
                          <col className="w-[200px]" /> {/* Lots */}
                          <col className="w-[100px]" /> {/* Price */}
                          <col className="w-[200px]" /> {/* Rationale */}
                          <col className="w-[200px]" /> {/* Comments */}
                          <col className="w-[200px]" /> {/* Extended Comment */}
                          <col className="w-[80px]" /> {/* Actions */}
                        </colgroup>

                        <thead className="bg-gray-50">
                          <tr className="h-16">
                            <th className="px-4 py-3 text-left text-xs font-poppins font-medium text-gray-500 uppercase tracking-wider">
                              Segment <span className="text-red-500">*</span>
                            </th>
                            <th className="px-3 py-2 text-left text-xs font-poppins font-medium text-gray-500 uppercase tracking-wider">
                              Symbol <span className="text-red-500">*</span>
                            </th>

                            {adviceDerivativesEntries.some(
                              (entry) => entry.foType === "OPTIONS"
                            ) && (
                              <th className="px-4 py-3 text-left text-xs font-poppins font-medium text-gray-500 uppercase tracking-wider">
                                Strike / (CE/PE){" "}
                                <span className="text-red-500">*</span>
                              </th>
                            )}

                            <th className="px-4 py-3 text-left font-poppins text-xs font-medium text-gray-500 uppercase tracking-wider w-[120px]">
                              Lots <span className="text-red-500">*</span>
                            </th>
                            <th className="px-4 py-3 text-left font-poppins text-xs font-medium text-gray-500 uppercase tracking-wider w-[120px]">
                              Option Type{" "}
                              <span className="text-red-500">*</span>
                            </th>
                            <th className="px-4 py-3 text-left text-xs font-poppins font-medium text-gray-500 uppercase tracking-wider">
                              Type <span className="text-red-500">*</span>
                            </th>
                            <th className="px-4 py-3 text-left font-poppins text-xs font-medium text-gray-500 uppercase tracking-wider w-[120px]">
                              Order Type <span className="text-red-500">*</span>
                            </th>
                            <th className="px-4 py-3 text-left font-poppins text-xs font-medium text-gray-500 uppercase tracking-wider w-[120px]">
                              Product Type{" "}
                              <span className="text-red-500">*</span>
                            </th>

                            <th className="px-4 py-3 text-left font-poppins text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Rationale <span className="text-red-500">*</span>
                            </th>
                            <th className="px-4 py-3 text-left font-poppins text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Comment
                            </th>
                            <th className="px-4 py-3 text-left font-poppins text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Extended Comment
                            </th>
                            <th className="px-4 py-3 text-left font-poppins text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Actions
                            </th>
                          </tr>
                        </thead>

                        <tbody className="bg-white divide-y divide-gray-200">
                          {adviceDerivativesEntries.map((entry, index) => (
                            <tr
                              key={entry.id}
                              className="hover:bg-gray-50 transition-colors min-h-[120px]"
                            >
                              {/* Type */}
                              <td className="px-4 py-4">
                                <select
                                  value={entry.foType}
                                  onChange={(e) =>
                                    handleDerivativeChange(
                                      index,
                                      "foType",
                                      e.target.value
                                    )
                                  }
                                  className="w-full min-w-[180px] font-poppins text-base px-3 py-1.5 border border-gray-300 rounded-md"
                                >
                                  <option value="">Select Type</option>
                                  {["OPTIONS", "FUTURES"].map((type) => (
                                    <option key={type} value={type}>
                                      {type}
                                    </option>
                                  ))}
                                </select>
                              </td>

                              {/* Symbol */}
                              <td className="py-2">
                                <div className="relative">
                                  <input
                                    type="text"
                                    value={entry.searchSymbol || ""}
                                    onChange={(e) =>
                                      handleDerivativesInputChange(
                                        index,
                                        e.target.value
                                      )
                                    }
                                    className="w-full min-w-[230px] px-3 font-poppins py-1.5 border border-gray-300 rounded-md"
                                    placeholder="Enter symbol"
                                  />
                                  {entry.symbols &&
                                    entry.symbols.length > 0 &&
                                    entry.showDropdown && (
                                      <div className="absolute z-10 font-poppins w-full mt-1 bg-white rounded-md shadow-lg">
                                        {Array.from(
                                          new Set(
                                            entry.symbols.map(
                                              (symbol) => symbol.searchSymbol
                                            )
                                          )
                                        )
                                          .filter(
                                            (uniqueSymbol) =>
                                              uniqueSymbol !==
                                              entry.searchSymbol
                                          )

                                          .map((uniqueSymbol, idx) => {
                                            const symbol = entry.symbols.find(
                                              (s) =>
                                                s.searchSymbol === uniqueSymbol
                                            );
                                            return (
                                              <div
                                                key={idx}
                                                onClick={() =>
                                                  handleDerivativesSymbolSelect(
                                                    index,
                                                    symbol,
                                                    symbol.lotsize,
                                                    symbol.strike,
                                                    symbol.exchange,
                                                    symbol.optionType,
                                                    symbol.strikes,
                                                    symbol.optionTypes
                                                  )
                                                }
                                                className="px-4 py-2.5 hover:bg-gray-100 font-poppins cursor-pointer"
                                              >
                                                {symbol.searchSymbol}
                                              </div>
                                            );
                                          })}
                                      </div>
                                    )}
                                </div>
                              </td>

                              {/* Strike (CE/PE) */}

                              {entry.foType === "OPTIONS" && (
                                <td className="px-4 py-4">
                                  <div className="flex items-center">
                                    {/* <input
                                      type="text"
                                      value={`${entry.strike || ''} `}
                                      onChange={(e) => handleDerivativeChange(index, 'strike', e.target.value)}
                                      className="w-full min-w-[120px] px-3 py-1.5 border border-gray-300 rounded-md text-center"
                                      placeholder="Strike"
                                    /> */}
                                    <select
                                      value={entry.strike}
                                      onChange={(e) =>
                                        handleDerivativeChange(
                                          index,
                                          "strike",
                                          e.target.value
                                        )
                                      }
                                      className="ml-2 px-3 font-poppins w-full min-w-[120px] py-1.5 border border-gray-300 rounded-md"
                                    >
                                      <option value="">Select</option>
                                      {(entry.strikes || []).map(
                                        (strike, idx) => (
                                          <option key={idx} value={strike}>
                                            {strike}
                                          </option>
                                        )
                                      )}
                                    </select>
                                  </div>
                                </td>
                              )}

                              {/* Lots */}
                              <td className="px-4 py-4 w-[120px]">
                                <div className="flex flex-row font-poppins items-center space-x-2">
                                  {/* Lot Multiplier Input */}
                                  <input
                                    type="number"
                                    value={entry.lotMultiplier}
                                    onChange={(e) => {
                                      const value = parseInt(
                                        e.target.value,
                                        10
                                      );
                                      const validatedValue =
                                        isNaN(value) || value <= 0 ? 0 : value;
                                      handleDerivativeChange(
                                        index,
                                        "lotMultiplier",
                                        validatedValue
                                      );
                                    }}
                                    className="w-[60px] font-poppins text-center text-base px-1 py-1 border border-gray-300 rounded-md"
                                    min="1"
                                  />
                                  <span className="text-sm text-gray-500">
                                    lot
                                  </span>
                                  <span className="text-sm text-gray-500">
                                    =
                                  </span>
                                  {/* Calculated Lots Input */}
                                  <input
                                    type="number"
                                    value={entry.lotMultiplier * entry.lots} // Show the exact calculated value
                                    onChange={(e) => {
                                      const value = parseInt(
                                        e.target.value,
                                        10
                                      );
                                      const validatedValue =
                                        isNaN(value) || value < 0 ? 0 : value;
                                      const newLots = Math.max(
                                        Math.floor(
                                          validatedValue / entry.lotMultiplier
                                        ),
                                        0
                                      );
                                      setAdviceDerivativesEntries(
                                        (prevEntries) =>
                                          prevEntries.map((item, i) =>
                                            i === index
                                              ? { ...item, lots: newLots }
                                              : item
                                          )
                                      );
                                    }}
                                    className="w-[80px] font-poppins text-base px-2 py-1 border border-gray-300 rounded-md"
                                    min="0"
                                  />
                                  <span className="text-sm text-gray-500">
                                    qty
                                  </span>
                                </div>
                              </td>

                              {/* Option Type */}
                              <td className="px-4 py-4">
                                <select
                                  value={entry.optionType}
                                  onChange={(e) =>
                                    handleDerivativeChange(
                                      index,
                                      "optionType",
                                      e.target.value
                                    )
                                  }
                                  className="ml-2 px-3 w-full font-poppins min-w-[120px] py-1.5 border border-gray-300 rounded-md"
                                >
                                  <option value="">Select</option>
                                  {(entry.optionTypes || []).map(
                                    (type, idx) => (
                                      <option key={idx} value={type}>
                                        {type}
                                      </option>
                                    )
                                  )}
                                </select>
                              </td>

                              {/* Order Type */}
                              <td className="px-4 py-4">
                                <select
                                  value={entry.orderType}
                                  onChange={(e) =>
                                    handleDerivativeChange(
                                      index,
                                      "orderType",
                                      e.target.value
                                    )
                                  }
                                  className="w-full min-w-[180px] font-poppins text-base px-3 py-1.5 border border-gray-300 rounded-md"
                                >
                                  <option value="">Select Type</option>
                                  {["BUY", "SELL"].map((type) => (
                                    <option key={type} value={type}>
                                      {type}
                                    </option>
                                  ))}
                                </select>
                              </td>

                              {/* ORDER TYPE */}
                              <td className="px-4 py-4">
                                <select
                                  value={entry.order || "MARKET"}
                                  onChange={(e) =>
                                    handleDerivativeChange(
                                      index,
                                      "order",
                                      e.target.value
                                    )
                                  }
                                  className="ml-2 px-3 w-full font-poppins min-w-[120px] py-1.5 border border-gray-300 rounded-md"
                                >
                                  <option value="">Select</option>
                                  {["MARKET", "LIMIT"].map((order, idx) => (
                                    <option key={idx} value={order}>
                                      {order}
                                    </option>
                                  ))}
                                </select>
                              </td>

                              {/* Product TYPE */}
                              <td className="px-4 py-4">
                                <select
                                  value={entry.productType || "CARRYFORWARD"}
                                  onChange={(e) =>
                                    handleDerivativeChange(
                                      index,
                                      "productType",
                                      e.target.value
                                    )
                                  }
                                  className="ml-2 px-2 font-poppins max-w-[180px] py-1.5 border border-gray-300 rounded-md"
                                >
                                  <option value="">Select</option>
                                  {["CARRYFORWARD", "INTRADAY"].map(
                                    (productType, idx) => (
                                      <option key={idx} value={productType}>
                                        {productType}
                                      </option>
                                    )
                                  )}
                                </select>
                              </td>

                              {/* Price */}
                              {/* <td className="px-4 py-4 min-w-[150px]">
                                <input
                                  type="number"
                                  value={entry.symbol ? (getLTPForSymbol2(entry.symbol) || "No data") : ""}

                                  // value={entry.price}
                                  // onChange={(e) => handleDerivativeChange(index, 'price', e.target.value)}
                                  className="w-full min-w-[10px] px-3 py-1.5 border border-gray-300 rounded-md"
                                  step="0.01"
                                /> */}

                              {/* {entry.symbol && getLTPForSymbol2(entry.symbol)} */}

                              {/* </td> */}

                              {/* Rationale */}
                              <td className="px-4 py-4">
                                <textarea
                                  value={entry.rationale}
                                  onChange={(e) =>
                                    handleDerivativeChange(
                                      index,
                                      "rationale",
                                      e.target.value
                                    )
                                  }
                                  className="w-full min-w-[280px] font-poppins px-3 py-1.5 border border-gray-300 rounded-md"
                                  rows="3"
                                  placeholder="Enter rationale..."
                                />
                              </td>

                              {/* Comments */}

                              <td className="relative px-4 py-4">
                                <textarea
                                  value={entry.comments}
                                  onChange={(e) =>
                                    handleDerivativeChange(
                                      index,
                                      "comments",
                                      e.target.value
                                    )
                                  }
                                  className="w-full min-w-[200px] px-3 font-poppins py-1.5 border border-gray-300 rounded-md"
                                  rows={3}
                                  placeholder="Enter comments..."
                                />
                              </td>
                              <td className="px-4 py-4">
                                <textarea
                                  value={entry.extendedComment}
                                  onChange={(e) =>
                                    handleDerivativeChange(
                                      index,
                                      "extendedComment",
                                      e.target.value
                                    )
                                  }
                                  className="w-full min-w-[200px] font-poppins px-3 py-1.5 border border-gray-300 rounded-md"
                                  rows="3"
                                  placeholder="Enter extended comment..."
                                />
                              </td>

                              {/* Actions */}
                              <td className="px-4 py-4">
                                {adviceDerivativesEntries.length > 1 && (
                                  <button
                                    onClick={() =>
                                      handleRemoveDerivativesEntry(index)
                                    }
                                    className="text-red-500 hover:text-red-700"
                                  >
                                    <XIcon className="h-5 w-5" />
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}

                {activeDerivativesTab === "basketEntry" && (
                  <div className="mt-4 p-4 border border-gray-200 rounded-lg">
                    <div className="mb-4 flex flex-row items-center space-x-4 border-gray-200">
                      <button
                        onClick={handleAddBasketEntry}
                        className="inline-flex  items-center px-4 py-2 text-sm font-medium text-white bg-black rounded-lg hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 transition-colors"
                      >
                        <Plus className="w-4 h-4 mr-2" />
                        Add
                      </button>
                      <span className="text-sm text-gray-500">
                        {adviceBasketEntries.length} entr
                        {adviceBasketEntries.length === 1 ? "y" : "ies"}
                      </span>
                    </div>
                    <div className="overflow-x-auto custom-scroll rounded-lg border border-gray-200 shadow-sm">
                      <table className="min-w-full divide-y divide-gray-200">
                        <colgroup>
                          <col className="w-[200px]" /> {/* Segment */}
                          <col className="w-[250px]" /> {/* Symbol */}
                          <col className="w-[180px]" /> {/* Strike */}
                          <col className="w-[200px]" /> {/* Lots */}
                          <col className="w-[250px]" /> {/* Order Type */}
                          <col className="w-[250px]" /> {/* Product Type */}
                          <col className="w-[250px]" /> {/* Option Type */}
                          <col className="w-[100px]" /> {/* price */}
                          <col className="w-[150px]" /> {/* action */}
                          <col className="w-[50px]" /> {/* Priority */}
                        </colgroup>

                        <thead className="bg-gray-50">
                          <tr>
                            <td
                              colSpan={7}
                              className="px-4 py-4 border border-b"
                            >
                              <div className="grid grid-cols-3 gap-4">
                                <div>
                                  <h4 className="font-semibold font-poppins mb-2">
                                    Rationale
                                  </h4>
                                  <div className="mb-2">
                                    <textarea
                                      value={
                                        adviceBasketEntries[0].commonRationale
                                      }
                                      onChange={(e) =>
                                        handleCommonFieldChange(
                                          "commonRationale",
                                          e.target.value
                                        )
                                      }
                                      className="w-full px-3 py-1.5 border border-gray-300 rounded-md"
                                      rows="3"
                                      placeholder="Enter rationale..."
                                    />
                                  </div>
                                </div>
                                <div>
                                  <h4 className="font-semibold  mb-2">
                                    Comments
                                  </h4>
                                  <div className="mb-2">
                                    <textarea
                                      value={
                                        adviceBasketEntries[0].commonComment
                                      }
                                      onChange={(e) =>
                                        handleCommonFieldChange(
                                          "commonComment",
                                          e.target.value
                                        )
                                      }
                                      className="w-full px-3 py-1.5 border border-gray-300 rounded-md"
                                      rows="3"
                                      placeholder="Enter comments..."
                                    />
                                  </div>
                                </div>
                                <div>
                                  <h4 className="font-semibold mb-2">
                                    Extended Comments
                                  </h4>
                                  <div className="mb-2">
                                    <textarea
                                      value={
                                        adviceBasketEntries[0]
                                          .commonExtendedComment
                                      }
                                      onChange={(e) =>
                                        handleCommonFieldChange(
                                          "commonExtendedComment",
                                          e.target.value
                                        )
                                      }
                                      className="w-full px-3 py-1.5 border border-gray-300 rounded-md"
                                      rows="3"
                                      placeholder="Enter extended comment..."
                                    />
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                          <tr className="h-16">
                            <th className="px-4 py-3 font-poppins text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Segment <span className="text-red-500">*</span>
                            </th>
                            <th className="px-3 py-2 font-poppins text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Symbol <span className="text-red-500">*</span>
                            </th>
                            <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Lots <span className="text-red-500">*</span>
                            </th>

                            {adviceBasketEntries.some(
                              (entry) => entry.foType === "OPTIONS"
                            ) && (
                              <th className="px-4 py-3 font-poppins text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Strike / (CE/PE){" "}
                                <span className="text-red-500">*</span>
                              </th>
                            )}

                            <th className="px-4 py-3 font-poppins text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Option Type{" "}
                              <span className="text-red-500">*</span>
                            </th>

                            <th className="px-4 py-3 font-poppins text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Type <span className="text-red-500">*</span>
                            </th>

                            <th className="px-4 py-3 font-poppins text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Order Type <span className="text-red-500">*</span>
                            </th>
                            <th className="px-4 py-3 font-poppins text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Product Type{" "}
                              <span className="text-red-500">*</span>
                            </th>

                            {showQuantityColumn && (
                              <th className="px-4 py-3 font-poppins text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Quantity
                              </th>
                            )}
                            {/* <th className="px-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Price <span className="text-red-500">*</span>
                            </th> */}
                            <th className="px-4 py-3 font-poppins text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Priority <span className="text-red-500">*</span>
                            </th>
                            <th className="px-4 py-3 font-poppins text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                              Actions
                            </th>
                          </tr>
                        </thead>

                        <tbody className="bg-white divide-y divide-gray-200">
                          {adviceBasketEntries.map((entry, index) => (
                            <tr
                              key={entry.id}
                              className="hover:bg-gray-50 transition-colors min-h-[120px]"
                            >
                              {/* Type */}
                              <td className="px-4 py-4">
                                <select
                                  value={entry.foType}
                                  onChange={(e) =>
                                    handleBasketChange(
                                      index,
                                      "foType",
                                      e.target.value
                                    )
                                  }
                                  className="w-full  min-w-[180px] font-poppins text-base px-3 py-1.5 border border-gray-300 rounded-md"
                                >
                                  <option value="">Select Type</option>
                                  {["OPTIONS", "FUTURES"].map((type) => (
                                    <option key={type} value={type}>
                                      {type}
                                    </option>
                                  ))}
                                </select>
                              </td>

                              {/* Symbol */}
                              <td className="px-3 py-2">
                                <div className="relative">
                                  <input
                                    type="text"
                                    value={entry.searchSymbol || ""}
                                    onChange={(e) =>
                                      handleBasketChange(
                                        index,
                                        "searchSymbol",
                                        e.target.value
                                      )
                                    }
                                    className="w-[250px] font-poppins px-3 py-1.5 border border-gray-300 rounded-md"
                                    placeholder="Enter symbol"
                                  />

                                  {entry.symbols &&
                                    entry.symbols.length > 0 &&
                                    entry.showBasketDropdown && (
                                      <div className="absolute font-poppins z-10 w-full mt-1 bg-white rounded-md shadow-lg">
                                        {Array.from(
                                          new Set(
                                            entry.symbols.map(
                                              (symbol) => symbol.searchSymbol
                                            )
                                          )
                                        ).map((uniqueSymbol, idx) => {
                                          const symbol = entry.symbols.find(
                                            (s) =>
                                              s.searchSymbol === uniqueSymbol
                                          );
                                          return (
                                            <div
                                              key={idx}
                                              onClick={() =>
                                                handleBasketSymbolSelect(
                                                  index,
                                                  symbol,
                                                  symbol.lotsize,
                                                  symbol.exchange,
                                                  symbol.strikes,
                                                  symbol.optionTypes
                                                )
                                              }
                                              className="px-4 py-2.5 font-poppins hover:bg-gray-100 cursor-pointer"
                                            >
                                              {symbol.searchSymbol}
                                            </div>
                                          );
                                        })}
                                      </div>
                                    )}
                                </div>
                              </td>

                              {/* Lots */}
                              {/* <td className="px-2 py-4">
  <div className="flex flex-row items-center space-x-2">
    <span className="text-sm text-gray-500">1 lot =</span>
    <input
      type="number"
      value={entry.lots}
      onChange={(e) => handleBasketChange(index, 'lots', e.target.value)}
      className="w-[100px] font-poppins text-base px-3 py-1.5 border border-gray-300 rounded-md"
      min="1"
    />
    <span className="text-sm text-gray-500">qty</span>
  </div>
</td> */}
                              <td className="px-8 py-4">
                                <div className="flex flex-row items-center space-x-2">
                                  {/* Lot Multiplier Input */}
                                  <input
                                    type="number"
                                    value={entry.lotMultiplier} // Controlled input
                                    onChange={(e) => {
                                      const value = parseInt(
                                        e.target.value,
                                        10
                                      );
                                      const validatedValue =
                                        isNaN(value) || value <= 0 ? "" : value;
                                      handleBasketChange(
                                        index,
                                        "lotMultiplier",
                                        validatedValue
                                      );
                                    }}
                                    className="w-[60px] font-poppins text-center text-base px-1 py-1 border border-gray-300 rounded-md"
                                    min="1"
                                  />
                                  <span className="text-sm text-gray-500">
                                    lot
                                  </span>
                                  <span className="text-sm text-gray-500">
                                    =
                                  </span>
                                  {/* Calculated Lots Input */}
                                  <input
                                    type="number"
                                    value={entry.lotMultiplier * entry.lots}
                                    onChange={(e) => {
                                      const value = parseInt(
                                        e.target.value,
                                        10
                                      );
                                      const validatedValue =
                                        isNaN(value) || value < 0 ? 0 : value;
                                      const newLots = Math.max(
                                        Math.floor(
                                          validatedValue / entry.lotMultiplier
                                        ),
                                        0
                                      );
                                      setAdviceBasketEntries((prevEntries) =>
                                        prevEntries.map((item, i) =>
                                          i === index
                                            ? { ...item, lots: newLots }
                                            : item
                                        )
                                      );
                                    }}
                                    className="w-[80px] font-poppins text-base px-2 py-1 border border-gray-300 rounded-md"
                                    min="0"
                                  />
                                  <span className="text-sm text-gray-500">
                                    qty
                                  </span>
                                </div>
                              </td>

                              {/* Strike (CE/PE) */}
                              {entry.foType === "OPTIONS" && (
                                <td className="px-4 py-4">
                                  <div className="flex items-center">
                                    {/* <input
                                      type="text"
                                      value={`${entry.strike || ''}`}
                                      onChange={(e) => handleBasketChange(index, 'strike', e.target.value)}
                                      className="w-full min-w-[120px] px-3 py-1.5 border border-gray-300 rounded-md text-center"
                                      placeholder="Strike"
                                    /> */}
                                    <select
                                      value={entry.strike}
                                      onChange={(e) =>
                                        handleBasketChange(
                                          index,
                                          "strike",
                                          e.target.value
                                        )
                                      }
                                      className="ml-2 font-poppins px-3 w-full min-w-[120px] py-1.5 border border-gray-300 rounded-md"
                                    >
                                      <option value="">Select</option>
                                      {entry.strikes.map((strike, idx) => (
                                        <option key={idx} value={strike}>
                                          {strike}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                              )}

                              {/* Option Type */}
                              <td>
                                <select
                                  value={entry.optionType}
                                  onChange={(e) =>
                                    handleBasketChange(
                                      index,
                                      "optionType",
                                      e.target.value
                                    )
                                  }
                                  className="ml-2  px-3 w-full min-w-[120px] py-1.5 border border-gray-300 rounded-md"
                                >
                                  <option value="">Select</option>
                                  {entry.optionTypes.map((type, idx) => (
                                    <option key={idx} value={type}>
                                      {type}
                                    </option>
                                  ))}
                                </select>
                              </td>

                              {/* TYPE */}
                              <td className="px-4 py-4">
                                <select
                                  value={entry.orderType}
                                  onChange={(e) =>
                                    handleBasketChange(
                                      index,
                                      "orderType",
                                      e.target.value
                                    )
                                  }
                                  className="w-[150px] font-poppins text-base px-3 py-1.5 border border-gray-300 rounded-md"
                                >
                                  <option value="">Select Type</option>
                                  {["BUY", "SELL"].map((type) => (
                                    <option key={type} value={type}>
                                      {type}
                                    </option>
                                  ))}
                                </select>
                              </td>

                              {/* Order Type */}
                              <td className="px-4 py-4">
                                <select
                                  value={entry.order || "MARKET"}
                                  onChange={(e) =>
                                    handleBasketChange(
                                      index,
                                      "order",
                                      e.target.value
                                    )
                                  }
                                  className="w-full font-poppins min-w-[120px] px-3 py-1.5 border border-gray-300 rounded-md"
                                >
                                  <option value="">Select</option>
                                  {["MARKET", "LIMIT"].map((order, idx) => (
                                    <option key={idx} value={order}>
                                      {order}
                                    </option>
                                  ))}
                                </select>
                              </td>

                              {/* Product Type */}
                              <td className="px-4 py-4">
                                <select
                                  value={entry.productType || "CARRYFORWARD"}
                                  onChange={(e) =>
                                    handleBasketChange(
                                      index,
                                      "productType",
                                      e.target.value
                                    )
                                  }
                                  className="ml-2 px-2 font-poppins max-w-[180px]  py-1.5 border border-gray-300 rounded-md"
                                >
                                  <option value="">Select</option>
                                  {["CARRYFORWARD", "INTRADAY"].map(
                                    (productType, idx) => (
                                      <option key={idx} value={productType}>
                                        {productType}
                                      </option>
                                    )
                                  )}
                                </select>
                              </td>

                              {/* Price */}
                              {/* <td className="px-4 py-4 min-w-[150px]">
                                <input
                                  type="number"
                                  value={entry.symbol ? (getLTPForSymbol2(entry.symbol) || "No data") : ""}
                                  onChange={(e) => handleBasketChange(index, 'price', e.target.value)}
                                  className="w-full min-w-[10px] px-3 py-1.5 border border-gray-300 rounded-md"
                                  step="0.01"
                                />
                              </td> */}

                              {/* Priority */}
                              <td className="px-4 py-4">
                                <input
                                  type="number"
                                  value={entry.priority}
                                  onChange={(e) =>
                                    handleBasketChange(
                                      index,
                                      "priority",
                                      e.target.value
                                    )
                                  }
                                  className="w-[100px] font-poppins text-base px-3 py-1.5 border border-gray-300 rounded-md"
                                />
                              </td>

                              {/* Actions */}
                              <td className="px-4 py-4">
                                {adviceBasketEntries.length > 1 && (
                                  <button
                                    onClick={() =>
                                      handleRemoveBasketEntry(index)
                                    }
                                    className="text-red-500 hover:text-red-700"
                                  >
                                    <XIcon className="h-5 w-5" />
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          {/* Footer - Equity */}
          <div className="sticky bottom-0 z-10 bg-gray-50 px-6 py-4 border-t border-gray-200">
            <div className="flex flex-col sm:flex-row items-center justify-between">
              {activeTab === "equity" && (
                <>
                  {!isValid && (
                    <div className="flex items-center text-red-500">
                      <AlertCircle className="w-4 h-4 mr-2" />
                      <span className="text-sm">
                        Please fill in all required fields
                      </span>
                    </div>
                  )}
                  <div className="flex space-x-3">
                    <button
                      onClick={closeSendAdviceModal}
                      className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleSendAdvice}
                      disabled={!isValid || loading}
                      className="inline-flex items-center px-4 py-2  text-sm font-medium text-white bg-black rounded-lg hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 disabled:bg-gray-300 disabled:cursor-not-allowed transition-colors"
                    >
                      {loading ? (
                        <span className="flex items-center">
                          <LoadingSpinner className="w-4 h-4 mr-2" />
                          Sending...
                        </span>
                      ) : (
                        "Send Advice"
                      )}
                    </button>
                  </div>
                </>
              )}

              {activeTab === "derivatives" && (
                <>
                  {isDerivativesValid && (
                    <div className="flex items-center text-red-500">
                      <AlertCircle className="w-4 h-4 mr-2" />
                      <span className="text-sm">
                        Please fill in all required fields
                      </span>
                    </div>
                  )}
                  <div className="flex space-x-3">
                    <button
                      onClick={closeSendAdviceModal}
                      className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-colors"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleSendDerivativesAdvice}
                      disabled={
                        !isDerivativesValid(
                          activeDerivativesTab === "basketEntry"
                            ? adviceBasketEntries
                            : adviceDerivativesEntries
                        ) || loading
                      }
                      className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-black rounded-lg hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900 disabled:bg-gray-300 disabled:cursor-not-allowed transition-colors"
                    >
                      {loading ? (
                        <span className="flex items-center">
                          <LoadingSpinner className="w-4 h-4 mr-2" />
                          Sending...
                        </span>
                      ) : (
                        "Send Advice"
                      )}
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};
export default SendAdviceModel;
